import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { AppConsts } from '@shared/AppConsts';
import { IdentityAuthProviderService } from '../../common/auth/identity-auth-provider.service';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: IdentityAuthProviderService,
        private _appSessionService: IdentityAuthProviderService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('POLICIES', '', 'flaticon-line-graph', '/app/main/policies', [], [], '../assets/common/images/sidebaricons/mypolicies.svg', '../assets/common/images/sidebaricons/mypolicies_hover.svg'),
            new AppMenuItem('CLIENT HUB', '', 'flaticon-line-graph', '/app/main/my-data/client-list', [], [], '../assets/common/images/sidebaricons/magnifier.svg', '../assets/common/images/sidebaricons/magnifier_hover.svg'),
            new AppMenuItem('POLICY MANAGEMENT', '', 'flaticon-line-graph', '/app/main/command-centre', [], [], '../assets/common/images/sidebaricons/mycommandcentre.svg', '../assets/common/images/sidebaricons/mycommandcentre_hover.svg'),
            new AppMenuItem('RESOURCE CENTRE', '', 'flaticon-line-graph', '/app/main/resource-center', [], [], '../assets/common/images/sidebaricons/myresourcecentre.svg', '../assets/common/images/sidebaricons/myresourcecentre_hover.svg'),
            //new AppMenuItem('COMMISSION', '', 'flaticon-line-graph', '/app/main/my-comission', [], [], '../assets/common/images/sidebaricons/comission.svg', '../assets/common/images/sidebaricons/comission_hover.svg'),
            new AppMenuItem('USERS', AppConsts.Roles.BrokerAdmin, 'flaticon-line-graph', '/app/main/user', [], [], '../assets/common/images/sidebaricons/users.svg', '../assets/common/images/sidebaricons/users_hover.svg'),
            new AppMenuItem('CLONE QUOTES', AppConsts.Roles.BrokerAdmin, 'flaticon-line-graph', '/app/main/clone-quote', [], [], '../assets/common/images/sidebaricons/mypolicies.svg', '../assets/common/images/sidebaricons/mypolicies_hover.svg'),
            new AppMenuItem('CONTACT US', '', 'flaticon-line-graph', '/app/main/contact-elevate', [], [], '../assets/common/images/sidebaricons/mysupport.svg', '../assets/common/images/sidebaricons/mysupport_hover.svg')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        //if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.getClaims() && !this._appSessionService.tenant.edition) {
        //    return false;
        //}

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.getClaims()) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        //if (this._appSessionService.getClaims() || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
        //    if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
        //        hideMenuItem = true;
        //    }
        //}

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
