import { Injector } from '@angular/core';
import { ProductService } from '@app/shared/services/product.service';
import { BenefitPattern } from './BenefitPattern';
import { BenefitModule } from '@app/shared/models/elevate-data-models';

export interface IIncompleteBenefitPattern {
    withMetadata(beneditPatternId): Promise<IIncompleteBenefitPattern>;
    extractBenefitPatternUIDList(): string[];
    forBenefitPattern(benefitPatternUID: string): IIncompleteBenefitPattern;
    extractUID(): IIncompleteBenefitPattern;
    extractBenefitIncreaseIndex(): IIncompleteBenefitPattern;
    extractBenefitIncrease(): IIncompleteBenefitPattern;
    extractBenefitEscallationTerm(): IIncompleteBenefitPattern;
    extractBenefitMaxPaymentAge(): IIncompleteBenefitPattern;
    extractInClaimbenefitIncreaseIndex(): IIncompleteBenefitPattern;
    extractInClaimbenefitIncrease(): IIncompleteBenefitPattern;
    getIfIdisability(): boolean;
    build(): BenefitPattern;
    extractInClaimMaximumPaymentTerm(): IIncompleteBenefitPattern;
}

export class BenefitPatternFactory implements IIncompleteBenefitPattern {
    private _productService: ProductService;
    MetadataBenefitPattern: BenefitModule.IBenefitPattern;
    benefitEscallationTerm: string;
    maxPaymentAge: string;
    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    metaData: BenefitModule.IBenefitModel;
    UID: string;
    benefitIncreaseIndex: string;
    benefitIncrease: any;
    InClaimbenefitIncreaseIndex: string;
    InClaimbenefitIncrease: string;
    benefitPatternList: string[];
    InClaimMaximumPaymentTerm: string;
    constructor(private injector: Injector) { }

    async withMetadata(benefitPatternSetId): Promise<IIncompleteBenefitPattern> {
        try {

            this.metaData = await this.productService.getBenefitPatternSet(benefitPatternSetId).toPromise();
            return this;
        } catch (e) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            return null;
        }

    }

    forBenefitPattern(benefitPatternUID: string): IIncompleteBenefitPattern {
        this.MetadataBenefitPattern = this.metaData.BenefitPatterns.find(res => res.UID === benefitPatternUID);
        return this;
    }


    extractBenefitPatternUIDList(): string[] {
        if (!this || !this.metaData || this.metaData.BenefitPatterns.IsNullOrEmpty()) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            return null;
        }
        this.benefitPatternList = this.metaData.BenefitPatterns.map(({ UID }) => ({ UID }.UID));
        return this.benefitPatternList;
    }



    extractUID(): IIncompleteBenefitPattern {

        this.UID = this.MetadataBenefitPattern.UID;
        return this;
    }


    extractBenefitIncreaseIndex(): IIncompleteBenefitPattern {

        if (this.MetadataBenefitPattern.BeforeClaimEscalation_2 && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.Index) {
            this.benefitIncreaseIndex = this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.Index.Name;
            return this;
        }
        if (this.MetadataBenefitPattern.BeforeClaimEscalation) {
            this.benefitIncreaseIndex = this.MetadataBenefitPattern.BeforeClaimEscalation;
            return this;
        }
        abp.message.error('Please Contact Support, as we could not assist at present.');
        abp.ui.clearBusy();
        throw new Error('Benefit Increase Index Empty.');
    }

    extractBenefitIncrease(): IIncompleteBenefitPattern {
        this.benefitIncrease = '0';
        if (this.MetadataBenefitPattern.BeforeClaimEscalation_2) {
            if (this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked
                && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation !== undefined && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation !== null) {
                this.benefitIncrease = this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_IndexLinked.BCAdditionalEscalation;
                if (this.benefitIncreaseIndex.toLocaleLowerCase() === 'cpi') {
                    this.benefitIncrease = 'CPI + ' + this.benefitIncrease;
                }
            }
            if (this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed
                && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation !== undefined && this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation !== null) {
                this.benefitIncrease = this.MetadataBenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation;
                if (this.benefitIncreaseIndex.toLocaleLowerCase() === 'cpi') {
                    this.benefitIncrease = 'CPI + ' + this.benefitIncrease;
                }
                return this;
            }
        }


        return this;

    }
    extractBenefitEscallationTerm(): IIncompleteBenefitPattern {
        if (this.MetadataBenefitPattern.CoverInForceCeaseAge) {
            this.benefitEscallationTerm = this.MetadataBenefitPattern.CoverInForceCeaseAge;
            return this;
        }

        abp.message.error('Please Contact Support, as we could not assist at present.');
        abp.ui.clearBusy();
        throw new Error('Benefit Term Not Found.');
    }


    extractBenefitMaxPaymentAge(): IIncompleteBenefitPattern {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum) {
            this.maxPaymentAge = this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.MaxPaymentAge;
        }

        return this;
    }

    extractInClaimbenefitIncreaseIndex(): IIncompleteBenefitPattern {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked.Index.Name) {
            this.InClaimbenefitIncreaseIndex = this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked.Index.Name;
        } else if (this.MetadataBenefitPattern.InClaimEscalation_2) {
            this.InClaimbenefitIncreaseIndex = this.MetadataBenefitPattern.InClaimEscalation;
        }

        return this;
    }


    extractInClaimbenefitIncrease(): IIncompleteBenefitPattern {

        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.ICE_NLS_IndexLinked.Index.Name) {
            this.InClaimbenefitIncrease = 'CPI + 0';
            return this;
        } else if (this.MetadataBenefitPattern.InClaimEscalation_2) {
            this.InClaimbenefitIncrease = '0';
            return this;
        }

        return this;
    }

    extractInClaimMaximumPaymentTerm(): IIncompleteBenefitPattern {
        if (this.MetadataBenefitPattern.InClaimEscalation_2 && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum && this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.MaxPaymentTerm) {
            this.InClaimMaximumPaymentTerm = this.MetadataBenefitPattern.InClaimEscalation_2.ICE_NonLumpSum.MaxPaymentTerm;
        }
        return this;
    }

    getIfIdisability() {
        if (this.metaData.BenefitPatterns.some(x => x.PaymentFrequency.trim().toLocaleLowerCase() === 'monthly')) {
            return true;
        }
        return false;
    }


    build(): BenefitPattern {
        return new BenefitPattern(this.UID,
            this.benefitIncreaseIndex, this.benefitIncrease, this.benefitEscallationTerm, this.InClaimbenefitIncreaseIndex, this.InClaimbenefitIncrease, this.maxPaymentAge, this.InClaimMaximumPaymentTerm);
    }
}
