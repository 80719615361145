import { BenefitPattern } from '../BenefitPatternFactory/BenefitPattern';
import { PremiumPattern } from '../PremiumPatternFactory/PremiumPattern';
import { AssociatedProduct } from '../AssociatedProductFactory/associated-product';
import { RateTableEntry } from '../RateTableFactory/RateTableEntry';
import { Injector } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { BenefitPatternFactory } from '../BenefitPatternFactory/IIncompleteBenefitPattern';
import { PremiumPatternFactory } from '../PremiumPatternFactory/IIncompletePremiumPattern';
import { RateTableEntryFactory } from '../RateTableFactory/IIncompleteRateTableEntry';
import { ProductListModule } from '../../models/elevate-data-models';
import { AppConsts } from '@shared/AppConsts';
import { IIncompleteProduct, ProductFactory } from './IIncompleteProduct';
import { includes } from 'lodash';

export interface IIncompleteProductBase {
    forProduct(mainProductUID: string): IIncompleteProductBase;
    excludeRiders(allowedDisabilityProductNames: Array<string>): IIncompleteProductBase;

    extractUID(): IIncompleteProductBase;
    extractName(): IIncompleteProductBase;
    extractMinBenefitAmount(): IIncompleteProductBase;
    extractDependentStatus(): IIncompleteProductBase;
    extractDependentParentGuid(mainProductFactory: ProductFactory): IIncompleteProductBase;
    extractMaxBenefitAmount(): IIncompleteProductBase;

    extractBenefitPatternSetAsync(): Promise<IIncompleteProductBase>;
    extractPremiumPatternSetAsync(): Promise<IIncompleteProductBase>;

    getBenefitPatternSet(): BenefitPattern[];
    getPremiumPatternSet(): PremiumPattern[];

    extractEventSet(): IIncompleteProductBase;
    extractCategory(): IIncompleteProductBase;
    extractDisplayName(): IIncompleteProductBase;

    setDefaultBenefitPattern(): IIncompleteProductBase;
    setDefaultPremiumPattern(): IIncompleteProductBase;
    setDefaultAssuredValue(): IIncompleteProductBase;

    setRateTableAsync(benefitPatternSet: BenefitPattern[], premiumPatternSet: PremiumPattern[], lowerLimit: number, upperLimit: number, age: string): Promise<IIncompleteProductBase>;
}


export abstract class ProductBaseFactory implements IIncompleteProductBase {


    private _productService: ProductService;

    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    metaData: ProductListModule.IProductListModel[];
    metaDataOriginal: ProductListModule.IProductListModel[];
    metaProduct: ProductListModule.IProductListModel;
    uid: string;
    name: string;
    minBenefitAmount: number;
    maxBenefitAmount: number;
    benefitPatternSet = new Array<BenefitPattern>();
    premiumPatternSet = new Array<PremiumPattern>();
    eventSet: string;
    category: string;
    displayName: string;
    rateTable: RateTableEntry[];
    defaultBenefitPattern: BenefitPattern;
    defaultPremiumPattern: PremiumPattern;
    isDisability: boolean;
    isParentDependent: boolean;
    dependentParentGuid: string;

    get sortedRateTable(): RateTableEntry[] {
        if (!this.rateTable) { return []; }
        return this.rateTable.sort((a, b) => a.BaseRate - b.BaseRate);
    }

    constructor(protected injector: Injector) { }


    forProduct(productUID: string): IIncompleteProductBase {
        try {
            this.metaProduct = this.metaData.find(x => x.UID === productUID);
            return this;
        } catch (e) {
            abp.message.error(JSON.stringify(e));
        }

    }

    excludeRiders(allowedDisabilityProductNames: Array<string>): IIncompleteProductBase {
        const isProductAllowed = (productName: string): boolean => {
            for (const excludedProductName of allowedDisabilityProductNames) {
                if (productName.toLocaleLowerCase().includes(excludedProductName)) {
                    return true;
                }
            }

            return false;
        };

        try {
            this.metaProduct.ProductRequirements = this.metaProduct.ProductRequirements.filter(m =>
                this.metaDataOriginal.find(p => p.Name === m.ProductConfig_2.Name).Category === 'Disability Protection'
                || (this.metaDataOriginal.find(p => p.Name === m.ProductConfig_2.Name).Category === 'Complimentary Benefits' && m.ProductConfig_2.Name.toLocaleLowerCase().includes('disability')) ?
                isProductAllowed(m.ProductConfig_2.Name) : true);
            return this;
        } catch (e) {
            abp.message.error(JSON.stringify(e));
        }
    }

    extractUID(): IIncompleteProductBase {
        try {
            this.uid = this.metaProduct.UID;
            return this;
        } catch (e) {
            abp.message.error(JSON.stringify(e));
        }

    }
    extractName(): IIncompleteProductBase {
        try {
            if (!this.metaProduct.Name) {
                abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractName');
                abp.ui.clearBusy();
                return null;
            }
            this.name = this.metaProduct.Name;
            return this;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractName');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }


    extractMinBenefitAmount(): IIncompleteProductBase {
        if (this.metaProduct.ConstraintConfig.MinBenefitAmount < 0) {
            abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractMinBenefitAmount');
            abp.ui.clearBusy();
            return null;
        }
        this.minBenefitAmount = this.metaProduct.ConstraintConfig.MinBenefitAmount;
        return this;
    }


    extractMaxBenefitAmount(): IIncompleteProductBase {
        if (this.metaProduct.ConstraintConfig.MaxBenefitAmount < this.minBenefitAmount) {
            abp.message.error('Please Contact Support, as we could not assist at present. extractMaxBenefitAmount');
            abp.ui.clearBusy();
            throw new Error('extractMaxBenefitAmount.');
            return null;
        }
        this.maxBenefitAmount = this.metaProduct.ConstraintConfig.MaxBenefitAmount;
        return this;
    }
    extractEventSet(): IIncompleteProductBase {
        this.eventSet = this.metaProduct.EventSets[0].ShortCode;
        return this;
    }
    extractCategory(): IIncompleteProductBase {

        this.category = this.metaProduct.Category;
        return this;
    }

    extractDisplayName(): IIncompleteProductBase {
        this.displayName = this.metaProduct.DisplayName.trim();
        return this;
    }

    async extractBenefitPatternSetAsync(): Promise<IIncompleteProductBase> {
        try {
            let benefitPatternSetId = this.metaProduct.BenefitPatternSets[0].UID;

            let benefitFactory = await new BenefitPatternFactory(this.injector)
                .withMetadata(benefitPatternSetId);


            this.isDisability = benefitFactory.getIfIdisability();

            this.benefitPatternSet = [];
            benefitFactory
                .extractBenefitPatternUIDList()
                .forEach(x => {
                    let benefitPattern = benefitFactory
                        .forBenefitPattern(x)
                        .extractUID()
                        .extractBenefitIncreaseIndex()
                        .extractBenefitIncrease()
                        .extractBenefitEscallationTerm()
                        .extractBenefitMaxPaymentAge()
                        .extractInClaimbenefitIncreaseIndex()
                        .extractInClaimbenefitIncrease()
                        .extractInClaimMaximumPaymentTerm()
                        .build();
                    this.benefitPatternSet.push(benefitPattern);
                });


            return this;
        } catch (error) {
            throw new Error(error);
        }
    }

    async extractPremiumPatternSetAsync(): Promise<IIncompleteProductBase> {
        try {
            let premiumPatternSetId = this.metaProduct.PremiumPatternSets[0].UID;

            let premiumFactory = await new PremiumPatternFactory(this.injector)
                .withMetadata(premiumPatternSetId);

            this.premiumPatternSet = [];
            premiumFactory.extractPremiumPatternUIDList()
                .forEach(UID => {
                    let premiumPattern = premiumFactory
                        .forPremiumPattern(UID)
                        .extractUID()
                        .extractPremiumIncreaseIndex()
                        .extractPremiumIncrease()
                        .extractPremiumTerm()
                        .build();
                    this.premiumPatternSet.push(premiumPattern);
                });
            return this;
        } catch (error) {
            throw new Error(error);
        }
    }

    extractDependentStatus(): IIncompleteProductBase {
        if (!this.displayName) {
            throw new Error('Unable to extract parent dependent status');
        }

        this.isParentDependent = this.displayName.includes(AppConsts.configProducts.FullTierBooster) || this.displayName.includes(AppConsts.configProducts.SelfEmployedEnhancer) || this.displayName.includes(AppConsts.configProducts.AlternateSelfEmployedEnhancer) || this.displayName.includes(AppConsts.configProducts.OwnOccupationBooster);
        return this;
    }

    extractDependentParentGuid(mainProductFactory: ProductFactory): IIncompleteProductBase {
        if (!this.isParentDependent) {
            this.dependentParentGuid = null;
        } else if (mainProductFactory.category === AppConsts.AggregationCategories.severeIllnessProtection || mainProductFactory.category === AppConsts.AggregationCategories.disabilityProtection) {
            this.dependentParentGuid = mainProductFactory.uid;
        } else {
            const accelerators = mainProductFactory.metaData.filter(m => m.DisplayName.includes('Principal Severe Illness Protection - Accelerator') || m.DisplayName.includes('Principal Disability Capital Protection - Accelerator'));

            const accelerator = accelerators.find(a => a.Category === this.category);
            if (accelerator) {
                this.dependentParentGuid = accelerator.UID;
            } else {
                this.dependentParentGuid = null;
            }
        }

        return this;
    }

    getBenefitPatternSet(): BenefitPattern[] {
        return this.benefitPatternSet;
    }
    getPremiumPatternSet(): PremiumPattern[] {
        return this.premiumPatternSet;
    }

    async setRateTableAsync(benefitPatternSet: BenefitPattern[], premiumPatternSet: PremiumPattern[], lowerLimit: number, upperLimit: number, age: string): Promise<IIncompleteProductBase> {
        try {
            let rateTableEntryFactory = await new RateTableEntryFactory(this.injector)
                .withMetaData(this.metaProduct.UID, lowerLimit, upperLimit, age);
            this.rateTable = [];
            if (!rateTableEntryFactory) {
                rateTableEntryFactory = await new RateTableEntryFactory(this.injector)
                    .withMetaData(this.metaProduct.UID, lowerLimit, upperLimit, age);
            }
            rateTableEntryFactory.extractRateTableEntryUIDList()
                .forEach(UID => {
                    let rateTableEntry = rateTableEntryFactory
                        .forRateTableEntry(UID)
                        .extractUID()
                        .extractBenefitPattern(benefitPatternSet)
                        .extractPremiumPattern(premiumPatternSet)
                        .extractBaseRate()
                        .extractUnitRate()
                        .extractSumAssuredLimit()
                        .build();

                    this.rateTable.push(rateTableEntry);
                });
            return this;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    setDefaultBenefitPattern(): IIncompleteProductBase {
        try {
            if (!this.sortedRateTable || this.sortedRateTable.length === 0) { return this; }
            this.defaultBenefitPattern = this.benefitPatternSet.find(x => x.UID === this.sortedRateTable[0].BenefitPattern.UID);
            return this;
        } catch (error) {
            throw new Error(error);
        }
    }
    setDefaultPremiumPattern(): IIncompleteProductBase {
        try {
            if (!this.sortedRateTable || this.sortedRateTable.length === 0) { return this; }
            this.defaultPremiumPattern = this.premiumPatternSet.find(x => x.UID === this.sortedRateTable[0].PremiumPattern.UID);
            return this;
        } catch (error) {
            throw new Error(error);
        }
    }
    setDefaultAssuredValue(): IIncompleteProductBase {
        throw new Error('Method not implemented.');
    }


}
