import { GetQuestionariesModule } from '@app/shared/models/elevate-data-models';
import { AppConsts } from '@shared/AppConsts';

export const getCustomQuoteQuestions = (): Array<GetQuestionariesModule.IQuestion> => {
    return [
        {
            AllowMultiAnswers: false,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'SingleEntryAnswerProvider',
                    ApplicantAttributeName: 'Net monthly income',
                    AutoComplete: false,
                    DataType: 'Number',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Net monthly income',
                    Value: null,
                    id: 54915
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.CustomNetIncome,
            Id: null,
            IsVisibleAtStart: true,
            Prompt: '',
            QuestionSequence: null,
            Text: 'Net monthly income (after tax and other deductions)',
            Title: 'Custom net income',
            QuestionType: 2,
            ValueGiven: undefined,
            overrideVisibility: false
        }
    ];
};

export const getCoverReasonQuestions = (): Array<GetQuestionariesModule.IQuestion> => {
    return [
        {
            AllowMultiAnswers: false,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Yes, they are the same',
                    Value: 'Yes',
                    id: null
                },
                {
                    AnswerSequence: 2,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'No, they are different',
                    Value: 'No',
                    id: null
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.PolicyHolderIsInsuredLife,
            Id: null,
            IsVisibleAtStart: true,
            Prompt: '',
            QuestionSequence: 0,
            Text: 'Please confirm that the Policyholder is intended to be the the same as the Life Insured on this application',
            Title: 'Policyholder',
            QuestionType: 1,
            ValueGiven: undefined,
            overrideVisibility: false
        },
        {
            AllowMultiAnswers: false,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Personal cover',
                    Value: 'Personal cover',
                    id: null
                },
                {
                    AnswerSequence: 2,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Business assurance',
                    Value: 'Business assurance',
                    id: null
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.CoverReason,
            Id: null,
            IsVisibleAtStart: true,
            Prompt: '',
            QuestionSequence: 1,
            Text: 'What is the reason for the cover being applied for?',
            Title: 'Reason for cover',
            QuestionType: 1,
            ValueGiven: undefined,
            overrideVisibility: false
        },
        {
            AllowMultiAnswers: true,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Key Man Cover',
                    Value: 'Key Man Cover',
                    id: null
                },
                {
                    AnswerSequence: 2,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Buy and Sell Cover',
                    Value: 'Buy and Sell Cover',
                    id: null
                },
                {
                    AnswerSequence: 3,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Contingent Liability Cover',
                    Value: 'Contingent Liability Cover',
                    id: null
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.BusinessAssuranceType,
            Id: null,
            IsVisibleAtStart: false,
            Prompt: '',
            QuestionSequence: 2,
            Text: 'What Business Assurance are you looking for?',
            Title: 'Reason for cover',
            QuestionType: 0,
            ValueGiven: undefined,
            overrideVisibility: true
        }
    ];
};

export const getCreatePolicyResult = (): {
    extId: string;
    questions: Array<GetQuestionariesModule.IQuestion>
} => {
    return {
        'extId': 'Important disclosures',
        'questions': [
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'SingleEntryAnswerProvider',
                        'ApplicantAttributeName': 'Age',
                        'AutoComplete': false,
                        'DataType': 'Number',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Age',
                        'Value': null,
                        'id': 54889
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'age',
                'Id': 14342,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 1,
                'Text': 'Age',
                'Title': 'Age',
                'QuestionType': 2
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Male',
                        'Value': 'Male',
                        'id': 54892
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Female',
                        'Value': 'Female',
                        'id': 54891
                    },
                    {
                        'AnswerSequence': 3,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Non-binary',
                        'Value': 'Non-binary',
                        'id': 54890
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'gender',
                'Id': 14344,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 2,
                'Text': 'Sex',
                'Title': 'Sex',
                'QuestionType': 1
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': 'Gender',
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Male',
                        'Value': 'Male',
                        'id': 54894
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': 'Gender',
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Female',
                        'Value': 'Female',
                        'id': 54893
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'DND_sex',
                'Id': 14348,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 3,
                'Text': 'Sex',
                'Title': 'DND_Sex',
                'QuestionType': 7
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': 'SmokerStatus',
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Non-smoker',
                        'Value': 'Non-smoker',
                        'id': 54898
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': 'SmokerStatus',
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Smoker',
                        'Value': 'Smoker',
                        'id': 54896
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'smoker_status',
                'Id': 14351,
                'IsVisibleAtStart': true,
                'Prompt': 'Smoking includes regular use of any tobacco product within the last 12 months.',
                'QuestionSequence': 4,
                'Text': 'Smoking status',
                'Title': 'Smoking status',
                'QuestionType': 1
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Single',
                        'Value': '0',
                        'id': 54907
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Married',
                        'Value': '1',
                        'id': 54905
                    },
                    {
                        'AnswerSequence': 3,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Domestic partnership',
                        'Value': '3',
                        'id': 54904
                    },
                    {
                        'AnswerSequence': 4,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Cohabiting',
                        'Value': '6',
                        'id': 54903
                    },
                    {
                        'AnswerSequence': 5,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Divorced',
                        'Value': '4',
                        'id': 54902
                    },
                    {
                        'AnswerSequence': 6,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Widowed',
                        'Value': '5',
                        'id': 54900
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'marital_status',
                'Id': 14354,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 5,
                'Text': 'Marital status',
                'Title': 'Marital status',
                'QuestionType': 1
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'SelectionAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': true,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Occupations',
                        'Value': '6',
                        'id': 54908
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'Occupation',
                'Id': 14361,
                'IsVisibleAtStart': true,
                'Prompt': 'If you are currently not working, please select the most suitable option, such as student, housewife or husband, retired, unemployed etc.',
                'QuestionSequence': 6,
                'Text': 'Current main occupation',
                'Title': 'Current main occupation',
                'CondtionValues': [
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3886,
                        'IsSynonym': false,
                        'Sequence': 1,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Abalone Fisherman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3887,
                        'IsSynonym': false,
                        'Sequence': 2,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Abattoir Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3888,
                        'IsSynonym': false,
                        'Sequence': 3,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Academic Tutor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3889,
                        'IsSynonym': false,
                        'Sequence': 4,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Accommodation and Lodging Housekeeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3890,
                        'IsSynonym': false,
                        'Sequence': 5,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Accompanist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3891,
                        'IsSynonym': false,
                        'Sequence': 6,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Accountant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3892,
                        'IsSynonym': false,
                        'Sequence': 7,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Accounting Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3893,
                        'IsSynonym': false,
                        'Sequence': 8,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Accounting Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3894,
                        'IsSynonym': false,
                        'Sequence': 9,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Accounts Supervisor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3895,
                        'IsSynonym': false,
                        'Sequence': 10,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Acoustician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3896,
                        'IsSynonym': false,
                        'Sequence': 11,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Acrobat'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3897,
                        'IsSynonym': false,
                        'Sequence': 12,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Actor/Actress'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3898,
                        'IsSynonym': false,
                        'Sequence': 13,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Actuarial Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3899,
                        'IsSynonym': false,
                        'Sequence': 14,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Actuary'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3900,
                        'IsSynonym': false,
                        'Sequence': 15,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Acupuncturist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3901,
                        'IsSynonym': false,
                        'Sequence': 16,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Adhesives Laboratory Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3902,
                        'IsSynonym': false,
                        'Sequence': 17,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Adhesives Laboratory Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3903,
                        'IsSynonym': false,
                        'Sequence': 18,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Adjudicator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3904,
                        'IsSynonym': false,
                        'Sequence': 19,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Admin Manager/Manageress'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3905,
                        'IsSynonym': false,
                        'Sequence': 20,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Administrative Head'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3906,
                        'IsSynonym': false,
                        'Sequence': 21,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Administrative Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3907,
                        'IsSynonym': false,
                        'Sequence': 22,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Administrative Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3908,
                        'IsSynonym': false,
                        'Sequence': 23,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Administrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3909,
                        'IsSynonym': false,
                        'Sequence': 24,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Admissions Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3910,
                        'IsSynonym': false,
                        'Sequence': 25,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Advertising Account Executive'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3911,
                        'IsSynonym': false,
                        'Sequence': 26,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Advertising Agent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3912,
                        'IsSynonym': false,
                        'Sequence': 27,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Advertising Executive'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3913,
                        'IsSynonym': false,
                        'Sequence': 28,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Advertising Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3914,
                        'IsSynonym': false,
                        'Sequence': 29,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Advocate'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3915,
                        'IsSynonym': false,
                        'Sequence': 30,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aerial Erector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3916,
                        'IsSynonym': false,
                        'Sequence': 31,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aerobics Instructor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3917,
                        'IsSynonym': false,
                        'Sequence': 32,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aeronautical Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3918,
                        'IsSynonym': false,
                        'Sequence': 33,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aeronautical Engineer (Non-Military)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3919,
                        'IsSynonym': false,
                        'Sequence': 34,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aeronautical Engineering Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3920,
                        'IsSynonym': false,
                        'Sequence': 35,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agent - Insurance (Administrative only)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3921,
                        'IsSynonym': false,
                        'Sequence': 36,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agent (Admin only)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3922,
                        'IsSynonym': false,
                        'Sequence': 37,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Advisor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3923,
                        'IsSynonym': false,
                        'Sequence': 38,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3924,
                        'IsSynonym': false,
                        'Sequence': 39,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Economist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3925,
                        'IsSynonym': false,
                        'Sequence': 40,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3926,
                        'IsSynonym': false,
                        'Sequence': 41,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Extension Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3927,
                        'IsSynonym': false,
                        'Sequence': 42,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Inspector'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3928,
                        'IsSynonym': false,
                        'Sequence': 43,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Labourer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3929,
                        'IsSynonym': false,
                        'Sequence': 44,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Secretary'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3930,
                        'IsSynonym': false,
                        'Sequence': 45,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3931,
                        'IsSynonym': false,
                        'Sequence': 46,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agricultural Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3932,
                        'IsSynonym': false,
                        'Sequence': 47,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agriculturist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3933,
                        'IsSynonym': false,
                        'Sequence': 48,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agrometeorologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3934,
                        'IsSynonym': false,
                        'Sequence': 49,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agronomist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3935,
                        'IsSynonym': false,
                        'Sequence': 50,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Agrostologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3936,
                        'IsSynonym': false,
                        'Sequence': 51,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air Observer (Rescue)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3937,
                        'IsSynonym': false,
                        'Sequence': 52,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air Pollution Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3938,
                        'IsSynonym': false,
                        'Sequence': 53,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air Safety Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3939,
                        'IsSynonym': false,
                        'Sequence': 54,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air Track Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3940,
                        'IsSynonym': false,
                        'Sequence': 55,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air Traffic Controller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3941,
                        'IsSynonym': false,
                        'Sequence': 56,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airborne Electronics Analyst'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3942,
                        'IsSynonym': false,
                        'Sequence': 57,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airconditioner Mechanic/Technician (heights > 12m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3943,
                        'IsSynonym': false,
                        'Sequence': 58,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airconditioner Mechanic/Technician (heights up to 12m)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3944,
                        'IsSynonym': false,
                        'Sequence': 59,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air-Conditioning Drafting Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3945,
                        'IsSynonym': false,
                        'Sequence': 60,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Air-Conditioning Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3946,
                        'IsSynonym': false,
                        'Sequence': 61,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft - cabin crew/airhostess/steward'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3947,
                        'IsSynonym': false,
                        'Sequence': 62,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft - ground crew'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3948,
                        'IsSynonym': false,
                        'Sequence': 63,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Cabin Cleaner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3949,
                        'IsSynonym': false,
                        'Sequence': 64,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Detail Draftsperson'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3950,
                        'IsSynonym': false,
                        'Sequence': 65,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Electrical Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3951,
                        'IsSynonym': false,
                        'Sequence': 66,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Electronics Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3952,
                        'IsSynonym': false,
                        'Sequence': 67,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Engine Fitter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3953,
                        'IsSynonym': false,
                        'Sequence': 68,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Engineer (Non-Military)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3954,
                        'IsSynonym': false,
                        'Sequence': 69,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Load Controller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3955,
                        'IsSynonym': false,
                        'Sequence': 70,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Machinist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3956,
                        'IsSynonym': false,
                        'Sequence': 71,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Maintenance Engineer (Non-Military)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3957,
                        'IsSynonym': false,
                        'Sequence': 72,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Safety Equipment Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3958,
                        'IsSynonym': false,
                        'Sequence': 73,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aircraft Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3959,
                        'IsSynonym': false,
                        'Sequence': 74,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airframe Fitter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3960,
                        'IsSynonym': false,
                        'Sequence': 75,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airframe Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3961,
                        'IsSynonym': false,
                        'Sequence': 76,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airline Traffic Officer (Passenger Services)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3962,
                        'IsSynonym': false,
                        'Sequence': 77,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airport Cleaner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3963,
                        'IsSynonym': false,
                        'Sequence': 78,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airways Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3964,
                        'IsSynonym': false,
                        'Sequence': 79,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Airworthiness Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3965,
                        'IsSynonym': false,
                        'Sequence': 80,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ambassador'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3966,
                        'IsSynonym': false,
                        'Sequence': 81,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ambulance dispatcher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3967,
                        'IsSynonym': false,
                        'Sequence': 82,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ambulance Paramedic'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3968,
                        'IsSynonym': false,
                        'Sequence': 83,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ambulance Superintendent (No manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3969,
                        'IsSynonym': false,
                        'Sequence': 84,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Anaesthetic Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3970,
                        'IsSynonym': false,
                        'Sequence': 85,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Anaesthetist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3971,
                        'IsSynonym': false,
                        'Sequence': 86,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Analyst (< 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3972,
                        'IsSynonym': false,
                        'Sequence': 87,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Analyst (> 20% travel or > 20% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3973,
                        'IsSynonym': false,
                        'Sequence': 88,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Analytical Chemist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3974,
                        'IsSynonym': false,
                        'Sequence': 89,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Anatomist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3975,
                        'IsSynonym': false,
                        'Sequence': 90,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3976,
                        'IsSynonym': false,
                        'Sequence': 91,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Breeder - Domestic Animals'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3977,
                        'IsSynonym': false,
                        'Sequence': 92,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Breeder - Wild Animals'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3978,
                        'IsSynonym': false,
                        'Sequence': 93,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Groomer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3979,
                        'IsSynonym': false,
                        'Sequence': 94,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Morphologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3980,
                        'IsSynonym': false,
                        'Sequence': 95,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Nutritionist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3981,
                        'IsSynonym': false,
                        'Sequence': 96,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Taxonomist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3982,
                        'IsSynonym': false,
                        'Sequence': 97,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animal Trainer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3983,
                        'IsSynonym': false,
                        'Sequence': 98,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Animator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3984,
                        'IsSynonym': false,
                        'Sequence': 99,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Annealer and Locksmith'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3985,
                        'IsSynonym': false,
                        'Sequence': 100,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Announcer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3986,
                        'IsSynonym': false,
                        'Sequence': 101,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Anthropologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3987,
                        'IsSynonym': false,
                        'Sequence': 102,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Antique Dealer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3988,
                        'IsSynonym': false,
                        'Sequence': 103,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Antique Furniture Restorer (No power tools)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3989,
                        'IsSynonym': false,
                        'Sequence': 104,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Antique Furniture Restorer (With power tools)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3990,
                        'IsSynonym': false,
                        'Sequence': 105,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apiarist/Beekeeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3991,
                        'IsSynonym': false,
                        'Sequence': 106,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apiary Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 3992,
                        'IsSynonym': false,
                        'Sequence': 107,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apparel Cutter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3993,
                        'IsSynonym': false,
                        'Sequence': 108,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Application Developer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3994,
                        'IsSynonym': false,
                        'Sequence': 109,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Applications Programmer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 3995,
                        'IsSynonym': false,
                        'Sequence': 110,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Appointments Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 3996,
                        'IsSynonym': false,
                        'Sequence': 111,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Aircraft Ground Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3997,
                        'IsSynonym': false,
                        'Sequence': 112,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Aircraft Machinist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3998,
                        'IsSynonym': false,
                        'Sequence': 113,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Aircraft Maintenance Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 3999,
                        'IsSynonym': false,
                        'Sequence': 114,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Aircraft Mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4000,
                        'IsSynonym': false,
                        'Sequence': 115,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Baker and Pastrycook'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4001,
                        'IsSynonym': false,
                        'Sequence': 116,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Binder and Finisher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4002,
                        'IsSynonym': false,
                        'Sequence': 117,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Boatbuilder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4003,
                        'IsSynonym': false,
                        'Sequence': 118,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Boilermaker (Non-Mining)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4004,
                        'IsSynonym': false,
                        'Sequence': 119,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Buttermaker/Cheesemaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4005,
                        'IsSynonym': false,
                        'Sequence': 120,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Cabinetmaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4006,
                        'IsSynonym': false,
                        'Sequence': 121,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Cane Furniture Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4007,
                        'IsSynonym': false,
                        'Sequence': 122,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Canvas Goods Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4008,
                        'IsSynonym': false,
                        'Sequence': 123,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Compositor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4009,
                        'IsSynonym': false,
                        'Sequence': 124,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Cook'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4010,
                        'IsSynonym': false,
                        'Sequence': 125,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Craftworker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4011,
                        'IsSynonym': false,
                        'Sequence': 126,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Dental Mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4012,
                        'IsSynonym': false,
                        'Sequence': 127,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Furniture Finisher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4013,
                        'IsSynonym': false,
                        'Sequence': 128,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Jeweller'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4014,
                        'IsSynonym': false,
                        'Sequence': 129,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Jewellery and Precious Metalware Tradesperson'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4015,
                        'IsSynonym': false,
                        'Sequence': 130,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Jockey'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4016,
                        'IsSynonym': false,
                        'Sequence': 131,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Landscape Gardener'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4017,
                        'IsSynonym': false,
                        'Sequence': 132,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Leather Goods Maker'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4018,
                        'IsSynonym': false,
                        'Sequence': 133,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Miner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4019,
                        'IsSynonym': false,
                        'Sequence': 134,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Model Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4020,
                        'IsSynonym': false,
                        'Sequence': 135,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Musical Instrument Maker and Repairer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4021,
                        'IsSynonym': false,
                        'Sequence': 136,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Nurseryman/woman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4022,
                        'IsSynonym': false,
                        'Sequence': 137,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Optical Mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4023,
                        'IsSynonym': false,
                        'Sequence': 138,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Painter, Decorator and Signwriter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4024,
                        'IsSynonym': false,
                        'Sequence': 139,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Piano Tuner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4025,
                        'IsSynonym': false,
                        'Sequence': 140,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Picture Framer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4026,
                        'IsSynonym': false,
                        'Sequence': 141,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Refrigeration and Air-Conditioning Mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4027,
                        'IsSynonym': false,
                        'Sequence': 142,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Sailmaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4028,
                        'IsSynonym': false,
                        'Sequence': 143,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Shoemaker and Repairer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4029,
                        'IsSynonym': false,
                        'Sequence': 144,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Silversmith'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4030,
                        'IsSynonym': false,
                        'Sequence': 145,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Smallgoods Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4031,
                        'IsSynonym': false,
                        'Sequence': 146,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Sprinkler Fitter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4032,
                        'IsSynonym': false,
                        'Sequence': 147,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Stereotyper and Electrotyper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4033,
                        'IsSynonym': false,
                        'Sequence': 148,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Tailor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4034,
                        'IsSynonym': false,
                        'Sequence': 149,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Upholsterer and Bedding Tradesperson'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4035,
                        'IsSynonym': false,
                        'Sequence': 150,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Apprentice Watch and Clock Repairer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4036,
                        'IsSynonym': false,
                        'Sequence': 151,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Arbitration Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4037,
                        'IsSynonym': false,
                        'Sequence': 152,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Arbitrator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4038,
                        'IsSynonym': false,
                        'Sequence': 153,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Arboriculturist (No tree climbing)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4039,
                        'IsSynonym': false,
                        'Sequence': 154,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Arboriculturist (Tree climbing)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4040,
                        'IsSynonym': false,
                        'Sequence': 155,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Archaeologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4041,
                        'IsSynonym': false,
                        'Sequence': 156,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Archery - Professional'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4042,
                        'IsSynonym': false,
                        'Sequence': 157,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architect - Landscape'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4043,
                        'IsSynonym': false,
                        'Sequence': 158,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architect - Naval'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4044,
                        'IsSynonym': false,
                        'Sequence': 159,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architect (No Site Visits)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4045,
                        'IsSynonym': false,
                        'Sequence': 160,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architect (Site visits)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4046,
                        'IsSynonym': false,
                        'Sequence': 161,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architectural Associate'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4047,
                        'IsSynonym': false,
                        'Sequence': 162,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architectural Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4048,
                        'IsSynonym': false,
                        'Sequence': 163,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architectural Drafting Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4049,
                        'IsSynonym': false,
                        'Sequence': 164,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architectural Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4050,
                        'IsSynonym': false,
                        'Sequence': 165,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architectural Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4051,
                        'IsSynonym': false,
                        'Sequence': 166,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Architectural Tracer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4052,
                        'IsSynonym': false,
                        'Sequence': 167,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Archivist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4053,
                        'IsSynonym': false,
                        'Sequence': 168,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Armed Response Unit'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4054,
                        'IsSynonym': false,
                        'Sequence': 169,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Aromatherapist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4055,
                        'IsSynonym': false,
                        'Sequence': 170,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Art Critic'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4056,
                        'IsSynonym': false,
                        'Sequence': 171,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Art Dealer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4057,
                        'IsSynonym': false,
                        'Sequence': 172,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Art Director (Film/Stage/Television)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4058,
                        'IsSynonym': false,
                        'Sequence': 173,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Art Gallery Curator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4059,
                        'IsSynonym': false,
                        'Sequence': 174,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Art Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4060,
                        'IsSynonym': false,
                        'Sequence': 175,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Articled Accountant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4061,
                        'IsSynonym': false,
                        'Sequence': 176,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Articled Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4062,
                        'IsSynonym': false,
                        'Sequence': 177,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Articled Lawyer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4063,
                        'IsSynonym': false,
                        'Sequence': 178,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Artist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4064,
                        'IsSynonym': false,
                        'Sequence': 179,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Arts Reporter (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4065,
                        'IsSynonym': false,
                        'Sequence': 180,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Arts Reporter (> 10% travel)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4066,
                        'IsSynonym': false,
                        'Sequence': 181,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Asbestos Inspector'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4067,
                        'IsSynonym': false,
                        'Sequence': 182,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Asbestos Stripper/Lagger/Insulator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4068,
                        'IsSynonym': false,
                        'Sequence': 183,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Assayer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4069,
                        'IsSynonym': false,
                        'Sequence': 184,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Assistant Driller - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4070,
                        'IsSynonym': false,
                        'Sequence': 185,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Assistant Editor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4071,
                        'IsSynonym': false,
                        'Sequence': 186,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Associate Editor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4072,
                        'IsSynonym': false,
                        'Sequence': 187,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Astrologer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4073,
                        'IsSynonym': false,
                        'Sequence': 188,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Astronomer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4074,
                        'IsSynonym': false,
                        'Sequence': 189,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Athletics - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4075,
                        'IsSynonym': false,
                        'Sequence': 190,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Attorney'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4076,
                        'IsSynonym': false,
                        'Sequence': 191,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Auctioneer (Livestock)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4077,
                        'IsSynonym': false,
                        'Sequence': 192,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Auctioneer (No livestock)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4078,
                        'IsSynonym': false,
                        'Sequence': 193,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Audio Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4079,
                        'IsSynonym': false,
                        'Sequence': 194,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Audio Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4080,
                        'IsSynonym': false,
                        'Sequence': 195,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Audiologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4081,
                        'IsSynonym': false,
                        'Sequence': 196,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Audiometrician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4082,
                        'IsSynonym': false,
                        'Sequence': 197,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Audit Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4083,
                        'IsSynonym': false,
                        'Sequence': 198,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Auditor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4084,
                        'IsSynonym': false,
                        'Sequence': 199,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Author'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4085,
                        'IsSynonym': false,
                        'Sequence': 200,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Auto Glass Installer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4086,
                        'IsSynonym': false,
                        'Sequence': 201,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Auto-electrician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4087,
                        'IsSynonym': false,
                        'Sequence': 202,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Automotive Air-Conditioning Mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4088,
                        'IsSynonym': false,
                        'Sequence': 203,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Awnings Installer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4089,
                        'IsSynonym': false,
                        'Sequence': 204,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Baby Sitter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4090,
                        'IsSynonym': false,
                        'Sequence': 205,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bacteriologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4091,
                        'IsSynonym': false,
                        'Sequence': 206,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Badminton - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4092,
                        'IsSynonym': false,
                        'Sequence': 207,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Baggage Handler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4093,
                        'IsSynonym': false,
                        'Sequence': 208,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bailiff'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4094,
                        'IsSynonym': false,
                        'Sequence': 209,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Baker/Bakers assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4095,
                        'IsSynonym': false,
                        'Sequence': 210,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bakery Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4096,
                        'IsSynonym': false,
                        'Sequence': 211,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Baking Factory Hand'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4097,
                        'IsSynonym': false,
                        'Sequence': 212,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ballet Dancer - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4098,
                        'IsSynonym': false,
                        'Sequence': 213,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ballet Teacher'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4099,
                        'IsSynonym': false,
                        'Sequence': 214,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Balloonist (Competition/Commercial)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4100,
                        'IsSynonym': false,
                        'Sequence': 215,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bank Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4101,
                        'IsSynonym': false,
                        'Sequence': 216,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bank manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4102,
                        'IsSynonym': false,
                        'Sequence': 217,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bank Teller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4103,
                        'IsSynonym': false,
                        'Sequence': 218,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Banker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4104,
                        'IsSynonym': false,
                        'Sequence': 219,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bargeman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4105,
                        'IsSynonym': false,
                        'Sequence': 220,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bargemaster - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4106,
                        'IsSynonym': false,
                        'Sequence': 221,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Barman/Bar Attendant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4107,
                        'IsSynonym': false,
                        'Sequence': 222,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Barrister'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4108,
                        'IsSynonym': false,
                        'Sequence': 223,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Basic Metal Products Machine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4109,
                        'IsSynonym': false,
                        'Sequence': 224,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Basketball - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4110,
                        'IsSynonym': false,
                        'Sequence': 225,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Beautician/Beauty Therapist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4111,
                        'IsSynonym': false,
                        'Sequence': 226,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Beauty consultant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4112,
                        'IsSynonym': false,
                        'Sequence': 227,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Beauty Parlour Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4113,
                        'IsSynonym': false,
                        'Sequence': 228,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bedding and Mattress Maker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4114,
                        'IsSynonym': false,
                        'Sequence': 229,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bibliographer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4115,
                        'IsSynonym': false,
                        'Sequence': 230,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Binder and Finisher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4116,
                        'IsSynonym': false,
                        'Sequence': 231,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bingo Caller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4117,
                        'IsSynonym': false,
                        'Sequence': 232,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biochemist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4118,
                        'IsSynonym': false,
                        'Sequence': 233,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biogeneticist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4119,
                        'IsSynonym': false,
                        'Sequence': 234,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biogeographer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4120,
                        'IsSynonym': false,
                        'Sequence': 235,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4121,
                        'IsSynonym': false,
                        'Sequence': 236,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biokineticist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4122,
                        'IsSynonym': false,
                        'Sequence': 237,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4123,
                        'IsSynonym': false,
                        'Sequence': 238,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biology Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4124,
                        'IsSynonym': false,
                        'Sequence': 239,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biomedical Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4125,
                        'IsSynonym': false,
                        'Sequence': 240,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biometrician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4126,
                        'IsSynonym': false,
                        'Sequence': 241,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biophysicist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4127,
                        'IsSynonym': false,
                        'Sequence': 242,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Biostatistician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4128,
                        'IsSynonym': false,
                        'Sequence': 243,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Blacksmith'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4129,
                        'IsSynonym': false,
                        'Sequence': 244,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Blaster, demolition worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4130,
                        'IsSynonym': false,
                        'Sequence': 245,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bleaching Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4131,
                        'IsSynonym': false,
                        'Sequence': 246,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Blind Installer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4132,
                        'IsSynonym': false,
                        'Sequence': 247,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Blindstitcher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4133,
                        'IsSynonym': false,
                        'Sequence': 248,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Blood Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4134,
                        'IsSynonym': false,
                        'Sequence': 249,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Blood Transfusionist'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4135,
                        'IsSynonym': false,
                        'Sequence': 250,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boarded'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4136,
                        'IsSynonym': false,
                        'Sequence': 251,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boarding House Proprietor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4137,
                        'IsSynonym': false,
                        'Sequence': 252,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boatbuilder'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4138,
                        'IsSynonym': false,
                        'Sequence': 253,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boatswain'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4139,
                        'IsSynonym': false,
                        'Sequence': 254,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bodyguard'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4140,
                        'IsSynonym': false,
                        'Sequence': 255,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boilermaker (Non-Mining)'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4141,
                        'IsSynonym': false,
                        'Sequence': 256,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bomb Disposal Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4142,
                        'IsSynonym': false,
                        'Sequence': 257,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bond Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4143,
                        'IsSynonym': false,
                        'Sequence': 258,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Book Editor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4144,
                        'IsSynonym': false,
                        'Sequence': 259,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bookbinder'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4145,
                        'IsSynonym': false,
                        'Sequence': 260,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Booking Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4146,
                        'IsSynonym': false,
                        'Sequence': 261,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Booking Officer - Railways'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4147,
                        'IsSynonym': false,
                        'Sequence': 262,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bookkeeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4148,
                        'IsSynonym': false,
                        'Sequence': 263,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bookmaker - Betting Shop'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4149,
                        'IsSynonym': false,
                        'Sequence': 264,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bookshop Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4150,
                        'IsSynonym': false,
                        'Sequence': 265,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boot Maker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4151,
                        'IsSynonym': false,
                        'Sequence': 266,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bosun'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4152,
                        'IsSynonym': false,
                        'Sequence': 267,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Botanist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4153,
                        'IsSynonym': false,
                        'Sequence': 268,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Botany Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4154,
                        'IsSynonym': false,
                        'Sequence': 269,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bottle Store Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4155,
                        'IsSynonym': false,
                        'Sequence': 270,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bottle Store Owner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4156,
                        'IsSynonym': false,
                        'Sequence': 271,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bouncer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4157,
                        'IsSynonym': false,
                        'Sequence': 272,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bowling (Indoor & Lawn) - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4158,
                        'IsSynonym': false,
                        'Sequence': 273,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Box Maker (Cardboard/Plastic)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4159,
                        'IsSynonym': false,
                        'Sequence': 274,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boxer - Amateur'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4160,
                        'IsSynonym': false,
                        'Sequence': 275,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Boxer - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4161,
                        'IsSynonym': false,
                        'Sequence': 276,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Branch Accountant - Financial Institution'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4162,
                        'IsSynonym': false,
                        'Sequence': 277,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Brewer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4163,
                        'IsSynonym': false,
                        'Sequence': 278,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Brewhouse Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4164,
                        'IsSynonym': false,
                        'Sequence': 279,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bricklayer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4165,
                        'IsSynonym': false,
                        'Sequence': 280,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broadcast Transmitter Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4166,
                        'IsSynonym': false,
                        'Sequence': 281,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broadcaster'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4167,
                        'IsSynonym': false,
                        'Sequence': 282,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broadcasting Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4168,
                        'IsSynonym': false,
                        'Sequence': 283,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broadcasting Tradesperson'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4169,
                        'IsSynonym': false,
                        'Sequence': 284,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broker - Insurance/Stock (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4170,
                        'IsSynonym': false,
                        'Sequence': 285,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broker - Insurance/Stock (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4171,
                        'IsSynonym': false,
                        'Sequence': 286,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broker (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4172,
                        'IsSynonym': false,
                        'Sequence': 287,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broker (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4173,
                        'IsSynonym': false,
                        'Sequence': 288,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broker Consultant (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4174,
                        'IsSynonym': false,
                        'Sequence': 289,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Broker Consultant (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4175,
                        'IsSynonym': false,
                        'Sequence': 290,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Builder'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4176,
                        'IsSynonym': false,
                        'Sequence': 291,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Builders Merchant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4177,
                        'IsSynonym': false,
                        'Sequence': 292,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building And Construction Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4178,
                        'IsSynonym': false,
                        'Sequence': 293,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Construction Supervisor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4179,
                        'IsSynonym': false,
                        'Sequence': 294,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Contractor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4180,
                        'IsSynonym': false,
                        'Sequence': 295,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Estimator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4181,
                        'IsSynonym': false,
                        'Sequence': 296,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Foreman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4182,
                        'IsSynonym': false,
                        'Sequence': 297,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Inspector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4183,
                        'IsSynonym': false,
                        'Sequence': 298,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Insulation Installer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4184,
                        'IsSynonym': false,
                        'Sequence': 299,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Site Foreman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4185,
                        'IsSynonym': false,
                        'Sequence': 300,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Building Society Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4186,
                        'IsSynonym': false,
                        'Sequence': 301,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bulk Materials Handling Plant Operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4187,
                        'IsSynonym': false,
                        'Sequence': 302,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bulldozer Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4188,
                        'IsSynonym': false,
                        'Sequence': 303,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bullet Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4189,
                        'IsSynonym': false,
                        'Sequence': 304,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bus Inspector'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4190,
                        'IsSynonym': false,
                        'Sequence': 305,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bush Clearer (Explosives)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4191,
                        'IsSynonym': false,
                        'Sequence': 306,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Bush Clearer (No Explosives)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4192,
                        'IsSynonym': false,
                        'Sequence': 307,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4193,
                        'IsSynonym': false,
                        'Sequence': 308,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4194,
                        'IsSynonym': false,
                        'Sequence': 309,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Consultant (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4195,
                        'IsSynonym': false,
                        'Sequence': 310,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Consultant (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4196,
                        'IsSynonym': false,
                        'Sequence': 311,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Development Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4197,
                        'IsSynonym': false,
                        'Sequence': 312,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4198,
                        'IsSynonym': false,
                        'Sequence': 313,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4199,
                        'IsSynonym': false,
                        'Sequence': 314,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4200,
                        'IsSynonym': false,
                        'Sequence': 315,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4201,
                        'IsSynonym': false,
                        'Sequence': 316,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4202,
                        'IsSynonym': false,
                        'Sequence': 317,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4203,
                        'IsSynonym': false,
                        'Sequence': 318,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4204,
                        'IsSynonym': false,
                        'Sequence': 319,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4205,
                        'IsSynonym': false,
                        'Sequence': 320,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4206,
                        'IsSynonym': false,
                        'Sequence': 321,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4207,
                        'IsSynonym': false,
                        'Sequence': 322,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Owner (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4208,
                        'IsSynonym': false,
                        'Sequence': 323,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Business Secretary'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4209,
                        'IsSynonym': false,
                        'Sequence': 324,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Butcher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4210,
                        'IsSynonym': false,
                        'Sequence': 325,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Butler'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4211,
                        'IsSynonym': false,
                        'Sequence': 326,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Buttermaker/Cheesemaker/Yogurt Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4212,
                        'IsSynonym': false,
                        'Sequence': 327,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Buttonhole Machinist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4213,
                        'IsSynonym': false,
                        'Sequence': 328,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Buyer (Fashion, Retail and Wool)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4214,
                        'IsSynonym': false,
                        'Sequence': 329,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Buyer (Office Bound)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4215,
                        'IsSynonym': false,
                        'Sequence': 330,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cabaret Artist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4216,
                        'IsSynonym': false,
                        'Sequence': 331,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cabinetmaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4217,
                        'IsSynonym': false,
                        'Sequence': 332,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cable Hand'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4218,
                        'IsSynonym': false,
                        'Sequence': 333,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cable Jointers Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4219,
                        'IsSynonym': false,
                        'Sequence': 334,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cadastral Surveyor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4220,
                        'IsSynonym': false,
                        'Sequence': 335,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Caddie'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4221,
                        'IsSynonym': false,
                        'Sequence': 336,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cadet Accountant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4222,
                        'IsSynonym': false,
                        'Sequence': 337,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cadet Journalist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4223,
                        'IsSynonym': false,
                        'Sequence': 338,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cadet Marine Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4224,
                        'IsSynonym': false,
                        'Sequence': 339,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cafe Owner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4225,
                        'IsSynonym': false,
                        'Sequence': 340,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Call Centre Agent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4226,
                        'IsSynonym': false,
                        'Sequence': 341,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Call Centre Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4227,
                        'IsSynonym': false,
                        'Sequence': 342,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Call Centre Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4228,
                        'IsSynonym': false,
                        'Sequence': 343,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Camera Operator (Aerial Work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4229,
                        'IsSynonym': false,
                        'Sequence': 344,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Camera Repairer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4230,
                        'IsSynonym': false,
                        'Sequence': 345,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cameraman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4231,
                        'IsSynonym': false,
                        'Sequence': 346,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Candle Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4232,
                        'IsSynonym': false,
                        'Sequence': 347,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cane Furniture Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4233,
                        'IsSynonym': false,
                        'Sequence': 348,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Canoeing - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4234,
                        'IsSynonym': false,
                        'Sequence': 349,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Canteen Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4235,
                        'IsSynonym': false,
                        'Sequence': 350,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Canteen Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4236,
                        'IsSynonym': false,
                        'Sequence': 351,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Canvas Goods Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4237,
                        'IsSynonym': false,
                        'Sequence': 352,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Canvasser'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4238,
                        'IsSynonym': false,
                        'Sequence': 353,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Captain - Fishing Industry Offshore'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4239,
                        'IsSynonym': false,
                        'Sequence': 354,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Captain - Trawlerman Inshore'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4240,
                        'IsSynonym': false,
                        'Sequence': 355,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Car Assembly - Motor Trade'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4241,
                        'IsSynonym': false,
                        'Sequence': 356,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Car Detailer - Motor Trade'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4242,
                        'IsSynonym': false,
                        'Sequence': 357,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Caravan Park Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4243,
                        'IsSynonym': false,
                        'Sequence': 358,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Caravan Park Owner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4244,
                        'IsSynonym': false,
                        'Sequence': 359,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carcass Splitter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4245,
                        'IsSynonym': false,
                        'Sequence': 360,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cardiologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4246,
                        'IsSynonym': false,
                        'Sequence': 361,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cardiothoracic Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4247,
                        'IsSynonym': false,
                        'Sequence': 362,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cardiovascular Specialist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4248,
                        'IsSynonym': false,
                        'Sequence': 363,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Careers Counsellor and Adviser'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4249,
                        'IsSynonym': false,
                        'Sequence': 364,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4250,
                        'IsSynonym': false,
                        'Sequence': 365,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Caretaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4251,
                        'IsSynonym': false,
                        'Sequence': 366,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cargo Co-coordinator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4252,
                        'IsSynonym': false,
                        'Sequence': 367,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carpenter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4253,
                        'IsSynonym': false,
                        'Sequence': 368,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carpet Layer/Cleaner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4254,
                        'IsSynonym': false,
                        'Sequence': 369,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carpet Manufacturing Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4255,
                        'IsSynonym': false,
                        'Sequence': 370,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carpet Weaver'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4256,
                        'IsSynonym': false,
                        'Sequence': 371,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carpetlayers Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4257,
                        'IsSynonym': false,
                        'Sequence': 372,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cartographer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4258,
                        'IsSynonym': false,
                        'Sequence': 373,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Carton Making Machine Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4259,
                        'IsSynonym': false,
                        'Sequence': 374,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cartoonist'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4260,
                        'IsSynonym': false,
                        'Sequence': 375,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cash in transit - Fidelity/coin security guard'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4261,
                        'IsSynonym': false,
                        'Sequence': 376,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cashier (Not Office Based)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4262,
                        'IsSynonym': false,
                        'Sequence': 377,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cashier (Office Based)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4263,
                        'IsSynonym': false,
                        'Sequence': 378,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Casino Attendant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4264,
                        'IsSynonym': false,
                        'Sequence': 379,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Casting Director'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4265,
                        'IsSynonym': false,
                        'Sequence': 380,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Casting Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4266,
                        'IsSynonym': false,
                        'Sequence': 381,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Casual Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4267,
                        'IsSynonym': false,
                        'Sequence': 382,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cataloguer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4268,
                        'IsSynonym': false,
                        'Sequence': 383,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Caterer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4269,
                        'IsSynonym': false,
                        'Sequence': 384,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Catering Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4270,
                        'IsSynonym': false,
                        'Sequence': 385,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Catering officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4271,
                        'IsSynonym': false,
                        'Sequence': 386,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Catering staff'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4272,
                        'IsSynonym': false,
                        'Sequence': 387,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cattle Grazier'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4273,
                        'IsSynonym': false,
                        'Sequence': 388,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ceiling Fixer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4274,
                        'IsSynonym': false,
                        'Sequence': 389,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cellarman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4275,
                        'IsSynonym': false,
                        'Sequence': 390,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cement and Concrete Plant Labourer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4276,
                        'IsSynonym': false,
                        'Sequence': 391,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cement Plasterer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4277,
                        'IsSynonym': false,
                        'Sequence': 392,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cement Production Plant Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4278,
                        'IsSynonym': false,
                        'Sequence': 393,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ceramics Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4279,
                        'IsSynonym': false,
                        'Sequence': 394,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ceramics Scientist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4280,
                        'IsSynonym': false,
                        'Sequence': 395,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chambermaid - Hotel Workers'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4281,
                        'IsSynonym': false,
                        'Sequence': 396,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chaplain'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4282,
                        'IsSynonym': false,
                        'Sequence': 397,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Charge Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4283,
                        'IsSynonym': false,
                        'Sequence': 398,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Charge Sister'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4284,
                        'IsSynonym': false,
                        'Sequence': 399,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Charity Collection Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4285,
                        'IsSynonym': false,
                        'Sequence': 400,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chartered Accountant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4286,
                        'IsSynonym': false,
                        'Sequence': 401,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Checkout Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4287,
                        'IsSynonym': false,
                        'Sequence': 402,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Checkout Supervisor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4288,
                        'IsSynonym': false,
                        'Sequence': 403,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chef - executive/head chef'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4289,
                        'IsSynonym': false,
                        'Sequence': 404,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chef - sous chef'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4290,
                        'IsSynonym': false,
                        'Sequence': 405,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chef - specialist/station chef'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4291,
                        'IsSynonym': false,
                        'Sequence': 406,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chemical Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4292,
                        'IsSynonym': false,
                        'Sequence': 407,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chemical Plant Labourer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4293,
                        'IsSynonym': false,
                        'Sequence': 408,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chemical Process Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4294,
                        'IsSynonym': false,
                        'Sequence': 409,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chemical Research Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4295,
                        'IsSynonym': false,
                        'Sequence': 410,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chemist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4296,
                        'IsSynonym': false,
                        'Sequence': 411,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chemistry Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4297,
                        'IsSynonym': false,
                        'Sequence': 412,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Accountant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4298,
                        'IsSynonym': false,
                        'Sequence': 413,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Analyst/Chemist (Non-Mining)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4299,
                        'IsSynonym': false,
                        'Sequence': 414,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Civil Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4300,
                        'IsSynonym': false,
                        'Sequence': 415,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Communications Officer (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4301,
                        'IsSynonym': false,
                        'Sequence': 416,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Communications Officer (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4302,
                        'IsSynonym': false,
                        'Sequence': 417,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Compliance Officer (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4303,
                        'IsSynonym': false,
                        'Sequence': 418,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Compliance Officer (> 20% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4304,
                        'IsSynonym': false,
                        'Sequence': 419,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Cook'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4305,
                        'IsSynonym': false,
                        'Sequence': 420,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Creative Officer (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4306,
                        'IsSynonym': false,
                        'Sequence': 421,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Creative Officer (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4307,
                        'IsSynonym': false,
                        'Sequence': 422,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Electronics Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4308,
                        'IsSynonym': false,
                        'Sequence': 423,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4309,
                        'IsSynonym': false,
                        'Sequence': 424,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4310,
                        'IsSynonym': false,
                        'Sequence': 425,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4311,
                        'IsSynonym': false,
                        'Sequence': 426,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4312,
                        'IsSynonym': false,
                        'Sequence': 427,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4313,
                        'IsSynonym': false,
                        'Sequence': 428,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4314,
                        'IsSynonym': false,
                        'Sequence': 429,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4315,
                        'IsSynonym': false,
                        'Sequence': 430,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4316,
                        'IsSynonym': false,
                        'Sequence': 431,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4317,
                        'IsSynonym': false,
                        'Sequence': 432,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4318,
                        'IsSynonym': false,
                        'Sequence': 433,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Financial Officer - CFO (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4319,
                        'IsSynonym': false,
                        'Sequence': 434,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Fire Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4320,
                        'IsSynonym': false,
                        'Sequence': 435,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Geologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4321,
                        'IsSynonym': false,
                        'Sequence': 436,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Information Officer (CIO) (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4322,
                        'IsSynonym': false,
                        'Sequence': 437,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Information Officer (CIO) (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4323,
                        'IsSynonym': false,
                        'Sequence': 438,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Legal Officer (CLO) (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4324,
                        'IsSynonym': false,
                        'Sequence': 439,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Legal Officer (CLO) (> 20% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4325,
                        'IsSynonym': false,
                        'Sequence': 440,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Marine Steward'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4326,
                        'IsSynonym': false,
                        'Sequence': 441,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Marketing Officer (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4327,
                        'IsSynonym': false,
                        'Sequence': 442,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Marketing Officer (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4328,
                        'IsSynonym': false,
                        'Sequence': 443,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Mechanical Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4329,
                        'IsSynonym': false,
                        'Sequence': 444,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Medical Officer (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4330,
                        'IsSynonym': false,
                        'Sequence': 445,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Medical Officer (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4331,
                        'IsSynonym': false,
                        'Sequence': 446,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Operating Officer (COO) (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4332,
                        'IsSynonym': false,
                        'Sequence': 447,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Operating Officer (COO) (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4333,
                        'IsSynonym': false,
                        'Sequence': 448,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Physicist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4334,
                        'IsSynonym': false,
                        'Sequence': 449,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Risk Officer (CRO) (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4335,
                        'IsSynonym': false,
                        'Sequence': 450,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Risk Officer (CRO) (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4336,
                        'IsSynonym': false,
                        'Sequence': 451,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Technology Officer (CTO) (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4337,
                        'IsSynonym': false,
                        'Sequence': 452,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chief Technology Officer (CTO) (> 20% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4338,
                        'IsSynonym': false,
                        'Sequence': 453,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Child Care Aide'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4339,
                        'IsSynonym': false,
                        'Sequence': 454,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Child Care Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4340,
                        'IsSynonym': false,
                        'Sequence': 455,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Child Care Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4341,
                        'IsSynonym': false,
                        'Sequence': 456,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Child Care Group Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4342,
                        'IsSynonym': false,
                        'Sequence': 457,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Child Care Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4343,
                        'IsSynonym': false,
                        'Sequence': 458,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Child Minder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4344,
                        'IsSynonym': false,
                        'Sequence': 459,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Children\'s Nursery Assistant/Creche Attendant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4345,
                        'IsSynonym': false,
                        'Sequence': 460,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chimney Sweep'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4346,
                        'IsSynonym': false,
                        'Sequence': 461,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chinese Medicine Specialist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4347,
                        'IsSynonym': false,
                        'Sequence': 462,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chiropodist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4348,
                        'IsSynonym': false,
                        'Sequence': 463,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Chiropractor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4349,
                        'IsSynonym': false,
                        'Sequence': 464,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Choral Director'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4350,
                        'IsSynonym': false,
                        'Sequence': 465,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Choreographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4351,
                        'IsSynonym': false,
                        'Sequence': 466,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Christian Scientist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4352,
                        'IsSynonym': false,
                        'Sequence': 467,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cigarette Making Machine Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4353,
                        'IsSynonym': false,
                        'Sequence': 468,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cinema Projectionist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4354,
                        'IsSynonym': false,
                        'Sequence': 469,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cinema/Theatre Administrative Staff'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4355,
                        'IsSynonym': false,
                        'Sequence': 470,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cinema/Theatre Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4356,
                        'IsSynonym': false,
                        'Sequence': 471,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cinematographer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4357,
                        'IsSynonym': false,
                        'Sequence': 472,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Circus Artist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4358,
                        'IsSynonym': false,
                        'Sequence': 473,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'City Treasurer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4359,
                        'IsSynonym': false,
                        'Sequence': 474,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineer (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4360,
                        'IsSynonym': false,
                        'Sequence': 475,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineer (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4361,
                        'IsSynonym': false,
                        'Sequence': 476,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Associate'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4362,
                        'IsSynonym': false,
                        'Sequence': 477,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Design Draftsperson'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4363,
                        'IsSynonym': false,
                        'Sequence': 478,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Detail Draftsperson'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4364,
                        'IsSynonym': false,
                        'Sequence': 479,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Drafting Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4365,
                        'IsSynonym': false,
                        'Sequence': 480,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Drafting Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4366,
                        'IsSynonym': false,
                        'Sequence': 481,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4367,
                        'IsSynonym': false,
                        'Sequence': 482,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Engineering Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4368,
                        'IsSynonym': false,
                        'Sequence': 483,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Civil Servant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4369,
                        'IsSynonym': false,
                        'Sequence': 484,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Claims Adjuster'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4370,
                        'IsSynonym': false,
                        'Sequence': 485,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Claims Assessor (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4371,
                        'IsSynonym': false,
                        'Sequence': 486,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Claims Assessor (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4372,
                        'IsSynonym': false,
                        'Sequence': 487,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Claims Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4373,
                        'IsSynonym': false,
                        'Sequence': 488,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Classified Advertising Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4374,
                        'IsSynonym': false,
                        'Sequence': 489,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clergyman/woman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4375,
                        'IsSynonym': false,
                        'Sequence': 490,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clerical Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4376,
                        'IsSynonym': false,
                        'Sequence': 491,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clerk of Court'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4377,
                        'IsSynonym': false,
                        'Sequence': 492,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Climbing instructor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4378,
                        'IsSynonym': false,
                        'Sequence': 493,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Allergist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4379,
                        'IsSynonym': false,
                        'Sequence': 494,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Biochemist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4380,
                        'IsSynonym': false,
                        'Sequence': 495,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Biochemistry Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4381,
                        'IsSynonym': false,
                        'Sequence': 496,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Chemist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4382,
                        'IsSynonym': false,
                        'Sequence': 497,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Endocrinologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4383,
                        'IsSynonym': false,
                        'Sequence': 498,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Laboratory Scientist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4384,
                        'IsSynonym': false,
                        'Sequence': 499,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Microbiologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4385,
                        'IsSynonym': false,
                        'Sequence': 500,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Microbiology Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4386,
                        'IsSynonym': false,
                        'Sequence': 501,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Nurse Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4387,
                        'IsSynonym': false,
                        'Sequence': 502,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Pharmacologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4388,
                        'IsSynonym': false,
                        'Sequence': 503,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Physical Scientist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4389,
                        'IsSynonym': false,
                        'Sequence': 504,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Psychologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4390,
                        'IsSynonym': false,
                        'Sequence': 505,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clinical Technologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4391,
                        'IsSynonym': false,
                        'Sequence': 506,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clothing Pattern Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4392,
                        'IsSynonym': false,
                        'Sequence': 507,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clothing Presser'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4393,
                        'IsSynonym': false,
                        'Sequence': 508,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Clown'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4394,
                        'IsSynonym': false,
                        'Sequence': 509,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Club Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4395,
                        'IsSynonym': false,
                        'Sequence': 510,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Coastguard (non office based)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4396,
                        'IsSynonym': false,
                        'Sequence': 511,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Coastguard (office based)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4397,
                        'IsSynonym': false,
                        'Sequence': 512,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Coffin Maker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4398,
                        'IsSynonym': false,
                        'Sequence': 513,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Columnist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4399,
                        'IsSynonym': false,
                        'Sequence': 514,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Combustion Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4400,
                        'IsSynonym': false,
                        'Sequence': 515,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Comedian'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4401,
                        'IsSynonym': false,
                        'Sequence': 516,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commentator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4402,
                        'IsSynonym': false,
                        'Sequence': 517,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commercial Artist'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4403,
                        'IsSynonym': false,
                        'Sequence': 518,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commercial Diver (Not Using Explosives)'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4404,
                        'IsSynonym': false,
                        'Sequence': 519,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commercial Diver (Using Explosives)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4405,
                        'IsSynonym': false,
                        'Sequence': 520,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commercial Flower Grower'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4406,
                        'IsSynonym': false,
                        'Sequence': 521,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commodity Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4407,
                        'IsSynonym': false,
                        'Sequence': 522,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Commodity Trader'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4408,
                        'IsSynonym': false,
                        'Sequence': 523,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Communications Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4409,
                        'IsSynonym': false,
                        'Sequence': 524,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Communications Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4410,
                        'IsSynonym': false,
                        'Sequence': 525,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Communications Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4411,
                        'IsSynonym': false,
                        'Sequence': 526,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Communications Engineering Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4412,
                        'IsSynonym': false,
                        'Sequence': 527,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Communications Programmer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4413,
                        'IsSynonym': false,
                        'Sequence': 528,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Community Development Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4414,
                        'IsSynonym': false,
                        'Sequence': 529,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Company Secretary'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4415,
                        'IsSynonym': false,
                        'Sequence': 530,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Complaints Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4416,
                        'IsSynonym': false,
                        'Sequence': 531,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Compliance Officer (< 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4417,
                        'IsSynonym': false,
                        'Sequence': 532,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Compliance Officer (> 20% travel or > 20% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4418,
                        'IsSynonym': false,
                        'Sequence': 533,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Composer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4419,
                        'IsSynonym': false,
                        'Sequence': 534,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Computer Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4420,
                        'IsSynonym': false,
                        'Sequence': 535,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Computer Auditor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4421,
                        'IsSynonym': false,
                        'Sequence': 536,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Computer Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4422,
                        'IsSynonym': false,
                        'Sequence': 537,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Computer Scientist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4423,
                        'IsSynonym': false,
                        'Sequence': 538,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Computer Servicer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4424,
                        'IsSynonym': false,
                        'Sequence': 539,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Computer Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4425,
                        'IsSynonym': false,
                        'Sequence': 540,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Concert and Opera Singer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4426,
                        'IsSynonym': false,
                        'Sequence': 541,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conciliation and Arbitration Commissioner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4427,
                        'IsSynonym': false,
                        'Sequence': 542,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Concrete Batching Plant Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4428,
                        'IsSynonym': false,
                        'Sequence': 543,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Concrete Pipe Machine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4429,
                        'IsSynonym': false,
                        'Sequence': 544,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Concrete Pre-cast Moulder'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4430,
                        'IsSynonym': false,
                        'Sequence': 545,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Concrete Slab Layer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4431,
                        'IsSynonym': false,
                        'Sequence': 546,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conductor (Bus/Train)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4432,
                        'IsSynonym': false,
                        'Sequence': 547,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Confectioner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4433,
                        'IsSynonym': false,
                        'Sequence': 548,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conservator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4434,
                        'IsSynonym': false,
                        'Sequence': 549,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Consul-General'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4435,
                        'IsSynonym': false,
                        'Sequence': 550,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Consultant (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4436,
                        'IsSynonym': false,
                        'Sequence': 551,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Consultant (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4437,
                        'IsSynonym': false,
                        'Sequence': 552,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Container Loading Clerk'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4438,
                        'IsSynonym': false,
                        'Sequence': 553,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Contract Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4439,
                        'IsSynonym': false,
                        'Sequence': 554,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Contractor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4440,
                        'IsSynonym': false,
                        'Sequence': 555,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Contractor – Non-Mining (< 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4441,
                        'IsSynonym': false,
                        'Sequence': 556,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Contractor – Non-Mining (> 20% travel or > 20% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4442,
                        'IsSynonym': false,
                        'Sequence': 557,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Control room operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4443,
                        'IsSynonym': false,
                        'Sequence': 558,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conveyancer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4444,
                        'IsSynonym': false,
                        'Sequence': 559,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conveyancing Clerk'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4445,
                        'IsSynonym': false,
                        'Sequence': 560,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conveyor Belt Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4446,
                        'IsSynonym': false,
                        'Sequence': 561,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Conveyor operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4447,
                        'IsSynonym': false,
                        'Sequence': 562,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Copywriter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4448,
                        'IsSynonym': false,
                        'Sequence': 563,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Coronary Care Nurse'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4449,
                        'IsSynonym': false,
                        'Sequence': 564,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Coroner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4450,
                        'IsSynonym': false,
                        'Sequence': 565,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Corporate Finance Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4451,
                        'IsSynonym': false,
                        'Sequence': 566,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Corporate Legal Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4452,
                        'IsSynonym': false,
                        'Sequence': 567,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Corporate Safety Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4453,
                        'IsSynonym': false,
                        'Sequence': 568,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Area commissioner/Deputy Regional Commissioner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4454,
                        'IsSynonym': false,
                        'Sequence': 569,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Assistant Director'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4455,
                        'IsSynonym': false,
                        'Sequence': 570,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Case Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4456,
                        'IsSynonym': false,
                        'Sequence': 571,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Deputy Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4457,
                        'IsSynonym': false,
                        'Sequence': 572,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Director General'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4458,
                        'IsSynonym': false,
                        'Sequence': 573,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Director/Area Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4459,
                        'IsSynonym': false,
                        'Sequence': 574,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Regional Commissioner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4460,
                        'IsSynonym': false,
                        'Sequence': 575,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Reintegration Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4461,
                        'IsSynonym': false,
                        'Sequence': 576,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Security Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4462,
                        'IsSynonym': false,
                        'Sequence': 577,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Senior Correctional Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4463,
                        'IsSynonym': false,
                        'Sequence': 578,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services CB: Unit Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4464,
                        'IsSynonym': false,
                        'Sequence': 579,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Admin Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4465,
                        'IsSynonym': false,
                        'Sequence': 580,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Assistant Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4466,
                        'IsSynonym': false,
                        'Sequence': 581,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Chief Deputy Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4467,
                        'IsSynonym': false,
                        'Sequence': 582,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4468,
                        'IsSynonym': false,
                        'Sequence': 583,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Deputy Commissioner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4469,
                        'IsSynonym': false,
                        'Sequence': 584,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Deputy Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4470,
                        'IsSynonym': false,
                        'Sequence': 585,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4471,
                        'IsSynonym': false,
                        'Sequence': 586,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: National Commissioner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4472,
                        'IsSynonym': false,
                        'Sequence': 587,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services non-CB: Senior Admin Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4473,
                        'IsSynonym': false,
                        'Sequence': 588,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Correctional services: Emergency Support team'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4474,
                        'IsSynonym': false,
                        'Sequence': 589,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cosmologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4475,
                        'IsSynonym': false,
                        'Sequence': 590,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cost Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4476,
                        'IsSynonym': false,
                        'Sequence': 591,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cotton Ginner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4477,
                        'IsSynonym': false,
                        'Sequence': 592,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cotton Ginning Bookman/woman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4478,
                        'IsSynonym': false,
                        'Sequence': 593,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cotton Grader'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4479,
                        'IsSynonym': false,
                        'Sequence': 594,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cotton Grower'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4480,
                        'IsSynonym': false,
                        'Sequence': 595,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Counter Staff - Post Office Workers'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4481,
                        'IsSynonym': false,
                        'Sequence': 596,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Courier (Motorcycle or Bicyle)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4482,
                        'IsSynonym': false,
                        'Sequence': 597,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Courier (Not Motorcycle or Bicycle)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4483,
                        'IsSynonym': false,
                        'Sequence': 598,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Court and Hansard Reporter'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4484,
                        'IsSynonym': false,
                        'Sequence': 599,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Court Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4485,
                        'IsSynonym': false,
                        'Sequence': 600,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Court Messenger'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4486,
                        'IsSynonym': false,
                        'Sequence': 601,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Court Orderly'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4487,
                        'IsSynonym': false,
                        'Sequence': 602,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Court Registrar'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4488,
                        'IsSynonym': false,
                        'Sequence': 603,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Court Usher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4489,
                        'IsSynonym': false,
                        'Sequence': 604,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Craftworker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4490,
                        'IsSynonym': false,
                        'Sequence': 605,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Crane Operator - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4491,
                        'IsSynonym': false,
                        'Sequence': 606,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Creche Owner (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4492,
                        'IsSynonym': false,
                        'Sequence': 607,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Creche Owner (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4493,
                        'IsSynonym': false,
                        'Sequence': 608,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Creche/Nursary school teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4494,
                        'IsSynonym': false,
                        'Sequence': 609,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Credit and Loans Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4495,
                        'IsSynonym': false,
                        'Sequence': 610,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Credit Controller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4496,
                        'IsSynonym': false,
                        'Sequence': 611,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Credit Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4497,
                        'IsSynonym': false,
                        'Sequence': 612,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Credit Union Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4498,
                        'IsSynonym': false,
                        'Sequence': 613,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Creditors Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4499,
                        'IsSynonym': false,
                        'Sequence': 614,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Crematorium Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4500,
                        'IsSynonym': false,
                        'Sequence': 615,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cricketer - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4501,
                        'IsSynonym': false,
                        'Sequence': 616,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Criminologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4502,
                        'IsSynonym': false,
                        'Sequence': 617,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Croupier'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4503,
                        'IsSynonym': false,
                        'Sequence': 618,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Crystallographer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4504,
                        'IsSynonym': false,
                        'Sequence': 619,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Curator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4505,
                        'IsSynonym': false,
                        'Sequence': 620,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Curator, Gallery, Museaum'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4506,
                        'IsSynonym': false,
                        'Sequence': 621,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Curriculum Advisory Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4507,
                        'IsSynonym': false,
                        'Sequence': 622,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Curriculum Development Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4508,
                        'IsSynonym': false,
                        'Sequence': 623,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Customer Service Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4509,
                        'IsSynonym': false,
                        'Sequence': 624,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Customs Agent/officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4510,
                        'IsSynonym': false,
                        'Sequence': 625,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Customs Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4511,
                        'IsSynonym': false,
                        'Sequence': 626,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cyclist - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4512,
                        'IsSynonym': false,
                        'Sequence': 627,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cylinder Preparer (Printing)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4513,
                        'IsSynonym': false,
                        'Sequence': 628,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cylinder Reviser'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4514,
                        'IsSynonym': false,
                        'Sequence': 629,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cytologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4515,
                        'IsSynonym': false,
                        'Sequence': 630,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Cytopathology Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4516,
                        'IsSynonym': false,
                        'Sequence': 631,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dairy Factory Hand'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4517,
                        'IsSynonym': false,
                        'Sequence': 632,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dairy Produce Inspector'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4518,
                        'IsSynonym': false,
                        'Sequence': 633,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dance Teacher'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4519,
                        'IsSynonym': false,
                        'Sequence': 634,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dancer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4520,
                        'IsSynonym': false,
                        'Sequence': 635,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Data Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4521,
                        'IsSynonym': false,
                        'Sequence': 636,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Data Capturer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4522,
                        'IsSynonym': false,
                        'Sequence': 637,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Data Processing Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4523,
                        'IsSynonym': false,
                        'Sequence': 638,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Database Administrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4524,
                        'IsSynonym': false,
                        'Sequence': 639,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Database Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4525,
                        'IsSynonym': false,
                        'Sequence': 640,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Database Programmer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4526,
                        'IsSynonym': false,
                        'Sequence': 641,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Day Mother'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4527,
                        'IsSynonym': false,
                        'Sequence': 642,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Deacon/Deaconess'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4528,
                        'IsSynonym': false,
                        'Sequence': 643,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dean'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4529,
                        'IsSynonym': false,
                        'Sequence': 644,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Debt Collector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4530,
                        'IsSynonym': false,
                        'Sequence': 645,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Debtors Clerk'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4531,
                        'IsSynonym': false,
                        'Sequence': 646,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Deckhand'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4532,
                        'IsSynonym': false,
                        'Sequence': 647,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Delivery Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4533,
                        'IsSynonym': false,
                        'Sequence': 648,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Demographer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4534,
                        'IsSynonym': false,
                        'Sequence': 649,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Demolition, Supervisor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4535,
                        'IsSynonym': false,
                        'Sequence': 650,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4536,
                        'IsSynonym': false,
                        'Sequence': 651,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Mechanic'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4537,
                        'IsSynonym': false,
                        'Sequence': 652,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Nurse'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4538,
                        'IsSynonym': false,
                        'Sequence': 653,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Prosthetist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4539,
                        'IsSynonym': false,
                        'Sequence': 654,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Surgeon'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4540,
                        'IsSynonym': false,
                        'Sequence': 655,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Technologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4541,
                        'IsSynonym': false,
                        'Sequence': 656,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dental Therapist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4542,
                        'IsSynonym': false,
                        'Sequence': 657,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dentist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4543,
                        'IsSynonym': false,
                        'Sequence': 658,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Depositor and Grinder'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4544,
                        'IsSynonym': false,
                        'Sequence': 659,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Depot manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4545,
                        'IsSynonym': false,
                        'Sequence': 660,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Derivatives trader'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4546,
                        'IsSynonym': false,
                        'Sequence': 661,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dermatologist'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4547,
                        'IsSynonym': false,
                        'Sequence': 662,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Derrickman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4548,
                        'IsSynonym': false,
                        'Sequence': 663,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Designer - Carpet Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4549,
                        'IsSynonym': false,
                        'Sequence': 664,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Designer - Clothing Industry (Employed)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4550,
                        'IsSynonym': false,
                        'Sequence': 665,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Designer - Clothing Industry (Freelance)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4551,
                        'IsSynonym': false,
                        'Sequence': 666,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Designer - Glass Manufacturing'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4552,
                        'IsSynonym': false,
                        'Sequence': 667,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Designer - Industrial and Commercial Products'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4553,
                        'IsSynonym': false,
                        'Sequence': 668,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diagnostic Radiologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4554,
                        'IsSynonym': false,
                        'Sequence': 669,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dialysis Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4555,
                        'IsSynonym': false,
                        'Sequence': 670,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diamond Cutter/Polisher/Setter'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4556,
                        'IsSynonym': false,
                        'Sequence': 671,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diamond Dealer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4557,
                        'IsSynonym': false,
                        'Sequence': 672,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Die Setter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4558,
                        'IsSynonym': false,
                        'Sequence': 673,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diesel Mechanic'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4559,
                        'IsSynonym': false,
                        'Sequence': 674,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dietician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4560,
                        'IsSynonym': false,
                        'Sequence': 675,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Digital Marketing Consultant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4561,
                        'IsSynonym': false,
                        'Sequence': 676,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dining Room Host/Hostess'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4562,
                        'IsSynonym': false,
                        'Sequence': 677,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diplomat'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4563,
                        'IsSynonym': false,
                        'Sequence': 678,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4564,
                        'IsSynonym': false,
                        'Sequence': 679,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4565,
                        'IsSynonym': false,
                        'Sequence': 680,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4566,
                        'IsSynonym': false,
                        'Sequence': 681,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4567,
                        'IsSynonym': false,
                        'Sequence': 682,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4568,
                        'IsSynonym': false,
                        'Sequence': 683,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4569,
                        'IsSynonym': false,
                        'Sequence': 684,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4570,
                        'IsSynonym': false,
                        'Sequence': 685,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4571,
                        'IsSynonym': false,
                        'Sequence': 686,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4572,
                        'IsSynonym': false,
                        'Sequence': 687,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4573,
                        'IsSynonym': false,
                        'Sequence': 688,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4574,
                        'IsSynonym': false,
                        'Sequence': 689,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director (Film/Radio/Stage/Television)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4575,
                        'IsSynonym': false,
                        'Sequence': 690,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Director of Photography'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4576,
                        'IsSynonym': false,
                        'Sequence': 691,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Disc Jockey'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4577,
                        'IsSynonym': false,
                        'Sequence': 692,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dispatch Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4578,
                        'IsSynonym': false,
                        'Sequence': 693,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Distiller'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4579,
                        'IsSynonym': false,
                        'Sequence': 694,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Distiller, Alcohol Manufacture'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4580,
                        'IsSynonym': false,
                        'Sequence': 695,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Distribution Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4581,
                        'IsSynonym': false,
                        'Sequence': 696,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Distributor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4582,
                        'IsSynonym': false,
                        'Sequence': 697,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diver (Submersibles - No Outside Activity)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4583,
                        'IsSynonym': false,
                        'Sequence': 698,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Diver (Submersibles - Outside Activity)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4584,
                        'IsSynonym': false,
                        'Sequence': 699,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dock foreman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4585,
                        'IsSynonym': false,
                        'Sequence': 700,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dock Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4586,
                        'IsSynonym': false,
                        'Sequence': 701,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Docker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4587,
                        'IsSynonym': false,
                        'Sequence': 702,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dockhand'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4588,
                        'IsSynonym': false,
                        'Sequence': 703,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Docks Clerical Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4589,
                        'IsSynonym': false,
                        'Sequence': 704,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dog Groomer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4590,
                        'IsSynonym': false,
                        'Sequence': 705,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Domestic Animal Trainer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4591,
                        'IsSynonym': false,
                        'Sequence': 706,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Domestic Cook'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4592,
                        'IsSynonym': false,
                        'Sequence': 707,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Domestic Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4593,
                        'IsSynonym': false,
                        'Sequence': 708,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Domiciliary Nurse'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4594,
                        'IsSynonym': false,
                        'Sequence': 709,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Donkeyman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4595,
                        'IsSynonym': false,
                        'Sequence': 710,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Doorman (Nightclub)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4596,
                        'IsSynonym': false,
                        'Sequence': 711,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Doorman (Not Nightclub)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4597,
                        'IsSynonym': false,
                        'Sequence': 712,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drain Cleaner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4598,
                        'IsSynonym': false,
                        'Sequence': 713,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drama Teacher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4599,
                        'IsSynonym': false,
                        'Sequence': 714,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Draper'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4600,
                        'IsSynonym': false,
                        'Sequence': 715,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Draughtsman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4601,
                        'IsSynonym': false,
                        'Sequence': 716,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dredge Master'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4602,
                        'IsSynonym': false,
                        'Sequence': 717,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dredge Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4603,
                        'IsSynonym': false,
                        'Sequence': 718,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dress Designer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4604,
                        'IsSynonym': false,
                        'Sequence': 719,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dressmaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4605,
                        'IsSynonym': false,
                        'Sequence': 720,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dressmaker - Clothing Industry (Employed)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4606,
                        'IsSynonym': false,
                        'Sequence': 721,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dressmaker - Clothing Industry (Freelance or Self-employed)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4607,
                        'IsSynonym': false,
                        'Sequence': 722,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dressmaking Teacher'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4608,
                        'IsSynonym': false,
                        'Sequence': 723,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drill Crew - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4609,
                        'IsSynonym': false,
                        'Sequence': 724,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driller - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4610,
                        'IsSynonym': false,
                        'Sequence': 725,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driller (Non-Mining)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4611,
                        'IsSynonym': false,
                        'Sequence': 726,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drilling Engineer Offshore'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4612,
                        'IsSynonym': false,
                        'Sequence': 727,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drilling Fluids Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4613,
                        'IsSynonym': false,
                        'Sequence': 728,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Ambulance'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4614,
                        'IsSynonym': false,
                        'Sequence': 729,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Armoured Vehicle'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4615,
                        'IsSynonym': false,
                        'Sequence': 730,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Bus'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4616,
                        'IsSynonym': false,
                        'Sequence': 731,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Cash in transit'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4617,
                        'IsSynonym': false,
                        'Sequence': 732,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Chauffeur (not minibus)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4618,
                        'IsSynonym': false,
                        'Sequence': 733,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Concrete mixer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4619,
                        'IsSynonym': false,
                        'Sequence': 734,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Crane'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4620,
                        'IsSynonym': false,
                        'Sequence': 735,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Derrick (Building and construction)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4621,
                        'IsSynonym': false,
                        'Sequence': 736,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Garbage collection'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4622,
                        'IsSynonym': false,
                        'Sequence': 737,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Hearse'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4623,
                        'IsSynonym': false,
                        'Sequence': 738,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Heavy vehicle'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4624,
                        'IsSynonym': false,
                        'Sequence': 739,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Hoist/Winch'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4625,
                        'IsSynonym': false,
                        'Sequence': 740,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Light goods'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4626,
                        'IsSynonym': false,
                        'Sequence': 741,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Locomotive (Railways)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4627,
                        'IsSynonym': false,
                        'Sequence': 742,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Long distance'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4628,
                        'IsSynonym': false,
                        'Sequence': 743,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Minibus/Taxi'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4629,
                        'IsSynonym': false,
                        'Sequence': 744,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Motorcycle'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4630,
                        'IsSynonym': false,
                        'Sequence': 745,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Tow Truck'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4631,
                        'IsSynonym': false,
                        'Sequence': 746,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Tractor (Farm)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4632,
                        'IsSynonym': false,
                        'Sequence': 747,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driver - Train (Railways)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4633,
                        'IsSynonym': false,
                        'Sequence': 748,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Driving Instructor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4634,
                        'IsSynonym': false,
                        'Sequence': 749,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drover'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4635,
                        'IsSynonym': false,
                        'Sequence': 750,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drug and Alcohol Counsellor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4636,
                        'IsSynonym': false,
                        'Sequence': 751,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dry Clean Owner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4637,
                        'IsSynonym': false,
                        'Sequence': 752,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dry Cleaning Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4638,
                        'IsSynonym': false,
                        'Sequence': 753,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Drycleaner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4639,
                        'IsSynonym': false,
                        'Sequence': 754,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dubbing Projectionist (Sound Mixing)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4640,
                        'IsSynonym': false,
                        'Sequence': 755,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dye Penetrant Testing Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4641,
                        'IsSynonym': false,
                        'Sequence': 756,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dyeing and Finishing Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4642,
                        'IsSynonym': false,
                        'Sequence': 757,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Dyer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4643,
                        'IsSynonym': false,
                        'Sequence': 758,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Early Child Care Adviser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4644,
                        'IsSynonym': false,
                        'Sequence': 759,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Earth Science Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4645,
                        'IsSynonym': false,
                        'Sequence': 760,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Earth Science Technician'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4646,
                        'IsSynonym': false,
                        'Sequence': 761,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Earthmoving Labourer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4647,
                        'IsSynonym': false,
                        'Sequence': 762,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ecological Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4648,
                        'IsSynonym': false,
                        'Sequence': 763,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ecologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4649,
                        'IsSynonym': false,
                        'Sequence': 764,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Econometrician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4650,
                        'IsSynonym': false,
                        'Sequence': 765,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Economic Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4651,
                        'IsSynonym': false,
                        'Sequence': 766,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Economic Geographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4652,
                        'IsSynonym': false,
                        'Sequence': 767,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Economic Historian'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4653,
                        'IsSynonym': false,
                        'Sequence': 768,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Economist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4654,
                        'IsSynonym': false,
                        'Sequence': 769,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Editor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4655,
                        'IsSynonym': false,
                        'Sequence': 770,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Editorial Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4656,
                        'IsSynonym': false,
                        'Sequence': 771,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Editorial Writer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4657,
                        'IsSynonym': false,
                        'Sequence': 772,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Education Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4658,
                        'IsSynonym': false,
                        'Sequence': 773,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Educational Counsellor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4659,
                        'IsSynonym': false,
                        'Sequence': 774,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Educational Guidance Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4660,
                        'IsSynonym': false,
                        'Sequence': 775,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Educational Psychologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4661,
                        'IsSynonym': false,
                        'Sequence': 776,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Egg Producer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4662,
                        'IsSynonym': false,
                        'Sequence': 777,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrical Contractor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4663,
                        'IsSynonym': false,
                        'Sequence': 778,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrical Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4664,
                        'IsSynonym': false,
                        'Sequence': 779,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrical Engineering Associate'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4665,
                        'IsSynonym': false,
                        'Sequence': 780,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrical Engineering Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4666,
                        'IsSynonym': false,
                        'Sequence': 781,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrical Fitter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4667,
                        'IsSynonym': false,
                        'Sequence': 782,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrician - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4668,
                        'IsSynonym': false,
                        'Sequence': 783,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrician (Heights > 12m or high voltage > 1000V)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4669,
                        'IsSynonym': false,
                        'Sequence': 784,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electrician (Heights up to 12m and voltage up to 1000V)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4670,
                        'IsSynonym': false,
                        'Sequence': 785,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electroencephalographic Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4671,
                        'IsSynonym': false,
                        'Sequence': 786,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electromechanic'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4672,
                        'IsSynonym': false,
                        'Sequence': 787,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electronics Engineering Associate'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4673,
                        'IsSynonym': false,
                        'Sequence': 788,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Electronics Engineering Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4674,
                        'IsSynonym': false,
                        'Sequence': 789,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Elocution Teacher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4675,
                        'IsSynonym': false,
                        'Sequence': 790,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Embroiderer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4676,
                        'IsSynonym': false,
                        'Sequence': 791,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Embryologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4677,
                        'IsSynonym': false,
                        'Sequence': 792,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Employment Office Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4678,
                        'IsSynonym': false,
                        'Sequence': 793,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Employment Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4679,
                        'IsSynonym': false,
                        'Sequence': 794,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Endocrinologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4680,
                        'IsSynonym': false,
                        'Sequence': 795,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Endodontist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4681,
                        'IsSynonym': false,
                        'Sequence': 796,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engine room worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4682,
                        'IsSynonym': false,
                        'Sequence': 797,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineer - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4683,
                        'IsSynonym': false,
                        'Sequence': 798,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineer - Radio Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4684,
                        'IsSynonym': false,
                        'Sequence': 799,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineering Laboratory Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4685,
                        'IsSynonym': false,
                        'Sequence': 800,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineering Laboratory Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4686,
                        'IsSynonym': false,
                        'Sequence': 801,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineering Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4687,
                        'IsSynonym': false,
                        'Sequence': 802,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineering Survey Drafting Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4688,
                        'IsSynonym': false,
                        'Sequence': 803,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineering Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4689,
                        'IsSynonym': false,
                        'Sequence': 804,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Engineering Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4690,
                        'IsSynonym': false,
                        'Sequence': 805,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Enrolled Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4691,
                        'IsSynonym': false,
                        'Sequence': 806,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Entertainment Agent'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4692,
                        'IsSynonym': false,
                        'Sequence': 807,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Entertainment Usher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4693,
                        'IsSynonym': false,
                        'Sequence': 808,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Entomologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4694,
                        'IsSynonym': false,
                        'Sequence': 809,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Environmental Artist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4695,
                        'IsSynonym': false,
                        'Sequence': 810,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Environmental Officer (Non-Mining)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4696,
                        'IsSynonym': false,
                        'Sequence': 811,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Environmental Scientist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4697,
                        'IsSynonym': false,
                        'Sequence': 812,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Enzyme Chemist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4698,
                        'IsSynonym': false,
                        'Sequence': 813,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Epidemiologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4699,
                        'IsSynonym': false,
                        'Sequence': 814,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Equestrian Sports - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4700,
                        'IsSynonym': false,
                        'Sequence': 815,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Equipment Assembly Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4701,
                        'IsSynonym': false,
                        'Sequence': 816,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ergonomist'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4702,
                        'IsSynonym': false,
                        'Sequence': 817,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Escort'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4703,
                        'IsSynonym': false,
                        'Sequence': 818,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Estate Agent'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4704,
                        'IsSynonym': false,
                        'Sequence': 819,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Events Coordinator (< 20% travel and < 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4705,
                        'IsSynonym': false,
                        'Sequence': 820,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Events Coordinator (> 20% travel or > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4706,
                        'IsSynonym': false,
                        'Sequence': 821,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Events Organiser (< 20% travel and < 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4707,
                        'IsSynonym': false,
                        'Sequence': 822,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Events Organiser (> 20% travel or > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4708,
                        'IsSynonym': false,
                        'Sequence': 823,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Examiner of Aircraft'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4709,
                        'IsSynonym': false,
                        'Sequence': 824,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Excavator Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4710,
                        'IsSynonym': false,
                        'Sequence': 825,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4711,
                        'IsSynonym': false,
                        'Sequence': 826,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4712,
                        'IsSynonym': false,
                        'Sequence': 827,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4713,
                        'IsSynonym': false,
                        'Sequence': 828,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4714,
                        'IsSynonym': false,
                        'Sequence': 829,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4715,
                        'IsSynonym': false,
                        'Sequence': 830,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4716,
                        'IsSynonym': false,
                        'Sequence': 831,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4717,
                        'IsSynonym': false,
                        'Sequence': 832,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4718,
                        'IsSynonym': false,
                        'Sequence': 833,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4719,
                        'IsSynonym': false,
                        'Sequence': 834,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4720,
                        'IsSynonym': false,
                        'Sequence': 835,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4721,
                        'IsSynonym': false,
                        'Sequence': 836,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Director - CEO (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4722,
                        'IsSynonym': false,
                        'Sequence': 837,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Producer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4723,
                        'IsSynonym': false,
                        'Sequence': 838,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Executive Search Consultant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4724,
                        'IsSynonym': false,
                        'Sequence': 839,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Exhibition Layout Designer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4725,
                        'IsSynonym': false,
                        'Sequence': 840,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Exploration (Photographic or Survey Work)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4726,
                        'IsSynonym': false,
                        'Sequence': 841,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Explosives Chemist'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4727,
                        'IsSynonym': false,
                        'Sequence': 842,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Explosives Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4728,
                        'IsSynonym': false,
                        'Sequence': 843,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Export Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4729,
                        'IsSynonym': false,
                        'Sequence': 844,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Exterior Cladding Installer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4730,
                        'IsSynonym': false,
                        'Sequence': 845,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Extractive Metallurgist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4731,
                        'IsSynonym': false,
                        'Sequence': 846,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fabric Designer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4732,
                        'IsSynonym': false,
                        'Sequence': 847,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fabric Dyer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4733,
                        'IsSynonym': false,
                        'Sequence': 848,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Faceter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4734,
                        'IsSynonym': false,
                        'Sequence': 849,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Factory Cleaner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4735,
                        'IsSynonym': false,
                        'Sequence': 850,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Factory Supervisor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4736,
                        'IsSynonym': false,
                        'Sequence': 851,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Factory Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4737,
                        'IsSynonym': false,
                        'Sequence': 852,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Faculty Head'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4738,
                        'IsSynonym': false,
                        'Sequence': 853,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fairground attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4739,
                        'IsSynonym': false,
                        'Sequence': 854,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Family Aide'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4740,
                        'IsSynonym': false,
                        'Sequence': 855,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Family Counsellor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4741,
                        'IsSynonym': false,
                        'Sequence': 856,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Family Court Counsellor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4742,
                        'IsSynonym': false,
                        'Sequence': 857,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farm Apprentice'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4743,
                        'IsSynonym': false,
                        'Sequence': 858,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farm Consultant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4744,
                        'IsSynonym': false,
                        'Sequence': 859,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farm Machinery Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4745,
                        'IsSynonym': false,
                        'Sequence': 860,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farm Office Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4746,
                        'IsSynonym': false,
                        'Sequence': 861,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farm Overseer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4747,
                        'IsSynonym': false,
                        'Sequence': 862,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farm Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4748,
                        'IsSynonym': false,
                        'Sequence': 863,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farmer (< 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4749,
                        'IsSynonym': false,
                        'Sequence': 864,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farmer (> 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4750,
                        'IsSynonym': false,
                        'Sequence': 865,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farmer (10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4751,
                        'IsSynonym': false,
                        'Sequence': 866,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farmer (21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4752,
                        'IsSynonym': false,
                        'Sequence': 867,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Farrier'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4753,
                        'IsSynonym': false,
                        'Sequence': 868,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Feltmaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4754,
                        'IsSynonym': false,
                        'Sequence': 869,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fence Erector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4755,
                        'IsSynonym': false,
                        'Sequence': 870,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fence Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4756,
                        'IsSynonym': false,
                        'Sequence': 871,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fibre-Optic Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4757,
                        'IsSynonym': false,
                        'Sequence': 872,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Field Crop Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4758,
                        'IsSynonym': false,
                        'Sequence': 873,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Filing Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4759,
                        'IsSynonym': false,
                        'Sequence': 874,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Film Distributor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4760,
                        'IsSynonym': false,
                        'Sequence': 875,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Film Editor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4761,
                        'IsSynonym': false,
                        'Sequence': 876,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4762,
                        'IsSynonym': false,
                        'Sequence': 877,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4763,
                        'IsSynonym': false,
                        'Sequence': 878,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4764,
                        'IsSynonym': false,
                        'Sequence': 879,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4765,
                        'IsSynonym': false,
                        'Sequence': 880,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4766,
                        'IsSynonym': false,
                        'Sequence': 881,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4767,
                        'IsSynonym': false,
                        'Sequence': 882,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4768,
                        'IsSynonym': false,
                        'Sequence': 883,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4769,
                        'IsSynonym': false,
                        'Sequence': 884,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4770,
                        'IsSynonym': false,
                        'Sequence': 885,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4771,
                        'IsSynonym': false,
                        'Sequence': 886,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Director - FD (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4772,
                        'IsSynonym': false,
                        'Sequence': 887,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Finance Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4773,
                        'IsSynonym': false,
                        'Sequence': 888,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Advisor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4774,
                        'IsSynonym': false,
                        'Sequence': 889,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4775,
                        'IsSynonym': false,
                        'Sequence': 890,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Broker (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4776,
                        'IsSynonym': false,
                        'Sequence': 891,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Broker (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4777,
                        'IsSynonym': false,
                        'Sequence': 892,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4778,
                        'IsSynonym': false,
                        'Sequence': 893,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Institution Branch Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4779,
                        'IsSynonym': false,
                        'Sequence': 894,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4780,
                        'IsSynonym': false,
                        'Sequence': 895,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Planner (< 20% travel and < 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4781,
                        'IsSynonym': false,
                        'Sequence': 896,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Financial Planner (> 20% travel or > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4782,
                        'IsSynonym': false,
                        'Sequence': 897,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fire Chief'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4783,
                        'IsSynonym': false,
                        'Sequence': 898,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fire Commissioner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4784,
                        'IsSynonym': false,
                        'Sequence': 899,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fire fighter (aviation)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4785,
                        'IsSynonym': false,
                        'Sequence': 900,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fire Prevention Officer/Fire safety officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4786,
                        'IsSynonym': false,
                        'Sequence': 901,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Firearms Dealer (Licenced)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4787,
                        'IsSynonym': false,
                        'Sequence': 902,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fireman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4788,
                        'IsSynonym': false,
                        'Sequence': 903,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'First Aid Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4789,
                        'IsSynonym': false,
                        'Sequence': 904,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Exports Inspector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4790,
                        'IsSynonym': false,
                        'Sequence': 905,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Farm Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4791,
                        'IsSynonym': false,
                        'Sequence': 906,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Farmer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4792,
                        'IsSynonym': false,
                        'Sequence': 907,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Filleter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4793,
                        'IsSynonym': false,
                        'Sequence': 908,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Plant and Machinery Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4794,
                        'IsSynonym': false,
                        'Sequence': 909,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Process Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4795,
                        'IsSynonym': false,
                        'Sequence': 910,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fish Smokehouse Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4796,
                        'IsSynonym': false,
                        'Sequence': 911,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fisheries and Wildlife Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4797,
                        'IsSynonym': false,
                        'Sequence': 912,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fisheries Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4798,
                        'IsSynonym': false,
                        'Sequence': 913,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fisherman - Vessels (> 40m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4799,
                        'IsSynonym': false,
                        'Sequence': 914,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fisherman - Vessels (up to 40m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4800,
                        'IsSynonym': false,
                        'Sequence': 915,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fishing Vessel Master'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4801,
                        'IsSynonym': false,
                        'Sequence': 916,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fishmonger'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4802,
                        'IsSynonym': false,
                        'Sequence': 917,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fitness Instructor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4803,
                        'IsSynonym': false,
                        'Sequence': 918,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fitter - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4804,
                        'IsSynonym': false,
                        'Sequence': 919,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fitter and Turner (Non-Mining)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4805,
                        'IsSynonym': false,
                        'Sequence': 920,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Controller'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4806,
                        'IsSynonym': false,
                        'Sequence': 921,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Engineer (IATA Registered Airline)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4807,
                        'IsSynonym': false,
                        'Sequence': 922,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Engineer (International)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4808,
                        'IsSynonym': false,
                        'Sequence': 923,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Engineer (South Africa Only)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4809,
                        'IsSynonym': false,
                        'Sequence': 924,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Engineer Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4810,
                        'IsSynonym': false,
                        'Sequence': 925,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Navigator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4811,
                        'IsSynonym': false,
                        'Sequence': 926,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Flight Service Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4812,
                        'IsSynonym': false,
                        'Sequence': 927,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Floor Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4813,
                        'IsSynonym': false,
                        'Sequence': 928,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Floorman - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4814,
                        'IsSynonym': false,
                        'Sequence': 929,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Florist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4815,
                        'IsSynonym': false,
                        'Sequence': 930,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Florist Apprentice/assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4816,
                        'IsSynonym': false,
                        'Sequence': 931,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food and Drink Chemist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4817,
                        'IsSynonym': false,
                        'Sequence': 932,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Processing Autoclave Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4818,
                        'IsSynonym': false,
                        'Sequence': 933,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Processing Extruder Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4819,
                        'IsSynonym': false,
                        'Sequence': 934,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Processing Machine Operators'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4820,
                        'IsSynonym': false,
                        'Sequence': 935,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Processing Retort Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4821,
                        'IsSynonym': false,
                        'Sequence': 936,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Size-grading Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4822,
                        'IsSynonym': false,
                        'Sequence': 937,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Steward/Stewardess'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4823,
                        'IsSynonym': false,
                        'Sequence': 938,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food Technologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4824,
                        'IsSynonym': false,
                        'Sequence': 939,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Food, Machine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4825,
                        'IsSynonym': false,
                        'Sequence': 940,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Football - Semi Professional or Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4826,
                        'IsSynonym': false,
                        'Sequence': 941,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Foreign Exchange Dealer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4827,
                        'IsSynonym': false,
                        'Sequence': 942,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Foreman (Non-Mining)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4828,
                        'IsSynonym': false,
                        'Sequence': 943,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forensic Auditor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4829,
                        'IsSynonym': false,
                        'Sequence': 944,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forensic Pathologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4830,
                        'IsSynonym': false,
                        'Sequence': 945,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forest Assessor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4831,
                        'IsSynonym': false,
                        'Sequence': 946,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forest Overseer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4832,
                        'IsSynonym': false,
                        'Sequence': 947,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forest Ranger/Warden'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4833,
                        'IsSynonym': false,
                        'Sequence': 948,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forest Scientist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4834,
                        'IsSynonym': false,
                        'Sequence': 949,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forester'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4835,
                        'IsSynonym': false,
                        'Sequence': 950,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forester-qualified supervisor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4836,
                        'IsSynonym': false,
                        'Sequence': 951,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forestry Adviser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4837,
                        'IsSynonym': false,
                        'Sequence': 952,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forestry Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4838,
                        'IsSynonym': false,
                        'Sequence': 953,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forestry Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4839,
                        'IsSynonym': false,
                        'Sequence': 954,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forestry Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4840,
                        'IsSynonym': false,
                        'Sequence': 955,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forms Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4841,
                        'IsSynonym': false,
                        'Sequence': 956,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forms Analyst and Designer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4842,
                        'IsSynonym': false,
                        'Sequence': 957,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Forms Designer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4843,
                        'IsSynonym': false,
                        'Sequence': 958,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fortune Teller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4844,
                        'IsSynonym': false,
                        'Sequence': 959,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Foundryman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4845,
                        'IsSynonym': false,
                        'Sequence': 960,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Freight Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4846,
                        'IsSynonym': false,
                        'Sequence': 961,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Freight Forwarder - Office'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4847,
                        'IsSynonym': false,
                        'Sequence': 962,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Freight Handler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4848,
                        'IsSynonym': false,
                        'Sequence': 963,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Freight Traffic Controller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4849,
                        'IsSynonym': false,
                        'Sequence': 964,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fruit and Vegetable Classer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4850,
                        'IsSynonym': false,
                        'Sequence': 965,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fruit and Vegetable Packer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4851,
                        'IsSynonym': false,
                        'Sequence': 966,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fruit Conserve and Sauce Maker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4852,
                        'IsSynonym': false,
                        'Sequence': 967,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fruit, Vegetable and Nut Farm Hand'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4853,
                        'IsSynonym': false,
                        'Sequence': 968,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fruiterer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4854,
                        'IsSynonym': false,
                        'Sequence': 969,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fuel Technologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4855,
                        'IsSynonym': false,
                        'Sequence': 970,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fumigator, Exterminator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4856,
                        'IsSynonym': false,
                        'Sequence': 971,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Fund Raiser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4857,
                        'IsSynonym': false,
                        'Sequence': 972,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Funeral Director'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4858,
                        'IsSynonym': false,
                        'Sequence': 973,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furnace Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4859,
                        'IsSynonym': false,
                        'Sequence': 974,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Assembler/Finisher/Maker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4860,
                        'IsSynonym': false,
                        'Sequence': 975,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Dealer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4861,
                        'IsSynonym': false,
                        'Sequence': 976,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Designer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4862,
                        'IsSynonym': false,
                        'Sequence': 977,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Manufacturing Labourer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4863,
                        'IsSynonym': false,
                        'Sequence': 978,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Packer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4864,
                        'IsSynonym': false,
                        'Sequence': 979,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Polisher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4865,
                        'IsSynonym': false,
                        'Sequence': 980,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Furniture Upholsterer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4866,
                        'IsSynonym': false,
                        'Sequence': 981,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Futures Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4867,
                        'IsSynonym': false,
                        'Sequence': 982,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Futures Dealer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4868,
                        'IsSynonym': false,
                        'Sequence': 983,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Game Warden/Keeper/Ranger'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4869,
                        'IsSynonym': false,
                        'Sequence': 984,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gamekeeper'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4870,
                        'IsSynonym': false,
                        'Sequence': 985,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gaming Club Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4871,
                        'IsSynonym': false,
                        'Sequence': 986,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Garage Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4872,
                        'IsSynonym': false,
                        'Sequence': 987,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Garage Owner'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4873,
                        'IsSynonym': false,
                        'Sequence': 988,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Garbage Collector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4874,
                        'IsSynonym': false,
                        'Sequence': 989,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Garden Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4875,
                        'IsSynonym': false,
                        'Sequence': 990,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gardener'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4876,
                        'IsSynonym': false,
                        'Sequence': 991,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gardener (Unskilled)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4877,
                        'IsSynonym': false,
                        'Sequence': 992,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Garment Finishing Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4878,
                        'IsSynonym': false,
                        'Sequence': 993,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas Compressor Turbine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4879,
                        'IsSynonym': false,
                        'Sequence': 994,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas Examiner'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4880,
                        'IsSynonym': false,
                        'Sequence': 995,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas Pipelayer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4881,
                        'IsSynonym': false,
                        'Sequence': 996,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas Plant Labourer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4882,
                        'IsSynonym': false,
                        'Sequence': 997,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas Welder-Cutter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4883,
                        'IsSynonym': false,
                        'Sequence': 998,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gasfitter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4884,
                        'IsSynonym': false,
                        'Sequence': 999,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas-main and Line Fitter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4885,
                        'IsSynonym': false,
                        'Sequence': 1000,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gas-shielded Arc Welding Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4886,
                        'IsSynonym': false,
                        'Sequence': 1001,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gastroenterologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4887,
                        'IsSynonym': false,
                        'Sequence': 1002,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gelato/Ice cream maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4888,
                        'IsSynonym': false,
                        'Sequence': 1003,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gem Cutter/Setter and Polisher'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4889,
                        'IsSynonym': false,
                        'Sequence': 1004,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'General Dealer (Registered)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4890,
                        'IsSynonym': false,
                        'Sequence': 1005,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'General Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4891,
                        'IsSynonym': false,
                        'Sequence': 1006,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'General Practitioner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4892,
                        'IsSynonym': false,
                        'Sequence': 1007,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geneticist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4893,
                        'IsSynonym': false,
                        'Sequence': 1008,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geochemical Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4894,
                        'IsSynonym': false,
                        'Sequence': 1009,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geochemist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4895,
                        'IsSynonym': false,
                        'Sequence': 1010,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geodetic Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4896,
                        'IsSynonym': false,
                        'Sequence': 1011,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4897,
                        'IsSynonym': false,
                        'Sequence': 1012,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geological Engineer (Non-Mining)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4898,
                        'IsSynonym': false,
                        'Sequence': 1013,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geological Survey Field Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4899,
                        'IsSynonym': false,
                        'Sequence': 1014,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geologist (Non-Mining)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4900,
                        'IsSynonym': false,
                        'Sequence': 1015,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geology Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4901,
                        'IsSynonym': false,
                        'Sequence': 1016,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geology Technician (Non-Mining)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4902,
                        'IsSynonym': false,
                        'Sequence': 1017,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geophysical Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4903,
                        'IsSynonym': false,
                        'Sequence': 1018,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geophysicist (Non-Mining)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4904,
                        'IsSynonym': false,
                        'Sequence': 1019,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geophysics Technician (Non-Mining)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4905,
                        'IsSynonym': false,
                        'Sequence': 1020,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geostatistician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4906,
                        'IsSynonym': false,
                        'Sequence': 1021,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geriatric Care Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4907,
                        'IsSynonym': false,
                        'Sequence': 1022,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Geriatrician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4908,
                        'IsSynonym': false,
                        'Sequence': 1023,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Glass Blower'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4909,
                        'IsSynonym': false,
                        'Sequence': 1024,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Glass Cutter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4910,
                        'IsSynonym': false,
                        'Sequence': 1025,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Glassware Verifier'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4911,
                        'IsSynonym': false,
                        'Sequence': 1026,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Glazier (Heights > 10m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4912,
                        'IsSynonym': false,
                        'Sequence': 1027,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Glazier (Heights up to 10m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4913,
                        'IsSynonym': false,
                        'Sequence': 1028,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gliding Instructor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4914,
                        'IsSynonym': false,
                        'Sequence': 1029,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Golf - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4915,
                        'IsSynonym': false,
                        'Sequence': 1030,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Administrative'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4916,
                        'IsSynonym': false,
                        'Sequence': 1031,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Civil Servant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4917,
                        'IsSynonym': false,
                        'Sequence': 1032,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Clerical'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4918,
                        'IsSynonym': false,
                        'Sequence': 1033,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Head of Department'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4919,
                        'IsSynonym': false,
                        'Sequence': 1034,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Local Government'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4920,
                        'IsSynonym': false,
                        'Sequence': 1035,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Minister'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4921,
                        'IsSynonym': false,
                        'Sequence': 1036,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Government Official - Other'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4922,
                        'IsSynonym': false,
                        'Sequence': 1037,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Governor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4923,
                        'IsSynonym': false,
                        'Sequence': 1038,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grain Binsman/woman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4924,
                        'IsSynonym': false,
                        'Sequence': 1039,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grain Grader'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4925,
                        'IsSynonym': false,
                        'Sequence': 1040,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grain Handler'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4926,
                        'IsSynonym': false,
                        'Sequence': 1041,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grant Advisor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4927,
                        'IsSynonym': false,
                        'Sequence': 1042,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Graphic designer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4928,
                        'IsSynonym': false,
                        'Sequence': 1043,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grave Digger'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4929,
                        'IsSynonym': false,
                        'Sequence': 1044,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grazier'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4930,
                        'IsSynonym': false,
                        'Sequence': 1045,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Greengrocer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4931,
                        'IsSynonym': false,
                        'Sequence': 1046,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Greengrocery Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4932,
                        'IsSynonym': false,
                        'Sequence': 1047,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Greyhound Trainer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4933,
                        'IsSynonym': false,
                        'Sequence': 1048,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grinder (stone)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4934,
                        'IsSynonym': false,
                        'Sequence': 1049,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grip, carpenter, set builder'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4935,
                        'IsSynonym': false,
                        'Sequence': 1050,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grocer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4936,
                        'IsSynonym': false,
                        'Sequence': 1051,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grocery Packer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4937,
                        'IsSynonym': false,
                        'Sequence': 1052,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Groom'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4938,
                        'IsSynonym': false,
                        'Sequence': 1053,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Grouter - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4939,
                        'IsSynonym': false,
                        'Sequence': 1054,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Guest House Owner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4940,
                        'IsSynonym': false,
                        'Sequence': 1055,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Guest House Proprietor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4941,
                        'IsSynonym': false,
                        'Sequence': 1056,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gymnast - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4942,
                        'IsSynonym': false,
                        'Sequence': 1057,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gynaecologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4943,
                        'IsSynonym': false,
                        'Sequence': 1058,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Gynaecologist (surgeon)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4944,
                        'IsSynonym': false,
                        'Sequence': 1059,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Haberdasher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4945,
                        'IsSynonym': false,
                        'Sequence': 1060,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Haematologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4946,
                        'IsSynonym': false,
                        'Sequence': 1061,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hairdresser - Apprentice'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4947,
                        'IsSynonym': false,
                        'Sequence': 1062,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hairdresser/Barber (< 25% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4948,
                        'IsSynonym': false,
                        'Sequence': 1063,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hairdresser/Barber (> 25% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4949,
                        'IsSynonym': false,
                        'Sequence': 1064,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hairdressers - Supervisor (< 25% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4950,
                        'IsSynonym': false,
                        'Sequence': 1065,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Handicrafts Teacher (Private)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4951,
                        'IsSynonym': false,
                        'Sequence': 1066,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Handyman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4952,
                        'IsSynonym': false,
                        'Sequence': 1067,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Harbour master'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4953,
                        'IsSynonym': false,
                        'Sequence': 1068,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Harbour/Maritime Pilot'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4954,
                        'IsSynonym': false,
                        'Sequence': 1069,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hardware Dealer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4955,
                        'IsSynonym': false,
                        'Sequence': 1070,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hardware Store Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4956,
                        'IsSynonym': false,
                        'Sequence': 1071,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Harvester, Agricultural Contractor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4957,
                        'IsSynonym': false,
                        'Sequence': 1072,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hat Maker/milliner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4958,
                        'IsSynonym': false,
                        'Sequence': 1073,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hatchery Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4959,
                        'IsSynonym': false,
                        'Sequence': 1074,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hatchery Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4960,
                        'IsSynonym': false,
                        'Sequence': 1075,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Haulier, Administrative Only'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4961,
                        'IsSynonym': false,
                        'Sequence': 1076,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Haulier, Others'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 4962,
                        'IsSynonym': false,
                        'Sequence': 1077,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hawker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4963,
                        'IsSynonym': false,
                        'Sequence': 1078,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head Greenkeeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4964,
                        'IsSynonym': false,
                        'Sequence': 1079,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head Groundsman/woman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4965,
                        'IsSynonym': false,
                        'Sequence': 1080,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head of Department (TAFE)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4966,
                        'IsSynonym': false,
                        'Sequence': 1081,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head of Diplomatic Mission'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4967,
                        'IsSynonym': false,
                        'Sequence': 1082,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head of School (TAFE)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4968,
                        'IsSynonym': false,
                        'Sequence': 1083,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head Porter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4969,
                        'IsSynonym': false,
                        'Sequence': 1084,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Head Sawyer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4970,
                        'IsSynonym': false,
                        'Sequence': 1085,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Headmaster/mistress'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4971,
                        'IsSynonym': false,
                        'Sequence': 1086,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Health Advisor/Consultant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4972,
                        'IsSynonym': false,
                        'Sequence': 1087,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Health and Safety Inspector (Non-mining)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4973,
                        'IsSynonym': false,
                        'Sequence': 1088,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Health Data Administrator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4974,
                        'IsSynonym': false,
                        'Sequence': 1089,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Health Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4975,
                        'IsSynonym': false,
                        'Sequence': 1090,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Health Insurance Assessor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4976,
                        'IsSynonym': false,
                        'Sequence': 1091,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hearing Aid Manufacturer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4977,
                        'IsSynonym': false,
                        'Sequence': 1092,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Heating and Ventilating Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4978,
                        'IsSynonym': false,
                        'Sequence': 1093,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Heating engineer, gas fitter'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 4979,
                        'IsSynonym': false,
                        'Sequence': 1094,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Heavy Duty Mechanic'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4980,
                        'IsSynonym': false,
                        'Sequence': 1095,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Helicopter Observer (Rescue)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4981,
                        'IsSynonym': false,
                        'Sequence': 1096,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hemstitching Machine Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4982,
                        'IsSynonym': false,
                        'Sequence': 1097,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Herbalist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4983,
                        'IsSynonym': false,
                        'Sequence': 1098,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Herdsman, Agricultural Contractor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 4984,
                        'IsSynonym': false,
                        'Sequence': 1099,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hide and Skin Classer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4985,
                        'IsSynonym': false,
                        'Sequence': 1100,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'High Commissioner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4986,
                        'IsSynonym': false,
                        'Sequence': 1101,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'High wire act'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4987,
                        'IsSynonym': false,
                        'Sequence': 1102,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Histologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4988,
                        'IsSynonym': false,
                        'Sequence': 1103,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Histopathologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4989,
                        'IsSynonym': false,
                        'Sequence': 1104,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Histopathology Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4990,
                        'IsSynonym': false,
                        'Sequence': 1105,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Historian'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4991,
                        'IsSynonym': false,
                        'Sequence': 1106,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hockey - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4992,
                        'IsSynonym': false,
                        'Sequence': 1107,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hoist Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4993,
                        'IsSynonym': false,
                        'Sequence': 1108,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hoist, Winch and Lift Operator (Non-Mining)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4994,
                        'IsSynonym': false,
                        'Sequence': 1109,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Homeopath'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 4995,
                        'IsSynonym': false,
                        'Sequence': 1110,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Home-School Liaison Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4996,
                        'IsSynonym': false,
                        'Sequence': 1111,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Breaker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4997,
                        'IsSynonym': false,
                        'Sequence': 1112,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Dealer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 4998,
                        'IsSynonym': false,
                        'Sequence': 1113,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Groom'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 4999,
                        'IsSynonym': false,
                        'Sequence': 1114,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Racing Judge'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5000,
                        'IsSynonym': false,
                        'Sequence': 1115,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Stud Farmer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5001,
                        'IsSynonym': false,
                        'Sequence': 1116,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Stud Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5002,
                        'IsSynonym': false,
                        'Sequence': 1117,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Trainer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5003,
                        'IsSynonym': false,
                        'Sequence': 1118,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horse Trainer, Horse Racing Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5004,
                        'IsSynonym': false,
                        'Sequence': 1119,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horticultural Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5005,
                        'IsSynonym': false,
                        'Sequence': 1120,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horticultural Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5006,
                        'IsSynonym': false,
                        'Sequence': 1121,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horticultural Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5007,
                        'IsSynonym': false,
                        'Sequence': 1122,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Horticulturalist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5008,
                        'IsSynonym': false,
                        'Sequence': 1123,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hospital Administrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5009,
                        'IsSynonym': false,
                        'Sequence': 1124,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hospital Admitting Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5010,
                        'IsSynonym': false,
                        'Sequence': 1125,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hospital Cleaner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5011,
                        'IsSynonym': false,
                        'Sequence': 1126,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hospital Orderly'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5012,
                        'IsSynonym': false,
                        'Sequence': 1127,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hospital Porter'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5013,
                        'IsSynonym': false,
                        'Sequence': 1128,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hospital Ward Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5014,
                        'IsSynonym': false,
                        'Sequence': 1129,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Host, hostess'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5015,
                        'IsSynonym': false,
                        'Sequence': 1130,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hostel Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5016,
                        'IsSynonym': false,
                        'Sequence': 1131,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hotel Concierge'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5017,
                        'IsSynonym': false,
                        'Sequence': 1132,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hotel Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5018,
                        'IsSynonym': false,
                        'Sequence': 1133,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hotel Steward'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5019,
                        'IsSynonym': false,
                        'Sequence': 1134,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hotel Yardman/woman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5020,
                        'IsSynonym': false,
                        'Sequence': 1135,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hotelier'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5021,
                        'IsSynonym': false,
                        'Sequence': 1136,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Housewife or House Husband'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5022,
                        'IsSynonym': false,
                        'Sequence': 1137,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Housing Counsellor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5023,
                        'IsSynonym': false,
                        'Sequence': 1138,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Housing Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5024,
                        'IsSynonym': false,
                        'Sequence': 1139,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Human Resource Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5025,
                        'IsSynonym': false,
                        'Sequence': 1140,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hunting - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5026,
                        'IsSynonym': false,
                        'Sequence': 1141,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydraulic Engineer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5027,
                        'IsSynonym': false,
                        'Sequence': 1142,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydraulic Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5028,
                        'IsSynonym': false,
                        'Sequence': 1143,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydrographer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5029,
                        'IsSynonym': false,
                        'Sequence': 1144,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydrographic Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5030,
                        'IsSynonym': false,
                        'Sequence': 1145,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydrologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5031,
                        'IsSynonym': false,
                        'Sequence': 1146,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydrometallurgical Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5032,
                        'IsSynonym': false,
                        'Sequence': 1147,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hydroponics Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5033,
                        'IsSynonym': false,
                        'Sequence': 1148,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Hypnotist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5034,
                        'IsSynonym': false,
                        'Sequence': 1149,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ice Cream Retailer - Van'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5035,
                        'IsSynonym': false,
                        'Sequence': 1150,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Illustrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5036,
                        'IsSynonym': false,
                        'Sequence': 1151,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Immigration Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5037,
                        'IsSynonym': false,
                        'Sequence': 1152,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Immunologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5038,
                        'IsSynonym': false,
                        'Sequence': 1153,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Impersonator, performance art'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5039,
                        'IsSynonym': false,
                        'Sequence': 1154,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Importer-Exporter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5040,
                        'IsSynonym': false,
                        'Sequence': 1155,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Import-Export Business Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5041,
                        'IsSynonym': false,
                        'Sequence': 1156,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Import-Export Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5042,
                        'IsSynonym': false,
                        'Sequence': 1157,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Chemist'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5043,
                        'IsSynonym': false,
                        'Sequence': 1158,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Cleaner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5044,
                        'IsSynonym': false,
                        'Sequence': 1159,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Designer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5045,
                        'IsSynonym': false,
                        'Sequence': 1160,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5046,
                        'IsSynonym': false,
                        'Sequence': 1161,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Metallurgist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5047,
                        'IsSynonym': false,
                        'Sequence': 1162,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5048,
                        'IsSynonym': false,
                        'Sequence': 1163,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Pharmacist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5049,
                        'IsSynonym': false,
                        'Sequence': 1164,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Psychologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5050,
                        'IsSynonym': false,
                        'Sequence': 1165,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Registrar'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5051,
                        'IsSynonym': false,
                        'Sequence': 1166,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Relations Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5052,
                        'IsSynonym': false,
                        'Sequence': 1167,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Industrial Tribunal Member'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5053,
                        'IsSynonym': false,
                        'Sequence': 1168,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Infant Welfare Nurse'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5054,
                        'IsSynonym': false,
                        'Sequence': 1169,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Infectious Diseases Specialist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5055,
                        'IsSynonym': false,
                        'Sequence': 1170,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Information Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5056,
                        'IsSynonym': false,
                        'Sequence': 1171,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Information Desk Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5057,
                        'IsSynonym': false,
                        'Sequence': 1172,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Information Officer (Public Relations)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5058,
                        'IsSynonym': false,
                        'Sequence': 1173,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Information Systems Developer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5059,
                        'IsSynonym': false,
                        'Sequence': 1174,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Information Systems Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5060,
                        'IsSynonym': false,
                        'Sequence': 1175,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Inquiry Agent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5061,
                        'IsSynonym': false,
                        'Sequence': 1176,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Inquiry Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5062,
                        'IsSynonym': false,
                        'Sequence': 1177,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Inquiry Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5063,
                        'IsSynonym': false,
                        'Sequence': 1178,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insect Pathologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5064,
                        'IsSynonym': false,
                        'Sequence': 1179,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Inspector - Not police'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5065,
                        'IsSynonym': false,
                        'Sequence': 1180,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Instrument Maker/Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5066,
                        'IsSynonym': false,
                        'Sequence': 1181,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Instrument Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5067,
                        'IsSynonym': false,
                        'Sequence': 1182,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Instrumental Musician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5068,
                        'IsSynonym': false,
                        'Sequence': 1183,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Agent (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5069,
                        'IsSynonym': false,
                        'Sequence': 1184,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Agent (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5070,
                        'IsSynonym': false,
                        'Sequence': 1185,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Broker (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5071,
                        'IsSynonym': false,
                        'Sequence': 1186,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Broker (> 10% Travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5072,
                        'IsSynonym': false,
                        'Sequence': 1187,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5073,
                        'IsSynonym': false,
                        'Sequence': 1188,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Consultant (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5074,
                        'IsSynonym': false,
                        'Sequence': 1189,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Consultant (> 10% Travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5075,
                        'IsSynonym': false,
                        'Sequence': 1190,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Official'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5076,
                        'IsSynonym': false,
                        'Sequence': 1191,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Insurance Underwriter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5077,
                        'IsSynonym': false,
                        'Sequence': 1192,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Intelligence Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5078,
                        'IsSynonym': false,
                        'Sequence': 1193,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Intensive Care Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5079,
                        'IsSynonym': false,
                        'Sequence': 1194,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Interior Decorator/Designer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5080,
                        'IsSynonym': false,
                        'Sequence': 1195,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'International Trade Economist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5081,
                        'IsSynonym': false,
                        'Sequence': 1196,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Interpreter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5082,
                        'IsSynonym': false,
                        'Sequence': 1197,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Inventory Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5083,
                        'IsSynonym': false,
                        'Sequence': 1198,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Investment Accounting Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5084,
                        'IsSynonym': false,
                        'Sequence': 1199,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Investment Adviser'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5085,
                        'IsSynonym': false,
                        'Sequence': 1200,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Investment Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5086,
                        'IsSynonym': false,
                        'Sequence': 1201,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Investment Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5087,
                        'IsSynonym': false,
                        'Sequence': 1202,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Investment Dealer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5088,
                        'IsSynonym': false,
                        'Sequence': 1203,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Invoice Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5089,
                        'IsSynonym': false,
                        'Sequence': 1204,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Iridologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5090,
                        'IsSynonym': false,
                        'Sequence': 1205,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ironmonger'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5091,
                        'IsSynonym': false,
                        'Sequence': 1206,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Irrigation Supervisor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5092,
                        'IsSynonym': false,
                        'Sequence': 1207,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Irrigation Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5093,
                        'IsSynonym': false,
                        'Sequence': 1208,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'IT Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5094,
                        'IsSynonym': false,
                        'Sequence': 1209,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'IT Consultant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5095,
                        'IsSynonym': false,
                        'Sequence': 1210,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Janitor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5096,
                        'IsSynonym': false,
                        'Sequence': 1211,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jeweller'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5097,
                        'IsSynonym': false,
                        'Sequence': 1212,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jeweller - retail/wholesale'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5098,
                        'IsSynonym': false,
                        'Sequence': 1213,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jewellery Assembler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5099,
                        'IsSynonym': false,
                        'Sequence': 1214,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jewellery Benchworker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5100,
                        'IsSynonym': false,
                        'Sequence': 1215,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jewellery Engraver'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5101,
                        'IsSynonym': false,
                        'Sequence': 1216,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jockey'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5102,
                        'IsSynonym': false,
                        'Sequence': 1217,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Joiner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5103,
                        'IsSynonym': false,
                        'Sequence': 1218,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Jounalist or Reporter/News paper, Radio and television'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5104,
                        'IsSynonym': false,
                        'Sequence': 1219,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Journalist (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5105,
                        'IsSynonym': false,
                        'Sequence': 1220,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Journalist (> 10% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5106,
                        'IsSynonym': false,
                        'Sequence': 1221,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Journalist, Freelance'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5107,
                        'IsSynonym': false,
                        'Sequence': 1222,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Judge'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5108,
                        'IsSynonym': false,
                        'Sequence': 1223,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Judge\'s Associate'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5109,
                        'IsSynonym': false,
                        'Sequence': 1224,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Judo - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5110,
                        'IsSynonym': false,
                        'Sequence': 1225,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Judo/Karate Instructor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5111,
                        'IsSynonym': false,
                        'Sequence': 1226,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Juggler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5112,
                        'IsSynonym': false,
                        'Sequence': 1227,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Junior Primary School Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5113,
                        'IsSynonym': false,
                        'Sequence': 1228,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Justice of the High Court'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5114,
                        'IsSynonym': false,
                        'Sequence': 1229,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Karate - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5115,
                        'IsSynonym': false,
                        'Sequence': 1230,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Kayaking - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5116,
                        'IsSynonym': false,
                        'Sequence': 1231,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Kennel Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5117,
                        'IsSynonym': false,
                        'Sequence': 1232,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Kennelman/maid'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5118,
                        'IsSynonym': false,
                        'Sequence': 1233,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Kiln Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5119,
                        'IsSynonym': false,
                        'Sequence': 1234,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Kitchen Fitter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5120,
                        'IsSynonym': false,
                        'Sequence': 1235,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Kitchen Staff'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5121,
                        'IsSynonym': false,
                        'Sequence': 1236,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laboratory Chemist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5122,
                        'IsSynonym': false,
                        'Sequence': 1237,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laboratory Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5123,
                        'IsSynonym': false,
                        'Sequence': 1238,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Labour Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5124,
                        'IsSynonym': false,
                        'Sequence': 1239,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Labour Economist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5125,
                        'IsSynonym': false,
                        'Sequence': 1240,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Labour Inspector'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5126,
                        'IsSynonym': false,
                        'Sequence': 1241,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Labourer - general'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5127,
                        'IsSynonym': false,
                        'Sequence': 1242,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Land Agent'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5128,
                        'IsSynonym': false,
                        'Sequence': 1243,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Land Degradation Analyst'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5129,
                        'IsSynonym': false,
                        'Sequence': 1244,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Land Inspector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5130,
                        'IsSynonym': false,
                        'Sequence': 1245,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Land Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5131,
                        'IsSynonym': false,
                        'Sequence': 1246,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Landlord'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5132,
                        'IsSynonym': false,
                        'Sequence': 1247,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Landscape Gardener'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5133,
                        'IsSynonym': false,
                        'Sequence': 1248,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Landscape Horticulturist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5134,
                        'IsSynonym': false,
                        'Sequence': 1249,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lapidary Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5135,
                        'IsSynonym': false,
                        'Sequence': 1250,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lapidary/Jewellery Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5136,
                        'IsSynonym': false,
                        'Sequence': 1251,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laser Artist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5137,
                        'IsSynonym': false,
                        'Sequence': 1252,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laser Engraver'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5138,
                        'IsSynonym': false,
                        'Sequence': 1253,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laundrette Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5139,
                        'IsSynonym': false,
                        'Sequence': 1254,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laundromat Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5140,
                        'IsSynonym': false,
                        'Sequence': 1255,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Laundromat Owner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5141,
                        'IsSynonym': false,
                        'Sequence': 1256,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Law Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5142,
                        'IsSynonym': false,
                        'Sequence': 1257,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lawyer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5143,
                        'IsSynonym': false,
                        'Sequence': 1258,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lead Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5144,
                        'IsSynonym': false,
                        'Sequence': 1259,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Leasing Agent'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5145,
                        'IsSynonym': false,
                        'Sequence': 1260,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Leather Finishing Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5146,
                        'IsSynonym': false,
                        'Sequence': 1261,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Leather Goods Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5147,
                        'IsSynonym': false,
                        'Sequence': 1262,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Leather Pattern Maker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5148,
                        'IsSynonym': false,
                        'Sequence': 1263,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lecturer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5149,
                        'IsSynonym': false,
                        'Sequence': 1264,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Legal Advisor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5150,
                        'IsSynonym': false,
                        'Sequence': 1265,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Legal Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5151,
                        'IsSynonym': false,
                        'Sequence': 1266,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Legal Secretary'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5152,
                        'IsSynonym': false,
                        'Sequence': 1267,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lensgrinder/polisher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5153,
                        'IsSynonym': false,
                        'Sequence': 1268,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Letterpress Plate Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5154,
                        'IsSynonym': false,
                        'Sequence': 1269,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Letterpress Printing Machinist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5155,
                        'IsSynonym': false,
                        'Sequence': 1270,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Liaison Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5156,
                        'IsSynonym': false,
                        'Sequence': 1271,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Librarian'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5157,
                        'IsSynonym': false,
                        'Sequence': 1272,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Librettist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5158,
                        'IsSynonym': false,
                        'Sequence': 1273,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'License Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5159,
                        'IsSynonym': false,
                        'Sequence': 1274,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Life Science Technician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5160,
                        'IsSynonym': false,
                        'Sequence': 1275,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lifeboat man (part time or full time)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5161,
                        'IsSynonym': false,
                        'Sequence': 1276,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lifeguard/Lifesaver'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5162,
                        'IsSynonym': false,
                        'Sequence': 1277,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lift Inspector'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5163,
                        'IsSynonym': false,
                        'Sequence': 1278,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lift mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5164,
                        'IsSynonym': false,
                        'Sequence': 1279,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Light Coil Winder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5165,
                        'IsSynonym': false,
                        'Sequence': 1280,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Light Manual Labour (Mechanic)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5166,
                        'IsSynonym': false,
                        'Sequence': 1281,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lighthousekeeper'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5167,
                        'IsSynonym': false,
                        'Sequence': 1282,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lighting Design Drafting Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5168,
                        'IsSynonym': false,
                        'Sequence': 1283,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lighting Supervisor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5169,
                        'IsSynonym': false,
                        'Sequence': 1284,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lighting Technician'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5170,
                        'IsSynonym': false,
                        'Sequence': 1285,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lineman - Electricity/Power cables (Heights > 12m or high voltage > 1000V)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5171,
                        'IsSynonym': false,
                        'Sequence': 1286,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lineman - Electricity/Power cables (Heights up to 12m and voltage up to 1000V)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5172,
                        'IsSynonym': false,
                        'Sequence': 1287,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Linesman/woman (Sport)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5173,
                        'IsSynonym': false,
                        'Sequence': 1288,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Linotype Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5174,
                        'IsSynonym': false,
                        'Sequence': 1289,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Linotype, Printer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5175,
                        'IsSynonym': false,
                        'Sequence': 1290,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Liquid Petroleum Gasfitter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5176,
                        'IsSynonym': false,
                        'Sequence': 1291,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Liquidator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5177,
                        'IsSynonym': false,
                        'Sequence': 1292,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Liquor Store Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5178,
                        'IsSynonym': false,
                        'Sequence': 1293,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Lithographer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5179,
                        'IsSynonym': false,
                        'Sequence': 1294,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Livestock and Field Crop Farm Hand'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5180,
                        'IsSynonym': false,
                        'Sequence': 1295,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Livestock Buyer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5181,
                        'IsSynonym': false,
                        'Sequence': 1296,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Livestock Inspector'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5182,
                        'IsSynonym': false,
                        'Sequence': 1297,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Loader Operator (Earthmoving)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5183,
                        'IsSynonym': false,
                        'Sequence': 1298,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Loans Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5184,
                        'IsSynonym': false,
                        'Sequence': 1299,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Loans Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5185,
                        'IsSynonym': false,
                        'Sequence': 1300,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Log hauler'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5186,
                        'IsSynonym': false,
                        'Sequence': 1301,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Logging Plant Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5187,
                        'IsSynonym': false,
                        'Sequence': 1302,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Logging Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5188,
                        'IsSynonym': false,
                        'Sequence': 1303,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Logistics Controller'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5189,
                        'IsSynonym': false,
                        'Sequence': 1304,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Loss Adjuster'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5190,
                        'IsSynonym': false,
                        'Sequence': 1305,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Luggage Porter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5191,
                        'IsSynonym': false,
                        'Sequence': 1306,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Machine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5192,
                        'IsSynonym': false,
                        'Sequence': 1307,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Machinist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5193,
                        'IsSynonym': false,
                        'Sequence': 1308,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Macro-economist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5194,
                        'IsSynonym': false,
                        'Sequence': 1309,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Magician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5195,
                        'IsSynonym': false,
                        'Sequence': 1310,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Magistrate'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5196,
                        'IsSynonym': false,
                        'Sequence': 1311,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Magistrates Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5197,
                        'IsSynonym': false,
                        'Sequence': 1312,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mail Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5198,
                        'IsSynonym': false,
                        'Sequence': 1313,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mail Order Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5199,
                        'IsSynonym': false,
                        'Sequence': 1314,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maintenance Mechanic, Printer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5200,
                        'IsSynonym': false,
                        'Sequence': 1315,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maintenance Technician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5201,
                        'IsSynonym': false,
                        'Sequence': 1316,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maintenance Worker, Gas Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5202,
                        'IsSynonym': false,
                        'Sequence': 1317,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maintenance, Airport Staff'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5203,
                        'IsSynonym': false,
                        'Sequence': 1318,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maitre d\'Hotel'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5204,
                        'IsSynonym': false,
                        'Sequence': 1319,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Make-Up Artist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5205,
                        'IsSynonym': false,
                        'Sequence': 1320,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Management Consultant (< 20% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5206,
                        'IsSynonym': false,
                        'Sequence': 1321,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Management Consultant (> 20% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5207,
                        'IsSynonym': false,
                        'Sequence': 1322,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5208,
                        'IsSynonym': false,
                        'Sequence': 1323,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5209,
                        'IsSynonym': false,
                        'Sequence': 1324,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5210,
                        'IsSynonym': false,
                        'Sequence': 1325,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5211,
                        'IsSynonym': false,
                        'Sequence': 1326,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5212,
                        'IsSynonym': false,
                        'Sequence': 1327,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5213,
                        'IsSynonym': false,
                        'Sequence': 1328,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5214,
                        'IsSynonym': false,
                        'Sequence': 1329,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5215,
                        'IsSynonym': false,
                        'Sequence': 1330,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5216,
                        'IsSynonym': false,
                        'Sequence': 1331,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5217,
                        'IsSynonym': false,
                        'Sequence': 1332,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5218,
                        'IsSynonym': false,
                        'Sequence': 1333,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manager (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5219,
                        'IsSynonym': false,
                        'Sequence': 1334,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5220,
                        'IsSynonym': false,
                        'Sequence': 1335,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (< 10% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5221,
                        'IsSynonym': false,
                        'Sequence': 1336,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (< 10% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5222,
                        'IsSynonym': false,
                        'Sequence': 1337,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (< 10% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5223,
                        'IsSynonym': false,
                        'Sequence': 1338,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (> 20% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5224,
                        'IsSynonym': false,
                        'Sequence': 1339,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (> 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5225,
                        'IsSynonym': false,
                        'Sequence': 1340,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (> 20% travel and 10% - 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5226,
                        'IsSynonym': false,
                        'Sequence': 1341,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (> 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5227,
                        'IsSynonym': false,
                        'Sequence': 1342,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (10% - 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5228,
                        'IsSynonym': false,
                        'Sequence': 1343,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (10% - 20% travel and > 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5229,
                        'IsSynonym': false,
                        'Sequence': 1344,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Director - MD (10% - 20% travel and 21% - 50% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5230,
                        'IsSynonym': false,
                        'Sequence': 1345,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Managing Editor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5231,
                        'IsSynonym': false,
                        'Sequence': 1346,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Manpower Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5232,
                        'IsSynonym': false,
                        'Sequence': 1347,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Biologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5233,
                        'IsSynonym': false,
                        'Sequence': 1348,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Engine and Boiler Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5234,
                        'IsSynonym': false,
                        'Sequence': 1349,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5235,
                        'IsSynonym': false,
                        'Sequence': 1350,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Engineer Superintendent'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5236,
                        'IsSynonym': false,
                        'Sequence': 1351,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Engineer Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5237,
                        'IsSynonym': false,
                        'Sequence': 1352,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Geologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5238,
                        'IsSynonym': false,
                        'Sequence': 1353,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Researcher'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5239,
                        'IsSynonym': false,
                        'Sequence': 1354,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marine Surveyor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5240,
                        'IsSynonym': false,
                        'Sequence': 1355,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Analyst'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5241,
                        'IsSynonym': false,
                        'Sequence': 1356,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Gardener'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5242,
                        'IsSynonym': false,
                        'Sequence': 1357,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Research Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5243,
                        'IsSynonym': false,
                        'Sequence': 1358,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Research Interviewer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5244,
                        'IsSynonym': false,
                        'Sequence': 1359,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Research Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5245,
                        'IsSynonym': false,
                        'Sequence': 1360,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Researcher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5246,
                        'IsSynonym': false,
                        'Sequence': 1361,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Market Trader'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5247,
                        'IsSynonym': false,
                        'Sequence': 1362,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marketing Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5248,
                        'IsSynonym': false,
                        'Sequence': 1363,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marketing Manager (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5249,
                        'IsSynonym': false,
                        'Sequence': 1364,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marketing Manager (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5250,
                        'IsSynonym': false,
                        'Sequence': 1365,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marketing Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5251,
                        'IsSynonym': false,
                        'Sequence': 1366,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Marriage Counsellor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5252,
                        'IsSynonym': false,
                        'Sequence': 1367,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Martial Arts - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5253,
                        'IsSynonym': false,
                        'Sequence': 1368,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mason (brick/stone)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5254,
                        'IsSynonym': false,
                        'Sequence': 1369,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Master Builder'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5255,
                        'IsSynonym': false,
                        'Sequence': 1370,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Master Fisherman/woman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5256,
                        'IsSynonym': false,
                        'Sequence': 1371,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Master of the Court'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5257,
                        'IsSynonym': false,
                        'Sequence': 1372,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Materials Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5258,
                        'IsSynonym': false,
                        'Sequence': 1373,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Materials Scientist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5259,
                        'IsSynonym': false,
                        'Sequence': 1374,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maternal and Child Health Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5260,
                        'IsSynonym': false,
                        'Sequence': 1375,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mathematician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5261,
                        'IsSynonym': false,
                        'Sequence': 1376,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Matron'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5262,
                        'IsSynonym': false,
                        'Sequence': 1377,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Maxillo Facial Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5263,
                        'IsSynonym': false,
                        'Sequence': 1378,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mayor/Mayoress'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5264,
                        'IsSynonym': false,
                        'Sequence': 1379,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat and Fish Processing Machine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5265,
                        'IsSynonym': false,
                        'Sequence': 1380,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat By-Products Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5266,
                        'IsSynonym': false,
                        'Sequence': 1381,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat Grader'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5267,
                        'IsSynonym': false,
                        'Sequence': 1382,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat Inspector'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5268,
                        'IsSynonym': false,
                        'Sequence': 1383,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat Mincer Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5269,
                        'IsSynonym': false,
                        'Sequence': 1384,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat Packer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5270,
                        'IsSynonym': false,
                        'Sequence': 1385,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat Process Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5271,
                        'IsSynonym': false,
                        'Sequence': 1386,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meat Smokehouse Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5272,
                        'IsSynonym': false,
                        'Sequence': 1387,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meatcutter/Trimmer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5273,
                        'IsSynonym': false,
                        'Sequence': 1388,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meatworks Labourer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5274,
                        'IsSynonym': false,
                        'Sequence': 1389,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanic - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5275,
                        'IsSynonym': false,
                        'Sequence': 1390,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Detail Draftsperson'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5276,
                        'IsSynonym': false,
                        'Sequence': 1391,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5277,
                        'IsSynonym': false,
                        'Sequence': 1392,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineering Associate'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5278,
                        'IsSynonym': false,
                        'Sequence': 1393,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineering Design Draftsperson'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5279,
                        'IsSynonym': false,
                        'Sequence': 1394,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineering Drafting Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5280,
                        'IsSynonym': false,
                        'Sequence': 1395,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineering Drafting Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5281,
                        'IsSynonym': false,
                        'Sequence': 1396,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineering Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5282,
                        'IsSynonym': false,
                        'Sequence': 1397,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mechanical Engineering Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5283,
                        'IsSynonym': false,
                        'Sequence': 1398,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Media Officer/spokesperson'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5284,
                        'IsSynonym': false,
                        'Sequence': 1399,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Chemist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5285,
                        'IsSynonym': false,
                        'Sequence': 1400,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Counsellor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5286,
                        'IsSynonym': false,
                        'Sequence': 1401,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Doctor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5287,
                        'IsSynonym': false,
                        'Sequence': 1402,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Laboratory Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5288,
                        'IsSynonym': false,
                        'Sequence': 1403,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Laboratory Scientist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5289,
                        'IsSynonym': false,
                        'Sequence': 1404,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Laboratory Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5290,
                        'IsSynonym': false,
                        'Sequence': 1405,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Laboratory Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5291,
                        'IsSynonym': false,
                        'Sequence': 1406,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Oncologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5292,
                        'IsSynonym': false,
                        'Sequence': 1407,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Physicist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5293,
                        'IsSynonym': false,
                        'Sequence': 1408,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Record Administrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5294,
                        'IsSynonym': false,
                        'Sequence': 1409,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Representative (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5295,
                        'IsSynonym': false,
                        'Sequence': 1410,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Representative (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5296,
                        'IsSynonym': false,
                        'Sequence': 1411,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Superintendent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5297,
                        'IsSynonym': false,
                        'Sequence': 1412,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5298,
                        'IsSynonym': false,
                        'Sequence': 1413,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medical Technologist'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 5299,
                        'IsSynonym': false,
                        'Sequence': 1414,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Medically Boarded'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5300,
                        'IsSynonym': false,
                        'Sequence': 1415,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Member of Parliament'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 5301,
                        'IsSynonym': false,
                        'Sequence': 1416,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mercenary'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5302,
                        'IsSynonym': false,
                        'Sequence': 1417,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Merchandise Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5303,
                        'IsSynonym': false,
                        'Sequence': 1418,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Merchant Seaman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5304,
                        'IsSynonym': false,
                        'Sequence': 1419,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Messenger (office)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5305,
                        'IsSynonym': false,
                        'Sequence': 1420,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Messenger, Motorcycle'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5306,
                        'IsSynonym': false,
                        'Sequence': 1421,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metal worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5307,
                        'IsSynonym': false,
                        'Sequence': 1422,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metallographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5308,
                        'IsSynonym': false,
                        'Sequence': 1423,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metallurgical Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5309,
                        'IsSynonym': false,
                        'Sequence': 1424,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metallurgical Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5310,
                        'IsSynonym': false,
                        'Sequence': 1425,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metallurgist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5311,
                        'IsSynonym': false,
                        'Sequence': 1426,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meteorological Observer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5312,
                        'IsSynonym': false,
                        'Sequence': 1427,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meteorological Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5313,
                        'IsSynonym': false,
                        'Sequence': 1428,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meteorologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5314,
                        'IsSynonym': false,
                        'Sequence': 1429,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Meter reader, test engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5315,
                        'IsSynonym': false,
                        'Sequence': 1430,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Methods Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5316,
                        'IsSynonym': false,
                        'Sequence': 1431,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Assistant Divisional Commander'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5317,
                        'IsSynonym': false,
                        'Sequence': 1432,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Chief of Police'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5318,
                        'IsSynonym': false,
                        'Sequence': 1433,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - City Police Chief'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5319,
                        'IsSynonym': false,
                        'Sequence': 1434,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Constable'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5320,
                        'IsSynonym': false,
                        'Sequence': 1435,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Deputy Chief'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5321,
                        'IsSynonym': false,
                        'Sequence': 1436,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Deputy Chief of Police'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5322,
                        'IsSynonym': false,
                        'Sequence': 1437,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5323,
                        'IsSynonym': false,
                        'Sequence': 1438,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Divisional Director'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5324,
                        'IsSynonym': false,
                        'Sequence': 1439,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Executive Director'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5325,
                        'IsSynonym': false,
                        'Sequence': 1440,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5326,
                        'IsSynonym': false,
                        'Sequence': 1441,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Precint Commander'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5327,
                        'IsSynonym': false,
                        'Sequence': 1442,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Regional Commander'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5328,
                        'IsSynonym': false,
                        'Sequence': 1443,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Sergeant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5329,
                        'IsSynonym': false,
                        'Sequence': 1444,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Metro Police - Superintendant Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5330,
                        'IsSynonym': false,
                        'Sequence': 1445,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Microbiologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5331,
                        'IsSynonym': false,
                        'Sequence': 1446,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Microbiology Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5332,
                        'IsSynonym': false,
                        'Sequence': 1447,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Microlender (registered only)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5333,
                        'IsSynonym': false,
                        'Sequence': 1448,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Microlender (Unregistered)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5334,
                        'IsSynonym': false,
                        'Sequence': 1449,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Microphone Boom Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5335,
                        'IsSynonym': false,
                        'Sequence': 1450,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Midwife'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5336,
                        'IsSynonym': false,
                        'Sequence': 1451,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Airman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5337,
                        'IsSynonym': false,
                        'Sequence': 1452,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Brigadier (Not Pilot)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5338,
                        'IsSynonym': false,
                        'Sequence': 1453,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Brigadier General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5339,
                        'IsSynonym': false,
                        'Sequence': 1454,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Candidate Officer (Not Pilot)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5340,
                        'IsSynonym': false,
                        'Sequence': 1455,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Captain (Not Pilot)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5341,
                        'IsSynonym': false,
                        'Sequence': 1456,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Chaplain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5342,
                        'IsSynonym': false,
                        'Sequence': 1457,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Colonel (Not Pilot)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5343,
                        'IsSynonym': false,
                        'Sequence': 1458,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Commissioned Officer (Not Pilot)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5344,
                        'IsSynonym': false,
                        'Sequence': 1459,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Corporal'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5345,
                        'IsSynonym': false,
                        'Sequence': 1460,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Flight Instructor'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5346,
                        'IsSynonym': false,
                        'Sequence': 1461,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Flight Sergeant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5347,
                        'IsSynonym': false,
                        'Sequence': 1462,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5348,
                        'IsSynonym': false,
                        'Sequence': 1463,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Lance Corporal'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5349,
                        'IsSynonym': false,
                        'Sequence': 1464,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Lieutenant (Not Pilot)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5350,
                        'IsSynonym': false,
                        'Sequence': 1465,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Lieutenant Colonel (Not Pilot)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5351,
                        'IsSynonym': false,
                        'Sequence': 1466,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Lieutenant General'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5352,
                        'IsSynonym': false,
                        'Sequence': 1467,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Major (Not Pilot)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5353,
                        'IsSynonym': false,
                        'Sequence': 1468,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Major General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5354,
                        'IsSynonym': false,
                        'Sequence': 1469,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Non-Commissioned Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5355,
                        'IsSynonym': false,
                        'Sequence': 1470,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Officer (Not Pilot)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5356,
                        'IsSynonym': false,
                        'Sequence': 1471,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Pilot - Fixed Wing - Brigadier'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5357,
                        'IsSynonym': false,
                        'Sequence': 1472,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air force - Pilot - Fixed Wing - Candidate Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5358,
                        'IsSynonym': false,
                        'Sequence': 1473,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air force - Pilot - Fixed Wing - Captain'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5359,
                        'IsSynonym': false,
                        'Sequence': 1474,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Pilot - Fixed Wing - Colonel'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5360,
                        'IsSynonym': false,
                        'Sequence': 1475,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air force - Pilot - Fixed Wing - Flight Lieutenant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5361,
                        'IsSynonym': false,
                        'Sequence': 1476,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air force - Pilot - Fixed Wing - Lieutenant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5362,
                        'IsSynonym': false,
                        'Sequence': 1477,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Pilot - Fixed Wing - Lieutenant Colonel'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5363,
                        'IsSynonym': false,
                        'Sequence': 1478,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Pilot - Fixed Wing - Major'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5364,
                        'IsSynonym': false,
                        'Sequence': 1479,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air force - Pilot - Helicopter (Attack)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5365,
                        'IsSynonym': false,
                        'Sequence': 1480,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Pilot - Helicopter (Other)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5366,
                        'IsSynonym': false,
                        'Sequence': 1481,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Private'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5367,
                        'IsSynonym': false,
                        'Sequence': 1482,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Regimental Sergeant Major'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5368,
                        'IsSynonym': false,
                        'Sequence': 1483,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - RSM'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5369,
                        'IsSynonym': false,
                        'Sequence': 1484,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Second (2nd) Lieutenant (Not Pilot)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5370,
                        'IsSynonym': false,
                        'Sequence': 1485,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Sergeant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5371,
                        'IsSynonym': false,
                        'Sequence': 1486,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Sergeant Major'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5372,
                        'IsSynonym': false,
                        'Sequence': 1487,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Warrant-Officer (class 1)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5373,
                        'IsSynonym': false,
                        'Sequence': 1488,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Air Force - Warrant-Officer (class 2)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5374,
                        'IsSynonym': false,
                        'Sequence': 1489,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Brigadier'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5375,
                        'IsSynonym': false,
                        'Sequence': 1490,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Brigadier General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5376,
                        'IsSynonym': false,
                        'Sequence': 1491,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Candidate Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5377,
                        'IsSynonym': false,
                        'Sequence': 1492,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Captain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5378,
                        'IsSynonym': false,
                        'Sequence': 1493,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Chaplain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5379,
                        'IsSynonym': false,
                        'Sequence': 1494,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Colonel'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5380,
                        'IsSynonym': false,
                        'Sequence': 1495,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Commissioned Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5381,
                        'IsSynonym': false,
                        'Sequence': 1496,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Corporal'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5382,
                        'IsSynonym': false,
                        'Sequence': 1497,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5383,
                        'IsSynonym': false,
                        'Sequence': 1498,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Lance Corporal'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5384,
                        'IsSynonym': false,
                        'Sequence': 1499,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Lieutenant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5385,
                        'IsSynonym': false,
                        'Sequence': 1500,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Lieutenant Colonel'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5386,
                        'IsSynonym': false,
                        'Sequence': 1501,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Lieutenant General'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5387,
                        'IsSynonym': false,
                        'Sequence': 1502,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Major'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5388,
                        'IsSynonym': false,
                        'Sequence': 1503,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Major General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5389,
                        'IsSynonym': false,
                        'Sequence': 1504,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Non-Commissioned Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5390,
                        'IsSynonym': false,
                        'Sequence': 1505,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5391,
                        'IsSynonym': false,
                        'Sequence': 1506,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Private'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5392,
                        'IsSynonym': false,
                        'Sequence': 1507,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Regimental Sergeant Major'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5393,
                        'IsSynonym': false,
                        'Sequence': 1508,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - RSM'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5394,
                        'IsSynonym': false,
                        'Sequence': 1509,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Second (2nd) Lieutenant'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5395,
                        'IsSynonym': false,
                        'Sequence': 1510,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Sergeant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5396,
                        'IsSynonym': false,
                        'Sequence': 1511,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Sergeant Major'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5397,
                        'IsSynonym': false,
                        'Sequence': 1512,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Staff Sergeant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5398,
                        'IsSynonym': false,
                        'Sequence': 1513,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Warrant-Officer (class 1)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5399,
                        'IsSynonym': false,
                        'Sequence': 1514,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Army - Warrant-Officer (class 2)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5400,
                        'IsSynonym': false,
                        'Sequence': 1515,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Brigadier'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5401,
                        'IsSynonym': false,
                        'Sequence': 1516,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Brigadier General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5402,
                        'IsSynonym': false,
                        'Sequence': 1517,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Candidate Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5403,
                        'IsSynonym': false,
                        'Sequence': 1518,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Captain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5404,
                        'IsSynonym': false,
                        'Sequence': 1519,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Chaplain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5405,
                        'IsSynonym': false,
                        'Sequence': 1520,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Colonel'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5406,
                        'IsSynonym': false,
                        'Sequence': 1521,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Commissioned Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5407,
                        'IsSynonym': false,
                        'Sequence': 1522,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Corporal'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5408,
                        'IsSynonym': false,
                        'Sequence': 1523,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5409,
                        'IsSynonym': false,
                        'Sequence': 1524,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Lance Corporal'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5410,
                        'IsSynonym': false,
                        'Sequence': 1525,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Lieutenant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5411,
                        'IsSynonym': false,
                        'Sequence': 1526,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Lieutenant Colonel'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5412,
                        'IsSynonym': false,
                        'Sequence': 1527,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Lieutenant General'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5413,
                        'IsSynonym': false,
                        'Sequence': 1528,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Major'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5414,
                        'IsSynonym': false,
                        'Sequence': 1529,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Major General'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5415,
                        'IsSynonym': false,
                        'Sequence': 1530,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Non-Commissioned Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5416,
                        'IsSynonym': false,
                        'Sequence': 1531,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5417,
                        'IsSynonym': false,
                        'Sequence': 1532,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Private'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5418,
                        'IsSynonym': false,
                        'Sequence': 1533,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Regimental Sergeant Major'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5419,
                        'IsSynonym': false,
                        'Sequence': 1534,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - RSM'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5420,
                        'IsSynonym': false,
                        'Sequence': 1535,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Second (2nd) Lieutenant'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5421,
                        'IsSynonym': false,
                        'Sequence': 1536,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Sergeant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5422,
                        'IsSynonym': false,
                        'Sequence': 1537,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Sergeant Major'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5423,
                        'IsSynonym': false,
                        'Sequence': 1538,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Staff Sergeant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5424,
                        'IsSynonym': false,
                        'Sequence': 1539,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Surgeon General'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5425,
                        'IsSynonym': false,
                        'Sequence': 1540,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Warrant-Officer (class 1)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5426,
                        'IsSynonym': false,
                        'Sequence': 1541,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Medics - Warrant-Officer (class 2)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5427,
                        'IsSynonym': false,
                        'Sequence': 1542,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Able Seaman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5428,
                        'IsSynonym': false,
                        'Sequence': 1543,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Admiral'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5429,
                        'IsSynonym': false,
                        'Sequence': 1544,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Captain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5430,
                        'IsSynonym': false,
                        'Sequence': 1545,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Chaplain'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5431,
                        'IsSynonym': false,
                        'Sequence': 1546,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Chief Petty Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5432,
                        'IsSynonym': false,
                        'Sequence': 1547,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Commander'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5433,
                        'IsSynonym': false,
                        'Sequence': 1548,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Diver'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5434,
                        'IsSynonym': false,
                        'Sequence': 1549,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Ensign'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5435,
                        'IsSynonym': false,
                        'Sequence': 1550,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Leading Seaman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5436,
                        'IsSynonym': false,
                        'Sequence': 1551,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Lieutenant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5437,
                        'IsSynonym': false,
                        'Sequence': 1552,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Lieutenant Commander'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5438,
                        'IsSynonym': false,
                        'Sequence': 1553,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Master Chief Warrant Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5439,
                        'IsSynonym': false,
                        'Sequence': 1554,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Midshipman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5440,
                        'IsSynonym': false,
                        'Sequence': 1555,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Petty Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5441,
                        'IsSynonym': false,
                        'Sequence': 1556,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Rear Admiral'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5442,
                        'IsSynonym': false,
                        'Sequence': 1557,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Rear Admiral (Junior Grade)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5443,
                        'IsSynonym': false,
                        'Sequence': 1558,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Seaman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5444,
                        'IsSynonym': false,
                        'Sequence': 1559,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Senior Chief Warrant Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5445,
                        'IsSynonym': false,
                        'Sequence': 1560,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Sub Lieutenant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5446,
                        'IsSynonym': false,
                        'Sequence': 1561,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Vice Admiral'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5447,
                        'IsSynonym': false,
                        'Sequence': 1562,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Warrant Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5448,
                        'IsSynonym': false,
                        'Sequence': 1563,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Warrant Officer - Master Coxswain'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5449,
                        'IsSynonym': false,
                        'Sequence': 1564,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Warrant Officer (Class 1)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5450,
                        'IsSynonym': false,
                        'Sequence': 1565,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Military - Navy - Warrant Officer (Class 2)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5451,
                        'IsSynonym': false,
                        'Sequence': 1566,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Milk Processing Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5452,
                        'IsSynonym': false,
                        'Sequence': 1567,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Miller'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5453,
                        'IsSynonym': false,
                        'Sequence': 1568,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Millwright'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5454,
                        'IsSynonym': false,
                        'Sequence': 1569,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mime'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5455,
                        'IsSynonym': false,
                        'Sequence': 1570,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mineral Chemist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5456,
                        'IsSynonym': false,
                        'Sequence': 1571,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mineral Ore Processing Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5457,
                        'IsSynonym': false,
                        'Sequence': 1572,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mineral Separation Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5458,
                        'IsSynonym': false,
                        'Sequence': 1573,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mineralogist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5459,
                        'IsSynonym': false,
                        'Sequence': 1574,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Minerals Laboratory Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5460,
                        'IsSynonym': false,
                        'Sequence': 1575,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Minerals Laboratory Technician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5461,
                        'IsSynonym': false,
                        'Sequence': 1576,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Minibus Taxi Owner, admin and Supervision'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5462,
                        'IsSynonym': false,
                        'Sequence': 1577,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Apprentice Boilermaker'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5463,
                        'IsSynonym': false,
                        'Sequence': 1578,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Apprentice Miner'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5464,
                        'IsSynonym': false,
                        'Sequence': 1579,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Blaster'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5465,
                        'IsSynonym': false,
                        'Sequence': 1580,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Boilermaker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5466,
                        'IsSynonym': false,
                        'Sequence': 1581,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Bucketwheel Excavator Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5467,
                        'IsSynonym': false,
                        'Sequence': 1582,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Bucketwheel Reclaimer Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5468,
                        'IsSynonym': false,
                        'Sequence': 1583,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Carrier (Coal Merchants)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5469,
                        'IsSynonym': false,
                        'Sequence': 1584,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Chief Analyst/Chemist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5470,
                        'IsSynonym': false,
                        'Sequence': 1585,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Chief Instructor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5471,
                        'IsSynonym': false,
                        'Sequence': 1586,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Chief Mining Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5472,
                        'IsSynonym': false,
                        'Sequence': 1587,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Coal Merchant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5473,
                        'IsSynonym': false,
                        'Sequence': 1588,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Contractor (< 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5474,
                        'IsSynonym': false,
                        'Sequence': 1589,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Contractor (> 20% travel or > 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5475,
                        'IsSynonym': false,
                        'Sequence': 1590,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Crusherman (Quarry Worker)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5476,
                        'IsSynonym': false,
                        'Sequence': 1591,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Dragline Supervisor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5477,
                        'IsSynonym': false,
                        'Sequence': 1592,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Driller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5478,
                        'IsSynonym': false,
                        'Sequence': 1593,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Drilling Plant Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5479,
                        'IsSynonym': false,
                        'Sequence': 1594,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Drop Sorter Operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5480,
                        'IsSynonym': false,
                        'Sequence': 1595,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Electrician - Heights > 12m or High Voltage > 1000V'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5481,
                        'IsSynonym': false,
                        'Sequence': 1596,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Electrician - Heights up to 12m and Voltage up to 1000V'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5482,
                        'IsSynonym': false,
                        'Sequence': 1597,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5483,
                        'IsSynonym': false,
                        'Sequence': 1598,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Environmental Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5484,
                        'IsSynonym': false,
                        'Sequence': 1599,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Fitter and Turner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5485,
                        'IsSynonym': false,
                        'Sequence': 1600,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Foreman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5486,
                        'IsSynonym': false,
                        'Sequence': 1601,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Geological Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5487,
                        'IsSynonym': false,
                        'Sequence': 1602,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Geologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5488,
                        'IsSynonym': false,
                        'Sequence': 1603,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Geology Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5489,
                        'IsSynonym': false,
                        'Sequence': 1604,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Geophysicist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5490,
                        'IsSynonym': false,
                        'Sequence': 1605,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Geophysics Technician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5491,
                        'IsSynonym': false,
                        'Sequence': 1606,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Hoist, Winch and Lift Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5492,
                        'IsSynonym': false,
                        'Sequence': 1607,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Instructor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5493,
                        'IsSynonym': false,
                        'Sequence': 1608,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Jackhammer operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5494,
                        'IsSynonym': false,
                        'Sequence': 1609,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Labourer (Explosives)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5495,
                        'IsSynonym': false,
                        'Sequence': 1610,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Labourer (No Explosives)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5496,
                        'IsSynonym': false,
                        'Sequence': 1611,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Learner Miner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5497,
                        'IsSynonym': false,
                        'Sequence': 1612,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Locomotive Driver'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5498,
                        'IsSynonym': false,
                        'Sequence': 1613,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Mine Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5499,
                        'IsSynonym': false,
                        'Sequence': 1614,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Mine Manager'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5500,
                        'IsSynonym': false,
                        'Sequence': 1615,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Miner (Explosives)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5501,
                        'IsSynonym': false,
                        'Sequence': 1616,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Miner (No Explosives)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5502,
                        'IsSynonym': false,
                        'Sequence': 1617,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Quarry Labourer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5503,
                        'IsSynonym': false,
                        'Sequence': 1618,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Reclaimer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5504,
                        'IsSynonym': false,
                        'Sequence': 1619,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Safety Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5505,
                        'IsSynonym': false,
                        'Sequence': 1620,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Sampler'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5506,
                        'IsSynonym': false,
                        'Sequence': 1621,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Shift Boss'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5507,
                        'IsSynonym': false,
                        'Sequence': 1622,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Shot Blaster'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5508,
                        'IsSynonym': false,
                        'Sequence': 1623,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Shot Firer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5509,
                        'IsSynonym': false,
                        'Sequence': 1624,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Shot Firers Assistant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5510,
                        'IsSynonym': false,
                        'Sequence': 1625,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Shovel Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5511,
                        'IsSynonym': false,
                        'Sequence': 1626,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Stoper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5512,
                        'IsSynonym': false,
                        'Sequence': 1627,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Superintendent'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5513,
                        'IsSynonym': false,
                        'Sequence': 1628,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Supervisor - Blasting Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5514,
                        'IsSynonym': false,
                        'Sequence': 1629,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No underground - Supervisor (Coal Merchants)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5515,
                        'IsSynonym': false,
                        'Sequence': 1630,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Supervisor (Explosives)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5516,
                        'IsSynonym': false,
                        'Sequence': 1631,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Supervisor (No Explosives)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5517,
                        'IsSynonym': false,
                        'Sequence': 1632,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Sweeper'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5518,
                        'IsSynonym': false,
                        'Sequence': 1633,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5519,
                        'IsSynonym': false,
                        'Sequence': 1634,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Train Driver'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5520,
                        'IsSynonym': false,
                        'Sequence': 1635,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Trainee Locomotive Driver'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5521,
                        'IsSynonym': false,
                        'Sequence': 1636,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Training Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5522,
                        'IsSynonym': false,
                        'Sequence': 1637,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Waste Packer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5523,
                        'IsSynonym': false,
                        'Sequence': 1638,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - No Underground - Yardman (Coal Merchants)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5524,
                        'IsSynonym': false,
                        'Sequence': 1639,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Apprentice Boilermaker'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5525,
                        'IsSynonym': false,
                        'Sequence': 1640,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Apprentice Miner'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5526,
                        'IsSynonym': false,
                        'Sequence': 1641,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Blaster'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5527,
                        'IsSynonym': false,
                        'Sequence': 1642,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Boilermaker'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5528,
                        'IsSynonym': false,
                        'Sequence': 1643,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Coal Miner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5529,
                        'IsSynonym': false,
                        'Sequence': 1644,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Contractor (< 20% travel and < 20% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5530,
                        'IsSynonym': false,
                        'Sequence': 1645,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Contractor (> 20% travel or > 20% manual work)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5531,
                        'IsSynonym': false,
                        'Sequence': 1646,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Developer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5532,
                        'IsSynonym': false,
                        'Sequence': 1647,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Driller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5533,
                        'IsSynonym': false,
                        'Sequence': 1648,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Drilling Plant Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5534,
                        'IsSynonym': false,
                        'Sequence': 1649,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Drop Sorter Operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5535,
                        'IsSynonym': false,
                        'Sequence': 1650,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Early Examiner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5536,
                        'IsSynonym': false,
                        'Sequence': 1651,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Electrician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5537,
                        'IsSynonym': false,
                        'Sequence': 1652,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Engineer (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5538,
                        'IsSynonym': false,
                        'Sequence': 1653,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Engineer (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5539,
                        'IsSynonym': false,
                        'Sequence': 1654,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Environmental Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5540,
                        'IsSynonym': false,
                        'Sequence': 1655,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Fitter and Turner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5541,
                        'IsSynonym': false,
                        'Sequence': 1656,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Foreman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5542,
                        'IsSynonym': false,
                        'Sequence': 1657,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Geological Engineer (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5543,
                        'IsSynonym': false,
                        'Sequence': 1658,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Geological Engineer (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5544,
                        'IsSynonym': false,
                        'Sequence': 1659,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Geologist (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5545,
                        'IsSynonym': false,
                        'Sequence': 1660,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Geologist (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5546,
                        'IsSynonym': false,
                        'Sequence': 1661,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Geology Technician (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5547,
                        'IsSynonym': false,
                        'Sequence': 1662,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Geology Technician (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5548,
                        'IsSynonym': false,
                        'Sequence': 1663,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Hoist, Winch and Lift Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5549,
                        'IsSynonym': false,
                        'Sequence': 1664,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Instructor'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5550,
                        'IsSynonym': false,
                        'Sequence': 1665,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Labourer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5551,
                        'IsSynonym': false,
                        'Sequence': 1666,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Lasher'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5552,
                        'IsSynonym': false,
                        'Sequence': 1667,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Learner Miner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5553,
                        'IsSynonym': false,
                        'Sequence': 1668,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Locomotive Driver'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5554,
                        'IsSynonym': false,
                        'Sequence': 1669,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Master Sinker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5555,
                        'IsSynonym': false,
                        'Sequence': 1670,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Analyst'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5556,
                        'IsSynonym': false,
                        'Sequence': 1671,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Cage Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5557,
                        'IsSynonym': false,
                        'Sequence': 1672,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Captain (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5558,
                        'IsSynonym': false,
                        'Sequence': 1673,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Captain (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5559,
                        'IsSynonym': false,
                        'Sequence': 1674,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Inspector (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5560,
                        'IsSynonym': false,
                        'Sequence': 1675,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Inspector (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5561,
                        'IsSynonym': false,
                        'Sequence': 1676,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Manager (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5562,
                        'IsSynonym': false,
                        'Sequence': 1677,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Manager (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5563,
                        'IsSynonym': false,
                        'Sequence': 1678,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Surveyor (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5564,
                        'IsSynonym': false,
                        'Sequence': 1679,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Surveyor (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5565,
                        'IsSynonym': false,
                        'Sequence': 1680,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Mine Ventilation Hand'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5566,
                        'IsSynonym': false,
                        'Sequence': 1681,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Miner (Explosives)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5567,
                        'IsSynonym': false,
                        'Sequence': 1682,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Miner (No Explosives)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5568,
                        'IsSynonym': false,
                        'Sequence': 1683,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Opal Miner'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5569,
                        'IsSynonym': false,
                        'Sequence': 1684,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5570,
                        'IsSynonym': false,
                        'Sequence': 1685,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Plant Operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5571,
                        'IsSynonym': false,
                        'Sequence': 1686,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Roof Bolter'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5572,
                        'IsSynonym': false,
                        'Sequence': 1687,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Safety Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5573,
                        'IsSynonym': false,
                        'Sequence': 1688,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Sampler'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5574,
                        'IsSynonym': false,
                        'Sequence': 1689,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Sand Filler'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5575,
                        'IsSynonym': false,
                        'Sequence': 1690,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Shaft Sinker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5576,
                        'IsSynonym': false,
                        'Sequence': 1691,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Shift Boss'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5577,
                        'IsSynonym': false,
                        'Sequence': 1692,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Shot Blaster'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5578,
                        'IsSynonym': false,
                        'Sequence': 1693,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Shot Firer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5579,
                        'IsSynonym': false,
                        'Sequence': 1694,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Shot Firers Assistant'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5580,
                        'IsSynonym': false,
                        'Sequence': 1695,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Stoper'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5581,
                        'IsSynonym': false,
                        'Sequence': 1696,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Superintendent (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5582,
                        'IsSynonym': false,
                        'Sequence': 1697,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Superintendent (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5583,
                        'IsSynonym': false,
                        'Sequence': 1698,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Supervisor - Blasting Tradespersons'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5584,
                        'IsSynonym': false,
                        'Sequence': 1699,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Supervisor (Explosives)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5585,
                        'IsSynonym': false,
                        'Sequence': 1700,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Supervisor (No Explosives)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5586,
                        'IsSynonym': false,
                        'Sequence': 1701,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Sweeper'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5587,
                        'IsSynonym': false,
                        'Sequence': 1702,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Technician'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5588,
                        'IsSynonym': false,
                        'Sequence': 1703,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Timberman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5589,
                        'IsSynonym': false,
                        'Sequence': 1704,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Train Driver'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5590,
                        'IsSynonym': false,
                        'Sequence': 1705,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Trainee Locomotive Driver'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5591,
                        'IsSynonym': false,
                        'Sequence': 1706,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Training Officer (< 20 hours per week)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5592,
                        'IsSynonym': false,
                        'Sequence': 1707,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Training Officer (> 20 hours per week)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5593,
                        'IsSynonym': false,
                        'Sequence': 1708,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Trammer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5594,
                        'IsSynonym': false,
                        'Sequence': 1709,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Truck Operator'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5595,
                        'IsSynonym': false,
                        'Sequence': 1710,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Tunnel Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5596,
                        'IsSynonym': false,
                        'Sequence': 1711,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Vertical Borer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5597,
                        'IsSynonym': false,
                        'Sequence': 1712,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining - Underground - Winze Sinker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5598,
                        'IsSynonym': false,
                        'Sequence': 1713,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mining Engineer - 100% Admin'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5599,
                        'IsSynonym': false,
                        'Sequence': 1714,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Minister of Religion'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5600,
                        'IsSynonym': false,
                        'Sequence': 1715,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Minister/Clergyman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5601,
                        'IsSynonym': false,
                        'Sequence': 1716,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Missionary'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5602,
                        'IsSynonym': false,
                        'Sequence': 1717,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mobile crane operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5603,
                        'IsSynonym': false,
                        'Sequence': 1718,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mobile Vehicle Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5604,
                        'IsSynonym': false,
                        'Sequence': 1719,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Model'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5605,
                        'IsSynonym': false,
                        'Sequence': 1720,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Model Maker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5606,
                        'IsSynonym': false,
                        'Sequence': 1721,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Modelling Agent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5607,
                        'IsSynonym': false,
                        'Sequence': 1722,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Molecular Biologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5608,
                        'IsSynonym': false,
                        'Sequence': 1723,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Money Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5609,
                        'IsSynonym': false,
                        'Sequence': 1724,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Money Market Dealer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5610,
                        'IsSynonym': false,
                        'Sequence': 1725,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mortician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5611,
                        'IsSynonym': false,
                        'Sequence': 1726,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mosaic Tiler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5612,
                        'IsSynonym': false,
                        'Sequence': 1727,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motel Proprietor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5613,
                        'IsSynonym': false,
                        'Sequence': 1728,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motion Picture Camera Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5614,
                        'IsSynonym': false,
                        'Sequence': 1729,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motion Picture Projectionist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5615,
                        'IsSynonym': false,
                        'Sequence': 1730,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motor Racer - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5616,
                        'IsSynonym': false,
                        'Sequence': 1731,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motor Vehicle Cleaner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5617,
                        'IsSynonym': false,
                        'Sequence': 1732,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motor vehicle mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5618,
                        'IsSynonym': false,
                        'Sequence': 1733,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motor Vessel Engineer Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5619,
                        'IsSynonym': false,
                        'Sequence': 1734,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Motorcycle Racer - Professional'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5620,
                        'IsSynonym': false,
                        'Sequence': 1735,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mud Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5621,
                        'IsSynonym': false,
                        'Sequence': 1736,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Municipal Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5622,
                        'IsSynonym': false,
                        'Sequence': 1737,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Museum curator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5623,
                        'IsSynonym': false,
                        'Sequence': 1738,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Music Arranger'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5624,
                        'IsSynonym': false,
                        'Sequence': 1739,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Music Copyist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5625,
                        'IsSynonym': false,
                        'Sequence': 1740,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Music Director'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5626,
                        'IsSynonym': false,
                        'Sequence': 1741,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Music Producer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5627,
                        'IsSynonym': false,
                        'Sequence': 1742,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Music Teacher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5628,
                        'IsSynonym': false,
                        'Sequence': 1743,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Musical Instrument Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5629,
                        'IsSynonym': false,
                        'Sequence': 1744,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Musician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5630,
                        'IsSynonym': false,
                        'Sequence': 1745,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Mycological Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5631,
                        'IsSynonym': false,
                        'Sequence': 1746,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nanny'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5632,
                        'IsSynonym': false,
                        'Sequence': 1747,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nature Conservation Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5633,
                        'IsSynonym': false,
                        'Sequence': 1748,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Naturopath'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5634,
                        'IsSynonym': false,
                        'Sequence': 1749,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nephrologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5635,
                        'IsSynonym': false,
                        'Sequence': 1750,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Neuroanatomist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5636,
                        'IsSynonym': false,
                        'Sequence': 1751,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Neurologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5637,
                        'IsSynonym': false,
                        'Sequence': 1752,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Neurophysiological Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5638,
                        'IsSynonym': false,
                        'Sequence': 1753,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Neuropsychologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5639,
                        'IsSynonym': false,
                        'Sequence': 1754,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Neurosurgeon'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5640,
                        'IsSynonym': false,
                        'Sequence': 1755,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Neurosurgical Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5641,
                        'IsSynonym': false,
                        'Sequence': 1756,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'News Correspondent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5642,
                        'IsSynonym': false,
                        'Sequence': 1757,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'News Editor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5643,
                        'IsSynonym': false,
                        'Sequence': 1758,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Newsagency Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5644,
                        'IsSynonym': false,
                        'Sequence': 1759,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Newspaper Editor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5645,
                        'IsSynonym': false,
                        'Sequence': 1760,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Newsreader - TV/Radio'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5646,
                        'IsSynonym': false,
                        'Sequence': 1761,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nuclear Medicine Specialist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5647,
                        'IsSynonym': false,
                        'Sequence': 1762,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nuclear Medicine Technologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5648,
                        'IsSynonym': false,
                        'Sequence': 1763,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nuclear Scientist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5649,
                        'IsSynonym': false,
                        'Sequence': 1764,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nurse Administrator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5650,
                        'IsSynonym': false,
                        'Sequence': 1765,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nurse Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5651,
                        'IsSynonym': false,
                        'Sequence': 1766,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nurse Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5652,
                        'IsSynonym': false,
                        'Sequence': 1767,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursery Assistant - Horticulture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5653,
                        'IsSynonym': false,
                        'Sequence': 1768,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursery Hand - Horticulture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5654,
                        'IsSynonym': false,
                        'Sequence': 1769,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nurseryman/woman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5655,
                        'IsSynonym': false,
                        'Sequence': 1770,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursing Aid - Medical Profession'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5656,
                        'IsSynonym': false,
                        'Sequence': 1771,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursing Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5657,
                        'IsSynonym': false,
                        'Sequence': 1772,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursing Sister'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5658,
                        'IsSynonym': false,
                        'Sequence': 1773,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursing Superintendent (No manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5659,
                        'IsSynonym': false,
                        'Sequence': 1774,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nursing Supervisor (No manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5660,
                        'IsSynonym': false,
                        'Sequence': 1775,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Nutritionist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5661,
                        'IsSynonym': false,
                        'Sequence': 1776,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Obstetrician and Gynaecologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5662,
                        'IsSynonym': false,
                        'Sequence': 1777,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Occupational Health Doctor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5663,
                        'IsSynonym': false,
                        'Sequence': 1778,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Occupational Health Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5664,
                        'IsSynonym': false,
                        'Sequence': 1779,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Occupational Hygienist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5665,
                        'IsSynonym': false,
                        'Sequence': 1780,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Occupational Medicine Specialist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5666,
                        'IsSynonym': false,
                        'Sequence': 1781,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Occupational Specialist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5667,
                        'IsSynonym': false,
                        'Sequence': 1782,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Occupational Therapist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5668,
                        'IsSynonym': false,
                        'Sequence': 1783,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oceanographer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5669,
                        'IsSynonym': false,
                        'Sequence': 1784,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oceanographic Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5670,
                        'IsSynonym': false,
                        'Sequence': 1785,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oenologist/Winemaker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5671,
                        'IsSynonym': false,
                        'Sequence': 1786,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Office Cleaner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5672,
                        'IsSynonym': false,
                        'Sequence': 1787,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Office Equipment Servicer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5673,
                        'IsSynonym': false,
                        'Sequence': 1788,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Office Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5674,
                        'IsSynonym': false,
                        'Sequence': 1789,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Office Secretary'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5675,
                        'IsSynonym': false,
                        'Sequence': 1790,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Office Worker, Railways'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5676,
                        'IsSynonym': false,
                        'Sequence': 1791,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oil and Gas Drilling Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5677,
                        'IsSynonym': false,
                        'Sequence': 1792,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oil Tempering Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5678,
                        'IsSynonym': false,
                        'Sequence': 1793,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oil Well Cementer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5679,
                        'IsSynonym': false,
                        'Sequence': 1794,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oil, Gas and Pipe Tester'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5680,
                        'IsSynonym': false,
                        'Sequence': 1795,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oiler'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5681,
                        'IsSynonym': false,
                        'Sequence': 1796,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oilrig Driller'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5682,
                        'IsSynonym': false,
                        'Sequence': 1797,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Olericulturist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5683,
                        'IsSynonym': false,
                        'Sequence': 1798,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ombudsman'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5684,
                        'IsSynonym': false,
                        'Sequence': 1799,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oncological Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5685,
                        'IsSynonym': false,
                        'Sequence': 1800,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oncologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5686,
                        'IsSynonym': false,
                        'Sequence': 1801,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Operating Theatre Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5687,
                        'IsSynonym': false,
                        'Sequence': 1802,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Operations Manager (Data Processing)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5688,
                        'IsSynonym': false,
                        'Sequence': 1803,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Operations Research Analyst'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5689,
                        'IsSynonym': false,
                        'Sequence': 1804,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ophthalmic Lens Surfacer and Fitter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5690,
                        'IsSynonym': false,
                        'Sequence': 1805,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ophthalmic Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5691,
                        'IsSynonym': false,
                        'Sequence': 1806,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ophthalmologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5692,
                        'IsSynonym': false,
                        'Sequence': 1807,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Optical Dispenser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5693,
                        'IsSynonym': false,
                        'Sequence': 1808,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Optical Mechanic'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5694,
                        'IsSynonym': false,
                        'Sequence': 1809,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Optical Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5695,
                        'IsSynonym': false,
                        'Sequence': 1810,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Optician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5696,
                        'IsSynonym': false,
                        'Sequence': 1811,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Optics Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5697,
                        'IsSynonym': false,
                        'Sequence': 1812,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Optometrist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5698,
                        'IsSynonym': false,
                        'Sequence': 1813,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oral Hygienist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5699,
                        'IsSynonym': false,
                        'Sequence': 1814,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oral Surgeon'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5700,
                        'IsSynonym': false,
                        'Sequence': 1815,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orchardist, Agricultural Contractor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5701,
                        'IsSynonym': false,
                        'Sequence': 1816,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orchestral Conductor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5702,
                        'IsSynonym': false,
                        'Sequence': 1817,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Order Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5703,
                        'IsSynonym': false,
                        'Sequence': 1818,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Organ Builder'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5704,
                        'IsSynonym': false,
                        'Sequence': 1819,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Organisation and Methods Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5705,
                        'IsSynonym': false,
                        'Sequence': 1820,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Organisation and Methods Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5706,
                        'IsSynonym': false,
                        'Sequence': 1821,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orthodontic Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5707,
                        'IsSynonym': false,
                        'Sequence': 1822,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orthodontist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5708,
                        'IsSynonym': false,
                        'Sequence': 1823,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orthopaedic Specialist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5709,
                        'IsSynonym': false,
                        'Sequence': 1824,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orthopaedic Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5710,
                        'IsSynonym': false,
                        'Sequence': 1825,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orthoptist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5711,
                        'IsSynonym': false,
                        'Sequence': 1826,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Orthotist/Prosthetist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5712,
                        'IsSynonym': false,
                        'Sequence': 1827,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Osteopath'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5713,
                        'IsSynonym': false,
                        'Sequence': 1828,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Otorhinolaryngological Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5714,
                        'IsSynonym': false,
                        'Sequence': 1829,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Otorhinolaryngologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5715,
                        'IsSynonym': false,
                        'Sequence': 1830,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Overlocker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5716,
                        'IsSynonym': false,
                        'Sequence': 1831,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Oyster Farmer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5717,
                        'IsSynonym': false,
                        'Sequence': 1832,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Packaging Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5718,
                        'IsSynonym': false,
                        'Sequence': 1833,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paediatric Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5719,
                        'IsSynonym': false,
                        'Sequence': 1834,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paediatric Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5720,
                        'IsSynonym': false,
                        'Sequence': 1835,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paediatrician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5721,
                        'IsSynonym': false,
                        'Sequence': 1836,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paedodontist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5722,
                        'IsSynonym': false,
                        'Sequence': 1837,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paint Factory Hand'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5723,
                        'IsSynonym': false,
                        'Sequence': 1838,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Painter (Artistic)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5724,
                        'IsSynonym': false,
                        'Sequence': 1839,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Painter (Building and Construction)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5725,
                        'IsSynonym': false,
                        'Sequence': 1840,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Palaeontologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5726,
                        'IsSynonym': false,
                        'Sequence': 1841,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Palaeontology Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5727,
                        'IsSynonym': false,
                        'Sequence': 1842,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Panelbeater'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5728,
                        'IsSynonym': false,
                        'Sequence': 1843,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paper and Paper Products Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5729,
                        'IsSynonym': false,
                        'Sequence': 1844,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Parachute Rigger'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5730,
                        'IsSynonym': false,
                        'Sequence': 1845,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paragliding Instructor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5731,
                        'IsSynonym': false,
                        'Sequence': 1846,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Parasitologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5732,
                        'IsSynonym': false,
                        'Sequence': 1847,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Park Keeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5733,
                        'IsSynonym': false,
                        'Sequence': 1848,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Park Superintendent'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5734,
                        'IsSynonym': false,
                        'Sequence': 1849,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Parking Meter Collector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5735,
                        'IsSynonym': false,
                        'Sequence': 1850,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Parliamentary Counsel'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5736,
                        'IsSynonym': false,
                        'Sequence': 1851,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Part-time Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5737,
                        'IsSynonym': false,
                        'Sequence': 1852,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pasta Maker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5738,
                        'IsSynonym': false,
                        'Sequence': 1853,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pastor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5739,
                        'IsSynonym': false,
                        'Sequence': 1854,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Patent Examiner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5740,
                        'IsSynonym': false,
                        'Sequence': 1855,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pathologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5741,
                        'IsSynonym': false,
                        'Sequence': 1856,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pattern Maker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5742,
                        'IsSynonym': false,
                        'Sequence': 1857,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Patternmaker-Grader (Textiles)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5743,
                        'IsSynonym': false,
                        'Sequence': 1858,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paver/pavior'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5744,
                        'IsSynonym': false,
                        'Sequence': 1859,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paving and Surfacing Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5745,
                        'IsSynonym': false,
                        'Sequence': 1860,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Paving and Surfacing Plant Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5746,
                        'IsSynonym': false,
                        'Sequence': 1861,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pawnbroker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5747,
                        'IsSynonym': false,
                        'Sequence': 1862,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Payroll Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5748,
                        'IsSynonym': false,
                        'Sequence': 1863,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pedologist/Soil Scientist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5749,
                        'IsSynonym': false,
                        'Sequence': 1864,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Penciller'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5750,
                        'IsSynonym': false,
                        'Sequence': 1865,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Performing Arts Support Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5751,
                        'IsSynonym': false,
                        'Sequence': 1866,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Perfusionist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5752,
                        'IsSynonym': false,
                        'Sequence': 1867,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Periodontist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5753,
                        'IsSynonym': false,
                        'Sequence': 1868,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personal Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5754,
                        'IsSynonym': false,
                        'Sequence': 1869,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personal Trainer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5755,
                        'IsSynonym': false,
                        'Sequence': 1870,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personnel and Industrial Relations Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5756,
                        'IsSynonym': false,
                        'Sequence': 1871,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personnel Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5757,
                        'IsSynonym': false,
                        'Sequence': 1872,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personnel Manager/Manageress'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5758,
                        'IsSynonym': false,
                        'Sequence': 1873,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personnel Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5759,
                        'IsSynonym': false,
                        'Sequence': 1874,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Personnel Records Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5760,
                        'IsSynonym': false,
                        'Sequence': 1875,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pest Controler/Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5761,
                        'IsSynonym': false,
                        'Sequence': 1876,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Petrochemical Process Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5762,
                        'IsSynonym': false,
                        'Sequence': 1877,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Petrol Pump Attendant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5763,
                        'IsSynonym': false,
                        'Sequence': 1878,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Petroleum and Gas Refining and Pumping Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5764,
                        'IsSynonym': false,
                        'Sequence': 1879,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Petroleum Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5765,
                        'IsSynonym': false,
                        'Sequence': 1880,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Petrophysical Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5766,
                        'IsSynonym': false,
                        'Sequence': 1881,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pharmaceuticals and Toiletries Production Machine Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5767,
                        'IsSynonym': false,
                        'Sequence': 1882,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pharmacist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5768,
                        'IsSynonym': false,
                        'Sequence': 1883,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pharmacologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5769,
                        'IsSynonym': false,
                        'Sequence': 1884,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Phlebotomist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5770,
                        'IsSynonym': false,
                        'Sequence': 1885,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photogrammetric Surveyor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5771,
                        'IsSynonym': false,
                        'Sequence': 1886,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photogrammetrist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5772,
                        'IsSynonym': false,
                        'Sequence': 1887,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Aerial'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5773,
                        'IsSynonym': false,
                        'Sequence': 1888,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Journalist - Not limited to sport or fashion'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5774,
                        'IsSynonym': false,
                        'Sequence': 1889,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Journalist (Sport/Fashion only)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5775,
                        'IsSynonym': false,
                        'Sequence': 1890,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Press (< 10% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5776,
                        'IsSynonym': false,
                        'Sequence': 1891,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Press (> 10% travel)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5777,
                        'IsSynonym': false,
                        'Sequence': 1892,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Underwater < 40m'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5778,
                        'IsSynonym': false,
                        'Sequence': 1893,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer - Underwater > 40m'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5779,
                        'IsSynonym': false,
                        'Sequence': 1894,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographer (No Press/No Aerial/No Underwater)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5780,
                        'IsSynonym': false,
                        'Sequence': 1895,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographic Developer and Printer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5781,
                        'IsSynonym': false,
                        'Sequence': 1896,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographic Laboratory Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5782,
                        'IsSynonym': false,
                        'Sequence': 1897,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographic Products Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5783,
                        'IsSynonym': false,
                        'Sequence': 1898,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photographic Studio Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5784,
                        'IsSynonym': false,
                        'Sequence': 1899,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photolithographer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5785,
                        'IsSynonym': false,
                        'Sequence': 1900,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Photo-type Setter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5786,
                        'IsSynonym': false,
                        'Sequence': 1901,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physical Geographer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5787,
                        'IsSynonym': false,
                        'Sequence': 1902,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physical Metallurgist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5788,
                        'IsSynonym': false,
                        'Sequence': 1903,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physical Oceanographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5789,
                        'IsSynonym': false,
                        'Sequence': 1904,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5790,
                        'IsSynonym': false,
                        'Sequence': 1905,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physicist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5791,
                        'IsSynonym': false,
                        'Sequence': 1906,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physics Technical Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5792,
                        'IsSynonym': false,
                        'Sequence': 1907,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physics Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5793,
                        'IsSynonym': false,
                        'Sequence': 1908,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physiologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5794,
                        'IsSynonym': false,
                        'Sequence': 1909,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Physiotherapist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5795,
                        'IsSynonym': false,
                        'Sequence': 1910,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pianist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5796,
                        'IsSynonym': false,
                        'Sequence': 1911,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Piano Builder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5797,
                        'IsSynonym': false,
                        'Sequence': 1912,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Piano Tuner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5798,
                        'IsSynonym': false,
                        'Sequence': 1913,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Picture Frame Maker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5799,
                        'IsSynonym': false,
                        'Sequence': 1914,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Picture Framer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5800,
                        'IsSynonym': false,
                        'Sequence': 1915,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Airship'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5801,
                        'IsSynonym': false,
                        'Sequence': 1916,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Autogyro'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5802,
                        'IsSynonym': false,
                        'Sequence': 1917,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Balloon'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5803,
                        'IsSynonym': false,
                        'Sequence': 1918,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Agricultural (South Africa Only)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5804,
                        'IsSynonym': false,
                        'Sequence': 1919,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Cargo & Passenger (International)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5805,
                        'IsSynonym': false,
                        'Sequence': 1920,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Cargo & Passenger (South Africa Only)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5806,
                        'IsSynonym': false,
                        'Sequence': 1921,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Crop Dusting/Spraying (South Africa Only)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5807,
                        'IsSynonym': false,
                        'Sequence': 1922,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Fire Fighting (South Africa Only)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5808,
                        'IsSynonym': false,
                        'Sequence': 1923,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Flying Instructor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5809,
                        'IsSynonym': false,
                        'Sequence': 1924,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - IATA Registered Airline'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5810,
                        'IsSynonym': false,
                        'Sequence': 1925,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Medical Transport (International)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5811,
                        'IsSynonym': false,
                        'Sequence': 1926,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Medical Transport (South Africa Only)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5812,
                        'IsSynonym': false,
                        'Sequence': 1927,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Other Aviation Duties'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5813,
                        'IsSynonym': false,
                        'Sequence': 1928,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Patient Transport (International)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5814,
                        'IsSynonym': false,
                        'Sequence': 1929,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Patient Transport (South Africa Only)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5815,
                        'IsSynonym': false,
                        'Sequence': 1930,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Search & Rescue (South Africa Only)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5816,
                        'IsSynonym': false,
                        'Sequence': 1931,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Student/Cadet'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5817,
                        'IsSynonym': false,
                        'Sequence': 1932,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Fixed Wing - Test Pilot'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5818,
                        'IsSynonym': false,
                        'Sequence': 1933,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Glider'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5819,
                        'IsSynonym': false,
                        'Sequence': 1934,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Gyrocopter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5820,
                        'IsSynonym': false,
                        'Sequence': 1935,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Gyroplane'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5821,
                        'IsSynonym': false,
                        'Sequence': 1936,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Hang-Glider'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5822,
                        'IsSynonym': false,
                        'Sequence': 1937,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Ambulance (South Africa Only)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5823,
                        'IsSynonym': false,
                        'Sequence': 1938,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Cargo and Passenger (International)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5824,
                        'IsSynonym': false,
                        'Sequence': 1939,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Cargo and Passenger (South Africa Only)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5825,
                        'IsSynonym': false,
                        'Sequence': 1940,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Crop Dusting/Spraying (South Africa Only)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5826,
                        'IsSynonym': false,
                        'Sequence': 1941,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Fire Fighting (South Africa Only)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5827,
                        'IsSynonym': false,
                        'Sequence': 1942,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Flying Instructor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5828,
                        'IsSynonym': false,
                        'Sequence': 1943,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Offshore Landings'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5829,
                        'IsSynonym': false,
                        'Sequence': 1944,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Other Aviation Duties'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5830,
                        'IsSynonym': false,
                        'Sequence': 1945,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Search & Rescue (South Africa Only)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5831,
                        'IsSynonym': false,
                        'Sequence': 1946,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Student/Cadet'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5832,
                        'IsSynonym': false,
                        'Sequence': 1947,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Helicopter - Test Pilot'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5833,
                        'IsSynonym': false,
                        'Sequence': 1948,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Microlight'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5834,
                        'IsSynonym': false,
                        'Sequence': 1949,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot (Commercial) - Powered Paraglider'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5835,
                        'IsSynonym': false,
                        'Sequence': 1950,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pilot, Harbour, River Or Seagoing'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5836,
                        'IsSynonym': false,
                        'Sequence': 1951,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pipe fitter'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5837,
                        'IsSynonym': false,
                        'Sequence': 1952,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pipe layer, roadworker'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5838,
                        'IsSynonym': false,
                        'Sequence': 1953,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pipe/cable laying (Underwater < 40m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5839,
                        'IsSynonym': false,
                        'Sequence': 1954,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pipe/cable laying (Underwater > 40m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5840,
                        'IsSynonym': false,
                        'Sequence': 1955,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pipefitter - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5841,
                        'IsSynonym': false,
                        'Sequence': 1956,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plain Clothes Police Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5842,
                        'IsSynonym': false,
                        'Sequence': 1957,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Planning Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5843,
                        'IsSynonym': false,
                        'Sequence': 1958,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Anatomist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5844,
                        'IsSynonym': false,
                        'Sequence': 1959,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Morphologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5845,
                        'IsSynonym': false,
                        'Sequence': 1960,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5846,
                        'IsSynonym': false,
                        'Sequence': 1961,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Pathologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5847,
                        'IsSynonym': false,
                        'Sequence': 1962,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Physiologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5848,
                        'IsSynonym': false,
                        'Sequence': 1963,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Propagator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5849,
                        'IsSynonym': false,
                        'Sequence': 1964,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Scientist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5850,
                        'IsSynonym': false,
                        'Sequence': 1965,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Supervisor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5851,
                        'IsSynonym': false,
                        'Sequence': 1966,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plant Taxonomist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5852,
                        'IsSynonym': false,
                        'Sequence': 1967,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plasterer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5853,
                        'IsSynonym': false,
                        'Sequence': 1968,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plasterers Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5854,
                        'IsSynonym': false,
                        'Sequence': 1969,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plastic and Reconstructive Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5855,
                        'IsSynonym': false,
                        'Sequence': 1970,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Playwright'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5856,
                        'IsSynonym': false,
                        'Sequence': 1971,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ploughman, Agricultural Contractor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5857,
                        'IsSynonym': false,
                        'Sequence': 1972,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plumbing Contractor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5858,
                        'IsSynonym': false,
                        'Sequence': 1973,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Plywood and Veneer Repairer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5859,
                        'IsSynonym': false,
                        'Sequence': 1974,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Podiatrist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5860,
                        'IsSynonym': false,
                        'Sequence': 1975,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poet'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5861,
                        'IsSynonym': false,
                        'Sequence': 1976,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poker Machine Attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5862,
                        'IsSynonym': false,
                        'Sequence': 1977,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poker Machine Servicer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5863,
                        'IsSynonym': false,
                        'Sequence': 1978,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Brigadier (Previously called the Director)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5864,
                        'IsSynonym': false,
                        'Sequence': 1979,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Captain'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5865,
                        'IsSynonym': false,
                        'Sequence': 1980,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Colonel (Previously called Snr Superintendent)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5866,
                        'IsSynonym': false,
                        'Sequence': 1981,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Constable'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5867,
                        'IsSynonym': false,
                        'Sequence': 1982,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Diver'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5868,
                        'IsSynonym': false,
                        'Sequence': 1983,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - General (Previously called the National Commissioner)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5869,
                        'IsSynonym': false,
                        'Sequence': 1984,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Lieutenant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5870,
                        'IsSynonym': false,
                        'Sequence': 1985,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Lieutenant Colonel (Previously called Superintendent)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5871,
                        'IsSynonym': false,
                        'Sequence': 1986,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Lieutenant General (Previously called Deputy National Commissioner or Provincial Commissioner)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5872,
                        'IsSynonym': false,
                        'Sequence': 1987,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Major'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5873,
                        'IsSynonym': false,
                        'Sequence': 1988,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Major General (Previously called Assistant Commissioner)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5874,
                        'IsSynonym': false,
                        'Sequence': 1989,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Pilot'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5875,
                        'IsSynonym': false,
                        'Sequence': 1990,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Sergeant'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 5876,
                        'IsSynonym': false,
                        'Sequence': 1991,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Special Branch Anti-terrorist Unit'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 5877,
                        'IsSynonym': false,
                        'Sequence': 1992,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Special Branch Bomb Disposal'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5878,
                        'IsSynonym': false,
                        'Sequence': 1993,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police - Warrant officer (Previously called Inspector)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5879,
                        'IsSynonym': false,
                        'Sequence': 1994,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police Detective'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5880,
                        'IsSynonym': false,
                        'Sequence': 1995,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Police Member (Serious & Violent Crimes unit, Organised Crime Component, Dog and Mounted Units)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5881,
                        'IsSynonym': false,
                        'Sequence': 1996,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Political Scientist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5882,
                        'IsSynonym': false,
                        'Sequence': 1997,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Politician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5883,
                        'IsSynonym': false,
                        'Sequence': 1998,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Polymer Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5884,
                        'IsSynonym': false,
                        'Sequence': 1999,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Polymer Scientist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5885,
                        'IsSynonym': false,
                        'Sequence': 2000,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pool Attendant/Serviceperson'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5886,
                        'IsSynonym': false,
                        'Sequence': 2001,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Port Captain'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5887,
                        'IsSynonym': false,
                        'Sequence': 2002,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Porter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5888,
                        'IsSynonym': false,
                        'Sequence': 2003,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Postal Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5889,
                        'IsSynonym': false,
                        'Sequence': 2004,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Postal delivery, milkman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5890,
                        'IsSynonym': false,
                        'Sequence': 2005,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Postal Supervisor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5891,
                        'IsSynonym': false,
                        'Sequence': 2006,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Postmaster/mistress'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5892,
                        'IsSynonym': false,
                        'Sequence': 2007,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Potter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5893,
                        'IsSynonym': false,
                        'Sequence': 2008,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poulterer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5894,
                        'IsSynonym': false,
                        'Sequence': 2009,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poultry Boner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5895,
                        'IsSynonym': false,
                        'Sequence': 2010,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poultry Farm Hand'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5896,
                        'IsSynonym': false,
                        'Sequence': 2011,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Poultry Saws Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5897,
                        'IsSynonym': false,
                        'Sequence': 2012,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Power Boat Racer - Professional'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5898,
                        'IsSynonym': false,
                        'Sequence': 2013,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Power station manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5899,
                        'IsSynonym': false,
                        'Sequence': 2014,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Precious Metal Smith'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5900,
                        'IsSynonym': false,
                        'Sequence': 2015,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Premier'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5901,
                        'IsSynonym': false,
                        'Sequence': 2016,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pre-School Adviser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5902,
                        'IsSynonym': false,
                        'Sequence': 2017,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pre-School Teacher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5903,
                        'IsSynonym': false,
                        'Sequence': 2018,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Presenter, TV, radio or functions'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5904,
                        'IsSynonym': false,
                        'Sequence': 2019,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Priest'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5905,
                        'IsSynonym': false,
                        'Sequence': 2020,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Primary Metallurgist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5906,
                        'IsSynonym': false,
                        'Sequence': 2021,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Primary School Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5907,
                        'IsSynonym': false,
                        'Sequence': 2022,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Principal'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5908,
                        'IsSynonym': false,
                        'Sequence': 2023,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Principal Chemical Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5909,
                        'IsSynonym': false,
                        'Sequence': 2024,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Printed Circuit Board Designer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5910,
                        'IsSynonym': false,
                        'Sequence': 2025,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Private Equity Investor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5911,
                        'IsSynonym': false,
                        'Sequence': 2026,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Private Investigator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5912,
                        'IsSynonym': false,
                        'Sequence': 2027,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Probation/Probation officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5913,
                        'IsSynonym': false,
                        'Sequence': 2028,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Procedures Analyst'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5914,
                        'IsSynonym': false,
                        'Sequence': 2029,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Process Camera Operator (Printing)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5915,
                        'IsSynonym': false,
                        'Sequence': 2030,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Process Control Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5916,
                        'IsSynonym': false,
                        'Sequence': 2031,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Process Control Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5917,
                        'IsSynonym': false,
                        'Sequence': 2032,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Process Control Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5918,
                        'IsSynonym': false,
                        'Sequence': 2033,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Process Controller'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5919,
                        'IsSynonym': false,
                        'Sequence': 2034,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Process Worker - Dairy Industry'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5920,
                        'IsSynonym': false,
                        'Sequence': 2035,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Proctologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5921,
                        'IsSynonym': false,
                        'Sequence': 2036,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Procurement Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5922,
                        'IsSynonym': false,
                        'Sequence': 2037,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Procurement Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5923,
                        'IsSynonym': false,
                        'Sequence': 2038,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Producer (Media/TV/Radio)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5924,
                        'IsSynonym': false,
                        'Sequence': 2039,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Product Design Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5925,
                        'IsSynonym': false,
                        'Sequence': 2040,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Product Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5926,
                        'IsSynonym': false,
                        'Sequence': 2041,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Production Planning Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5927,
                        'IsSynonym': false,
                        'Sequence': 2042,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Production Recorder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5928,
                        'IsSynonym': false,
                        'Sequence': 2043,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Production Worker, Non Machinery'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5929,
                        'IsSynonym': false,
                        'Sequence': 2044,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Professor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5930,
                        'IsSynonym': false,
                        'Sequence': 2045,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Program Router Cutter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5931,
                        'IsSynonym': false,
                        'Sequence': 2046,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Program Seller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5932,
                        'IsSynonym': false,
                        'Sequence': 2047,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Programme Arranger'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5933,
                        'IsSynonym': false,
                        'Sequence': 2048,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Programmer Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5934,
                        'IsSynonym': false,
                        'Sequence': 2049,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Project Manager (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5935,
                        'IsSynonym': false,
                        'Sequence': 2050,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Project Manager (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5936,
                        'IsSynonym': false,
                        'Sequence': 2051,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Projectionist, Cinema or Theatre'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5937,
                        'IsSynonym': false,
                        'Sequence': 2052,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Promotions Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5938,
                        'IsSynonym': false,
                        'Sequence': 2053,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Proof Reader'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5939,
                        'IsSynonym': false,
                        'Sequence': 2054,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Prop and Scenery Maker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5940,
                        'IsSynonym': false,
                        'Sequence': 2055,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Properties Investment Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5941,
                        'IsSynonym': false,
                        'Sequence': 2056,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Property Appraiser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5942,
                        'IsSynonym': false,
                        'Sequence': 2057,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Property Broker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5943,
                        'IsSynonym': false,
                        'Sequence': 2058,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Property Developer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5944,
                        'IsSynonym': false,
                        'Sequence': 2059,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Prosecutor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5945,
                        'IsSynonym': false,
                        'Sequence': 2060,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Prosthodontist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5946,
                        'IsSynonym': false,
                        'Sequence': 2061,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Psychiatrist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5947,
                        'IsSynonym': false,
                        'Sequence': 2062,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Psychologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5948,
                        'IsSynonym': false,
                        'Sequence': 2063,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Public Policy Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5949,
                        'IsSynonym': false,
                        'Sequence': 2064,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Public Prosecutor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5950,
                        'IsSynonym': false,
                        'Sequence': 2065,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Public Relations Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5951,
                        'IsSynonym': false,
                        'Sequence': 2066,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Public Telephone Money Collector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5952,
                        'IsSynonym': false,
                        'Sequence': 2067,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Publication Designer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5953,
                        'IsSynonym': false,
                        'Sequence': 2068,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Publicity Agent'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5954,
                        'IsSynonym': false,
                        'Sequence': 2069,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Publicity Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5955,
                        'IsSynonym': false,
                        'Sequence': 2070,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Publisher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5956,
                        'IsSynonym': false,
                        'Sequence': 2071,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Publisher, Printer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5957,
                        'IsSynonym': false,
                        'Sequence': 2072,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pumpman - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5958,
                        'IsSynonym': false,
                        'Sequence': 2073,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Puppeteer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5959,
                        'IsSynonym': false,
                        'Sequence': 2074,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Purchasing Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5960,
                        'IsSynonym': false,
                        'Sequence': 2075,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Purchasing Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5961,
                        'IsSynonym': false,
                        'Sequence': 2076,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Purchasing Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5962,
                        'IsSynonym': false,
                        'Sequence': 2077,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Pyrometallurgical Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5963,
                        'IsSynonym': false,
                        'Sequence': 2078,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quality Assessor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5964,
                        'IsSynonym': false,
                        'Sequence': 2079,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quality Control Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5965,
                        'IsSynonym': false,
                        'Sequence': 2080,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quality Control Metallurgist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5966,
                        'IsSynonym': false,
                        'Sequence': 2081,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quality Controller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5967,
                        'IsSynonym': false,
                        'Sequence': 2082,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quality Controller - Supervision'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5968,
                        'IsSynonym': false,
                        'Sequence': 2083,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quality Controller, Glass Manufacturing'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5969,
                        'IsSynonym': false,
                        'Sequence': 2084,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quantity Surveyor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5970,
                        'IsSynonym': false,
                        'Sequence': 2085,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Quartermaster'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5971,
                        'IsSynonym': false,
                        'Sequence': 2086,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rabbi'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5972,
                        'IsSynonym': false,
                        'Sequence': 2087,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Race Horse Trainer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5973,
                        'IsSynonym': false,
                        'Sequence': 2088,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Race Steward'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5974,
                        'IsSynonym': false,
                        'Sequence': 2089,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Race Track Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5975,
                        'IsSynonym': false,
                        'Sequence': 2090,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Racecourse Official'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5976,
                        'IsSynonym': false,
                        'Sequence': 2091,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radar Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5977,
                        'IsSynonym': false,
                        'Sequence': 2092,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radar Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5978,
                        'IsSynonym': false,
                        'Sequence': 2093,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radiation Oncologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5979,
                        'IsSynonym': false,
                        'Sequence': 2094,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Despatcher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5980,
                        'IsSynonym': false,
                        'Sequence': 2095,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5981,
                        'IsSynonym': false,
                        'Sequence': 2096,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio License Inspector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5982,
                        'IsSynonym': false,
                        'Sequence': 2097,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Maintenance Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5983,
                        'IsSynonym': false,
                        'Sequence': 2098,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Mechanic'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5984,
                        'IsSynonym': false,
                        'Sequence': 2099,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5985,
                        'IsSynonym': false,
                        'Sequence': 2100,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5986,
                        'IsSynonym': false,
                        'Sequence': 2101,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5987,
                        'IsSynonym': false,
                        'Sequence': 2102,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Telegraphist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5988,
                        'IsSynonym': false,
                        'Sequence': 2103,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radio Traffic Scheduler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5989,
                        'IsSynonym': false,
                        'Sequence': 2104,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radiographer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5990,
                        'IsSynonym': false,
                        'Sequence': 2105,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radiological Metallurgist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5991,
                        'IsSynonym': false,
                        'Sequence': 2106,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radiologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 5992,
                        'IsSynonym': false,
                        'Sequence': 2107,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Radiotherapist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 5993,
                        'IsSynonym': false,
                        'Sequence': 2108,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5994,
                        'IsSynonym': false,
                        'Sequence': 2109,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Conductor (Sleeping Car)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 5995,
                        'IsSynonym': false,
                        'Sequence': 2110,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Crossing Attendant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5996,
                        'IsSynonym': false,
                        'Sequence': 2111,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Fettler'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 5997,
                        'IsSynonym': false,
                        'Sequence': 2112,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Foreman'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5998,
                        'IsSynonym': false,
                        'Sequence': 2113,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Labourer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 5999,
                        'IsSynonym': false,
                        'Sequence': 2114,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Patrol Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6000,
                        'IsSynonym': false,
                        'Sequence': 2115,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Security Guard'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6001,
                        'IsSynonym': false,
                        'Sequence': 2116,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Shunter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6002,
                        'IsSynonym': false,
                        'Sequence': 2117,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Station Assistant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6003,
                        'IsSynonym': false,
                        'Sequence': 2118,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Track Repairman'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6004,
                        'IsSynonym': false,
                        'Sequence': 2119,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Railway Yard Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6005,
                        'IsSynonym': false,
                        'Sequence': 2120,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Real Estate Agency Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6006,
                        'IsSynonym': false,
                        'Sequence': 2121,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Real Estate Agent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6007,
                        'IsSynonym': false,
                        'Sequence': 2122,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Real Estate Property Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6008,
                        'IsSynonym': false,
                        'Sequence': 2123,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Receiving and Despatching Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6009,
                        'IsSynonym': false,
                        'Sequence': 2124,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Receptionist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6010,
                        'IsSynonym': false,
                        'Sequence': 2125,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Receptionist, concierge'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6011,
                        'IsSynonym': false,
                        'Sequence': 2126,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Recreation Adviser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6012,
                        'IsSynonym': false,
                        'Sequence': 2127,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Recreation Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6013,
                        'IsSynonym': false,
                        'Sequence': 2128,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Recruitment Consultant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6014,
                        'IsSynonym': false,
                        'Sequence': 2129,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Recruitment Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6015,
                        'IsSynonym': false,
                        'Sequence': 2130,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Referee'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6016,
                        'IsSynonym': false,
                        'Sequence': 2131,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Reflexologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6017,
                        'IsSynonym': false,
                        'Sequence': 2132,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Regional Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6018,
                        'IsSynonym': false,
                        'Sequence': 2133,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Regional Planner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6019,
                        'IsSynonym': false,
                        'Sequence': 2134,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Registered Community Health Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6020,
                        'IsSynonym': false,
                        'Sequence': 2135,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Registered General Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6021,
                        'IsSynonym': false,
                        'Sequence': 2136,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Registered Midwife'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6022,
                        'IsSynonym': false,
                        'Sequence': 2137,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Registered Psychiatric Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6023,
                        'IsSynonym': false,
                        'Sequence': 2138,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Registry Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6024,
                        'IsSynonym': false,
                        'Sequence': 2139,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rehabilitation Counsellor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6025,
                        'IsSynonym': false,
                        'Sequence': 2140,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Relationship Counsellor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6026,
                        'IsSynonym': false,
                        'Sequence': 2141,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Religious Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6027,
                        'IsSynonym': false,
                        'Sequence': 2142,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Remedial Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6028,
                        'IsSynonym': false,
                        'Sequence': 2143,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Renal Specialist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6029,
                        'IsSynonym': false,
                        'Sequence': 2144,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rental Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6030,
                        'IsSynonym': false,
                        'Sequence': 2145,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Reporter (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6031,
                        'IsSynonym': false,
                        'Sequence': 2146,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Reporter (> 10% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6032,
                        'IsSynonym': false,
                        'Sequence': 2147,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Repossession Agent'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6033,
                        'IsSynonym': false,
                        'Sequence': 2148,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Re-recording Mixer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6034,
                        'IsSynonym': false,
                        'Sequence': 2149,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rescue Diver'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6035,
                        'IsSynonym': false,
                        'Sequence': 2150,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Research Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6036,
                        'IsSynonym': false,
                        'Sequence': 2151,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Research and Development Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6037,
                        'IsSynonym': false,
                        'Sequence': 2152,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Research Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6038,
                        'IsSynonym': false,
                        'Sequence': 2153,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Research Meteorologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6039,
                        'IsSynonym': false,
                        'Sequence': 2154,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Researcher (Admin only)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6040,
                        'IsSynonym': false,
                        'Sequence': 2155,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Researcher (Field work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6041,
                        'IsSynonym': false,
                        'Sequence': 2156,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Reservations Clerk'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6042,
                        'IsSynonym': false,
                        'Sequence': 2157,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Reservoir attendant, driller'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6043,
                        'IsSynonym': false,
                        'Sequence': 2158,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Resort Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6044,
                        'IsSynonym': false,
                        'Sequence': 2159,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Resource Teacher'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6045,
                        'IsSynonym': false,
                        'Sequence': 2160,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Restaurant and Catering Managing Supervisor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6046,
                        'IsSynonym': false,
                        'Sequence': 2161,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Restaurant Owner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6047,
                        'IsSynonym': false,
                        'Sequence': 2162,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Retail Merchandiser (< 20% travel and < 50% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6048,
                        'IsSynonym': false,
                        'Sequence': 2163,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Retail Merchandiser (> 20% travel or > 50% manual work)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6049,
                        'IsSynonym': false,
                        'Sequence': 2164,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Retired/pensioner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6050,
                        'IsSynonym': false,
                        'Sequence': 2165,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Reverend'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6051,
                        'IsSynonym': false,
                        'Sequence': 2166,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rig construction'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6052,
                        'IsSynonym': false,
                        'Sequence': 2167,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rig Electrician - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6053,
                        'IsSynonym': false,
                        'Sequence': 2168,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rig maintenance'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6054,
                        'IsSynonym': false,
                        'Sequence': 2169,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rig Mechanic - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6055,
                        'IsSynonym': false,
                        'Sequence': 2170,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rig medic'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6056,
                        'IsSynonym': false,
                        'Sequence': 2171,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rigger (Heights > 10m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6057,
                        'IsSynonym': false,
                        'Sequence': 2172,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rigger (Heights upto 10m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6058,
                        'IsSynonym': false,
                        'Sequence': 2173,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Riveter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6059,
                        'IsSynonym': false,
                        'Sequence': 2174,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Road Design Draftsperson'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6060,
                        'IsSynonym': false,
                        'Sequence': 2175,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Road Grader'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6061,
                        'IsSynonym': false,
                        'Sequence': 2176,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Road Maintenance Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6062,
                        'IsSynonym': false,
                        'Sequence': 2177,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roadworthy Inspector'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6063,
                        'IsSynonym': false,
                        'Sequence': 2178,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rock Driller, Building And Construction'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6064,
                        'IsSynonym': false,
                        'Sequence': 2179,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rolling Mill Assistant'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6065,
                        'IsSynonym': false,
                        'Sequence': 2180,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roofer (Heights > 10m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6066,
                        'IsSynonym': false,
                        'Sequence': 2181,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roofer (Heights up to 10m)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6067,
                        'IsSynonym': false,
                        'Sequence': 2182,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roofing Contractor (Heights > 10m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6068,
                        'IsSynonym': false,
                        'Sequence': 2183,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roofing Contractor (Heights up to 10m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6069,
                        'IsSynonym': false,
                        'Sequence': 2184,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roofing, Asphalt Layer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6070,
                        'IsSynonym': false,
                        'Sequence': 2185,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roughneck'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6071,
                        'IsSynonym': false,
                        'Sequence': 2186,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Roustabout'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6072,
                        'IsSynonym': false,
                        'Sequence': 2187,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rubber and Plastics Factory Hand'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6073,
                        'IsSynonym': false,
                        'Sequence': 2188,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rubber Production Machine Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6074,
                        'IsSynonym': false,
                        'Sequence': 2189,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Rugby - Semi Professional or Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6075,
                        'IsSynonym': false,
                        'Sequence': 2190,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sack and Bag Filler'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6076,
                        'IsSynonym': false,
                        'Sequence': 2191,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Safari Tour Operator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6077,
                        'IsSynonym': false,
                        'Sequence': 2192,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Safety Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6078,
                        'IsSynonym': false,
                        'Sequence': 2193,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Safety Officer - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6079,
                        'IsSynonym': false,
                        'Sequence': 2194,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Safety Officer (construction)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6080,
                        'IsSynonym': false,
                        'Sequence': 2195,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Safety Officer (park or reserve)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6081,
                        'IsSynonym': false,
                        'Sequence': 2196,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sailmaker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6082,
                        'IsSynonym': false,
                        'Sequence': 2197,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Analyst'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6083,
                        'IsSynonym': false,
                        'Sequence': 2198,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Assistant (< 10% travel)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6084,
                        'IsSynonym': false,
                        'Sequence': 2199,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Assistant (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6085,
                        'IsSynonym': false,
                        'Sequence': 2200,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Manager (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6086,
                        'IsSynonym': false,
                        'Sequence': 2201,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Manager (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6087,
                        'IsSynonym': false,
                        'Sequence': 2202,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Representative (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6088,
                        'IsSynonym': false,
                        'Sequence': 2203,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sales Representative (> 10% travel)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6089,
                        'IsSynonym': false,
                        'Sequence': 2204,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Salesperson (< 10% travel)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6090,
                        'IsSynonym': false,
                        'Sequence': 2205,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Salesperson (> 10% travel)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6091,
                        'IsSynonym': false,
                        'Sequence': 2206,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sandblaster'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6092,
                        'IsSynonym': false,
                        'Sequence': 2207,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sanitation Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6093,
                        'IsSynonym': false,
                        'Sequence': 2208,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Satellite Dish Installer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6094,
                        'IsSynonym': false,
                        'Sequence': 2209,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Saturation Diver'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6095,
                        'IsSynonym': false,
                        'Sequence': 2210,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scaffolder (Heights > 10m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6096,
                        'IsSynonym': false,
                        'Sequence': 2211,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scaffolder (Heights up to 10m)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6097,
                        'IsSynonym': false,
                        'Sequence': 2212,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Schedule Clerk'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6098,
                        'IsSynonym': false,
                        'Sequence': 2213,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'School Crossing Attendant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6099,
                        'IsSynonym': false,
                        'Sequence': 2214,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'School Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6100,
                        'IsSynonym': false,
                        'Sequence': 2215,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'School Matron'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6101,
                        'IsSynonym': false,
                        'Sequence': 2216,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'School Principal'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6102,
                        'IsSynonym': false,
                        'Sequence': 2217,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'School Psychologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6103,
                        'IsSynonym': false,
                        'Sequence': 2218,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'School Superintendent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6104,
                        'IsSynonym': false,
                        'Sequence': 2219,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scientist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6105,
                        'IsSynonym': false,
                        'Sequence': 2220,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scrap Dealer, Metalworkers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6106,
                        'IsSynonym': false,
                        'Sequence': 2221,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scrap Materials Buyer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6107,
                        'IsSynonym': false,
                        'Sequence': 2222,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Screen Printer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6108,
                        'IsSynonym': false,
                        'Sequence': 2223,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Screenwriter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6109,
                        'IsSynonym': false,
                        'Sequence': 2224,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scrip Clerk (Stockbroking)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6110,
                        'IsSynonym': false,
                        'Sequence': 2225,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Script Editor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6111,
                        'IsSynonym': false,
                        'Sequence': 2226,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Script Writer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6112,
                        'IsSynonym': false,
                        'Sequence': 2227,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Scuba Diving Instructor - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6113,
                        'IsSynonym': false,
                        'Sequence': 2228,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sculptor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6114,
                        'IsSynonym': false,
                        'Sequence': 2229,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Seamstress'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6115,
                        'IsSynonym': false,
                        'Sequence': 2230,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Secondary Metallurgist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6116,
                        'IsSynonym': false,
                        'Sequence': 2231,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Secondary School Teacher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6117,
                        'IsSynonym': false,
                        'Sequence': 2232,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Secretary'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6118,
                        'IsSynonym': false,
                        'Sequence': 2233,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Section Head (Public Service)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6119,
                        'IsSynonym': false,
                        'Sequence': 2234,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Securities Dealer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6120,
                        'IsSynonym': false,
                        'Sequence': 2235,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Security Door Installer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6121,
                        'IsSynonym': false,
                        'Sequence': 2236,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Security guard (Armed)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6122,
                        'IsSynonym': false,
                        'Sequence': 2237,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Security guard (Unarmed)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6123,
                        'IsSynonym': false,
                        'Sequence': 2238,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Security Systems/Alarms Installer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6124,
                        'IsSynonym': false,
                        'Sequence': 2239,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Seismic Prospecting Observer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6125,
                        'IsSynonym': false,
                        'Sequence': 2240,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Seismic Survey Assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6126,
                        'IsSynonym': false,
                        'Sequence': 2241,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Seismologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6127,
                        'IsSynonym': false,
                        'Sequence': 2242,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Service Counter Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6128,
                        'IsSynonym': false,
                        'Sequence': 2243,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Service Station Owner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6129,
                        'IsSynonym': false,
                        'Sequence': 2244,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Set Builder'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6130,
                        'IsSynonym': false,
                        'Sequence': 2245,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Set Designer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6131,
                        'IsSynonym': false,
                        'Sequence': 2246,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sewage Reticulation Drafting Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6132,
                        'IsSynonym': false,
                        'Sequence': 2247,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sewing Machine Mechanic'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6133,
                        'IsSynonym': false,
                        'Sequence': 2248,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sewing Machinist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6134,
                        'IsSynonym': false,
                        'Sequence': 2249,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shearer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6135,
                        'IsSynonym': false,
                        'Sequence': 2250,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sheetmetal Patternmaker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6136,
                        'IsSynonym': false,
                        'Sequence': 2251,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shelf stacker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6137,
                        'IsSynonym': false,
                        'Sequence': 2252,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sheriff of the Court'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6138,
                        'IsSynonym': false,
                        'Sequence': 2253,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sheriffs Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6139,
                        'IsSynonym': false,
                        'Sequence': 2254,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shift Foreman (No Underground)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6140,
                        'IsSynonym': false,
                        'Sequence': 2255,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shift Foreman (Underground)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6141,
                        'IsSynonym': false,
                        'Sequence': 2256,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ship Builder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6142,
                        'IsSynonym': false,
                        'Sequence': 2257,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ship Steward'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6143,
                        'IsSynonym': false,
                        'Sequence': 2258,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shipper Administrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6144,
                        'IsSynonym': false,
                        'Sequence': 2259,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shipping Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6145,
                        'IsSynonym': false,
                        'Sequence': 2260,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Captain/Master/Skipper'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6146,
                        'IsSynonym': false,
                        'Sequence': 2261,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Carpenter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6147,
                        'IsSynonym': false,
                        'Sequence': 2262,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Engineer Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6148,
                        'IsSynonym': false,
                        'Sequence': 2263,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Navigating Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6149,
                        'IsSynonym': false,
                        'Sequence': 2264,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6150,
                        'IsSynonym': false,
                        'Sequence': 2265,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Pilot'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6151,
                        'IsSynonym': false,
                        'Sequence': 2266,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ships Purser'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6152,
                        'IsSynonym': false,
                        'Sequence': 2267,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shoe Repairer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6153,
                        'IsSynonym': false,
                        'Sequence': 2268,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shop Assistant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6154,
                        'IsSynonym': false,
                        'Sequence': 2269,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shop Cleaner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6155,
                        'IsSynonym': false,
                        'Sequence': 2270,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shop Fitter'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6156,
                        'IsSynonym': false,
                        'Sequence': 2271,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shop Manager (< 20% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6157,
                        'IsSynonym': false,
                        'Sequence': 2272,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shop Manager (> 20% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6158,
                        'IsSynonym': false,
                        'Sequence': 2273,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shopkeeper'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6159,
                        'IsSynonym': false,
                        'Sequence': 2274,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shorthand Typist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6160,
                        'IsSynonym': false,
                        'Sequence': 2275,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Shower Screen Installer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6161,
                        'IsSynonym': false,
                        'Sequence': 2276,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Signal Man, Signaller'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6162,
                        'IsSynonym': false,
                        'Sequence': 2277,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Signwriter, Ground Level'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6163,
                        'IsSynonym': false,
                        'Sequence': 2278,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Signwriter, working at heights'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6164,
                        'IsSynonym': false,
                        'Sequence': 2279,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Signwriters Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6165,
                        'IsSynonym': false,
                        'Sequence': 2280,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Silver Recovery Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6166,
                        'IsSynonym': false,
                        'Sequence': 2281,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Silversmith'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6167,
                        'IsSynonym': false,
                        'Sequence': 2282,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Singer - All Genres'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6168,
                        'IsSynonym': false,
                        'Sequence': 2283,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Singer - Theatre'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6169,
                        'IsSynonym': false,
                        'Sequence': 2284,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Singing Teacher (Private)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6170,
                        'IsSynonym': false,
                        'Sequence': 2285,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Slater (heights > 10m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6171,
                        'IsSynonym': false,
                        'Sequence': 2286,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Slater (heights up to 10m)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6172,
                        'IsSynonym': false,
                        'Sequence': 2287,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Small Engine Mechanic'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6173,
                        'IsSynonym': false,
                        'Sequence': 2288,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Smelting Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6174,
                        'IsSynonym': false,
                        'Sequence': 2289,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Smelting Plant Operator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6175,
                        'IsSynonym': false,
                        'Sequence': 2290,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Snow Skiing - Professional or Regular competition'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6176,
                        'IsSynonym': false,
                        'Sequence': 2291,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soap Maker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6177,
                        'IsSynonym': false,
                        'Sequence': 2292,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soccer - Semi Professional or Professional'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6178,
                        'IsSynonym': false,
                        'Sequence': 2293,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Social Planner and Administrator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6179,
                        'IsSynonym': false,
                        'Sequence': 2294,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Social Worker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6180,
                        'IsSynonym': false,
                        'Sequence': 2295,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Software Developer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6181,
                        'IsSynonym': false,
                        'Sequence': 2296,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Software Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6182,
                        'IsSynonym': false,
                        'Sequence': 2297,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Software Programmer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6183,
                        'IsSynonym': false,
                        'Sequence': 2298,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soil Biologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6184,
                        'IsSynonym': false,
                        'Sequence': 2299,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soil Conservationist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6185,
                        'IsSynonym': false,
                        'Sequence': 2300,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soil Science Technician'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6186,
                        'IsSynonym': false,
                        'Sequence': 2301,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soil Zoologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6187,
                        'IsSynonym': false,
                        'Sequence': 2302,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Soils Science Technical Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6188,
                        'IsSynonym': false,
                        'Sequence': 2303,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Songwriter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6189,
                        'IsSynonym': false,
                        'Sequence': 2304,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sorter, Post Office Workers'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6190,
                        'IsSynonym': false,
                        'Sequence': 2305,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sound Editor'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6191,
                        'IsSynonym': false,
                        'Sequence': 2306,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sound Effects Person'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6192,
                        'IsSynonym': false,
                        'Sequence': 2307,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sound Engineer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6193,
                        'IsSynonym': false,
                        'Sequence': 2308,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sound Mixer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6194,
                        'IsSynonym': false,
                        'Sequence': 2309,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sound Recordist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6195,
                        'IsSynonym': false,
                        'Sequence': 2310,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sound Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6196,
                        'IsSynonym': false,
                        'Sequence': 2311,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Special Effects Person'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6197,
                        'IsSynonym': false,
                        'Sequence': 2312,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Special task team'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6198,
                        'IsSynonym': false,
                        'Sequence': 2313,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Special Teaching Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6199,
                        'IsSynonym': false,
                        'Sequence': 2314,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Spectroscopic Technical Officer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6200,
                        'IsSynonym': false,
                        'Sequence': 2315,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Speech and Hearing Therapist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6201,
                        'IsSynonym': false,
                        'Sequence': 2316,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Speech Therapist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6202,
                        'IsSynonym': false,
                        'Sequence': 2317,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Spinal Injuries Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6203,
                        'IsSynonym': false,
                        'Sequence': 2318,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Spinal Injuries Rehabilitation Specialist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6204,
                        'IsSynonym': false,
                        'Sequence': 2319,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Spinner (textiles)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6205,
                        'IsSynonym': false,
                        'Sequence': 2320,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sporting Facility Curator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6206,
                        'IsSynonym': false,
                        'Sequence': 2321,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Administrator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6207,
                        'IsSynonym': false,
                        'Sequence': 2322,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Centre Administrator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6208,
                        'IsSynonym': false,
                        'Sequence': 2323,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Centre Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6209,
                        'IsSynonym': false,
                        'Sequence': 2324,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Coach'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6210,
                        'IsSynonym': false,
                        'Sequence': 2325,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Instructor/Trainer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6211,
                        'IsSynonym': false,
                        'Sequence': 2326,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6212,
                        'IsSynonym': false,
                        'Sequence': 2327,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Official (< 10% manual work)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6213,
                        'IsSynonym': false,
                        'Sequence': 2328,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Official (> 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6214,
                        'IsSynonym': false,
                        'Sequence': 2329,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Organiser'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6215,
                        'IsSynonym': false,
                        'Sequence': 2330,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Psychologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6216,
                        'IsSynonym': false,
                        'Sequence': 2331,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sports Teacher'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6217,
                        'IsSynonym': false,
                        'Sequence': 2332,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sprayer, Motor Trade'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6218,
                        'IsSynonym': false,
                        'Sequence': 2333,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Spraypainter'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6219,
                        'IsSynonym': false,
                        'Sequence': 2334,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sprinkler Fitter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6220,
                        'IsSynonym': false,
                        'Sequence': 2335,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Squash - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6221,
                        'IsSynonym': false,
                        'Sequence': 2336,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stable Hand'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6222,
                        'IsSynonym': false,
                        'Sequence': 2337,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stable Lad'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6223,
                        'IsSynonym': false,
                        'Sequence': 2338,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stable Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6224,
                        'IsSynonym': false,
                        'Sequence': 2339,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stage Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6225,
                        'IsSynonym': false,
                        'Sequence': 2340,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stage Director'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6226,
                        'IsSynonym': false,
                        'Sequence': 2341,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stage Manager'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6227,
                        'IsSynonym': false,
                        'Sequence': 2342,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stagehand'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6228,
                        'IsSynonym': false,
                        'Sequence': 2343,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Station Hand'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6229,
                        'IsSynonym': false,
                        'Sequence': 2344,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Station Manager, Railways'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6230,
                        'IsSynonym': false,
                        'Sequence': 2345,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Station Master'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6231,
                        'IsSynonym': false,
                        'Sequence': 2346,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Station superintendant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6232,
                        'IsSynonym': false,
                        'Sequence': 2347,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Statistical Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6233,
                        'IsSynonym': false,
                        'Sequence': 2348,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Statistician'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6234,
                        'IsSynonym': false,
                        'Sequence': 2349,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Steel Erector (Heights > 10m)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6235,
                        'IsSynonym': false,
                        'Sequence': 2350,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Steel Erector (Heights up to 10m)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6236,
                        'IsSynonym': false,
                        'Sequence': 2351,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Steel Merchant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6237,
                        'IsSynonym': false,
                        'Sequence': 2352,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Steel Worker'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6238,
                        'IsSynonym': false,
                        'Sequence': 2353,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Steeplejack'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6239,
                        'IsSynonym': false,
                        'Sequence': 2354,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stevedore'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6240,
                        'IsSynonym': false,
                        'Sequence': 2355,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stock Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6241,
                        'IsSynonym': false,
                        'Sequence': 2356,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stock Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6242,
                        'IsSynonym': false,
                        'Sequence': 2357,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stock Controller'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6243,
                        'IsSynonym': false,
                        'Sequence': 2358,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stock Exchange Dealer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6244,
                        'IsSynonym': false,
                        'Sequence': 2359,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stockbroking Firm Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6245,
                        'IsSynonym': false,
                        'Sequence': 2360,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stockman, Agriculture'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6246,
                        'IsSynonym': false,
                        'Sequence': 2361,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stoker - Non-underground'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6247,
                        'IsSynonym': false,
                        'Sequence': 2362,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stoker - Underground'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6248,
                        'IsSynonym': false,
                        'Sequence': 2363,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stonemason'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6249,
                        'IsSynonym': false,
                        'Sequence': 2364,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stonemasonry Machinist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6250,
                        'IsSynonym': false,
                        'Sequence': 2365,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Storekeeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6251,
                        'IsSynonym': false,
                        'Sequence': 2366,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Storeman/woman'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6252,
                        'IsSynonym': false,
                        'Sequence': 2367,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stores Clerk'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6253,
                        'IsSynonym': false,
                        'Sequence': 2368,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stores Despatch Hand'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6254,
                        'IsSynonym': false,
                        'Sequence': 2369,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Street Cleaner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6255,
                        'IsSynonym': false,
                        'Sequence': 2370,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Street entertainer/busker'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 6256,
                        'IsSynonym': false,
                        'Sequence': 2371,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Street Vendor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6257,
                        'IsSynonym': false,
                        'Sequence': 2372,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stringed Instrument Repairer'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 6258,
                        'IsSynonym': false,
                        'Sequence': 2373,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Striptease Artist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6259,
                        'IsSynonym': false,
                        'Sequence': 2374,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Structural Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6260,
                        'IsSynonym': false,
                        'Sequence': 2375,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stud Hand'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6261,
                        'IsSynonym': false,
                        'Sequence': 2376,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stud Master/Mistress'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6262,
                        'IsSynonym': false,
                        'Sequence': 2377,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student 1st Year'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6263,
                        'IsSynonym': false,
                        'Sequence': 2378,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student 2nd or 3rd Year'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6264,
                        'IsSynonym': false,
                        'Sequence': 2379,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student 4th Year'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6265,
                        'IsSynonym': false,
                        'Sequence': 2380,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student Counsellor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6266,
                        'IsSynonym': false,
                        'Sequence': 2381,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student Enrolled Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6267,
                        'IsSynonym': false,
                        'Sequence': 2382,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student higher than 4th Year'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6268,
                        'IsSynonym': false,
                        'Sequence': 2383,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student Minister'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6269,
                        'IsSynonym': false,
                        'Sequence': 2384,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student Registered Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6270,
                        'IsSynonym': false,
                        'Sequence': 2385,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Student Registered Psychiatric Nurse'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6271,
                        'IsSynonym': false,
                        'Sequence': 2386,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Studio Hand'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6272,
                        'IsSynonym': false,
                        'Sequence': 2387,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Studio Manager'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6273,
                        'IsSynonym': false,
                        'Sequence': 2388,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Stuntman/woman'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6274,
                        'IsSynonym': false,
                        'Sequence': 2389,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sub-editor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6275,
                        'IsSynonym': false,
                        'Sequence': 2390,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Submariner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6276,
                        'IsSynonym': false,
                        'Sequence': 2391,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sugar Cane Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6277,
                        'IsSynonym': false,
                        'Sequence': 2392,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supermarket Manager'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6278,
                        'IsSynonym': false,
                        'Sequence': 2393,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor of messengers and delivery officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6279,
                        'IsSynonym': false,
                        'Sequence': 2394,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Atomic Energy'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6280,
                        'IsSynonym': false,
                        'Sequence': 2395,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Automotive Electricians'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6281,
                        'IsSynonym': false,
                        'Sequence': 2396,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Basic Metal Products Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6282,
                        'IsSynonym': false,
                        'Sequence': 2397,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Battery Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6283,
                        'IsSynonym': false,
                        'Sequence': 2398,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Bicycle Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6284,
                        'IsSynonym': false,
                        'Sequence': 2399,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Binders and Finishers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6285,
                        'IsSynonym': false,
                        'Sequence': 2400,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Brassfoundry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6286,
                        'IsSynonym': false,
                        'Sequence': 2401,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Brick Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6287,
                        'IsSynonym': false,
                        'Sequence': 2402,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Bricklayers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6288,
                        'IsSynonym': false,
                        'Sequence': 2403,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Bulk Materials Handling Plant Operators'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6289,
                        'IsSynonym': false,
                        'Sequence': 2404,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Business Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6290,
                        'IsSynonym': false,
                        'Sequence': 2405,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Cabinetmakers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6291,
                        'IsSynonym': false,
                        'Sequence': 2406,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Carpenters and Joiners'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6292,
                        'IsSynonym': false,
                        'Sequence': 2407,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Cashiers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6293,
                        'IsSynonym': false,
                        'Sequence': 2408,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Cement Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6294,
                        'IsSynonym': false,
                        'Sequence': 2409,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Chemical Plant Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6295,
                        'IsSynonym': false,
                        'Sequence': 2410,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Chemical Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6296,
                        'IsSynonym': false,
                        'Sequence': 2411,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Clay and Stone Processing Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6297,
                        'IsSynonym': false,
                        'Sequence': 2412,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Cleaners'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6298,
                        'IsSynonym': false,
                        'Sequence': 2413,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Communications Equipment Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6299,
                        'IsSynonym': false,
                        'Sequence': 2414,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Concrete Products Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6300,
                        'IsSynonym': false,
                        'Sequence': 2415,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Cooks/Bakers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6301,
                        'IsSynonym': false,
                        'Sequence': 2416,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Cotton Ginning Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6302,
                        'IsSynonym': false,
                        'Sequence': 2417,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Craftworkers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6303,
                        'IsSynonym': false,
                        'Sequence': 2418,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Drainers'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6304,
                        'IsSynonym': false,
                        'Sequence': 2419,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Dressmakers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6305,
                        'IsSynonym': false,
                        'Sequence': 2420,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Drilling Plant Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6306,
                        'IsSynonym': false,
                        'Sequence': 2421,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Electrical Fitters'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6307,
                        'IsSynonym': false,
                        'Sequence': 2422,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Electrical Mechanics'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6308,
                        'IsSynonym': false,
                        'Sequence': 2423,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Electrical Powerline Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6309,
                        'IsSynonym': false,
                        'Sequence': 2424,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Excavating and Earthmoving Plant Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6310,
                        'IsSynonym': false,
                        'Sequence': 2425,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Fabric Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6311,
                        'IsSynonym': false,
                        'Sequence': 2426,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Farm Hands and Assistants'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6312,
                        'IsSynonym': false,
                        'Sequence': 2427,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Fibre Cement Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6313,
                        'IsSynonym': false,
                        'Sequence': 2428,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Fibreglass Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6314,
                        'IsSynonym': false,
                        'Sequence': 2429,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Floor Coverers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6315,
                        'IsSynonym': false,
                        'Sequence': 2430,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Food Processing Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6316,
                        'IsSynonym': false,
                        'Sequence': 2431,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Forestry Labourers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6317,
                        'IsSynonym': false,
                        'Sequence': 2432,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Forging Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6318,
                        'IsSynonym': false,
                        'Sequence': 2433,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Furniture Finishers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6319,
                        'IsSynonym': false,
                        'Sequence': 2434,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Garbage Collectors'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6320,
                        'IsSynonym': false,
                        'Sequence': 2435,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Garment Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6321,
                        'IsSynonym': false,
                        'Sequence': 2436,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Glass Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6322,
                        'IsSynonym': false,
                        'Sequence': 2437,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Glass Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6323,
                        'IsSynonym': false,
                        'Sequence': 2438,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Glue Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6324,
                        'IsSynonym': false,
                        'Sequence': 2439,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, graphite and carbon Products'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6325,
                        'IsSynonym': false,
                        'Sequence': 2440,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Hide and Skin Processing Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6326,
                        'IsSynonym': false,
                        'Sequence': 2441,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Hoist, Winch and Lift Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6327,
                        'IsSynonym': false,
                        'Sequence': 2442,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Insecticide Manufacture'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6328,
                        'IsSynonym': false,
                        'Sequence': 2443,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Inspectors and Regulatory Officers'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6329,
                        'IsSynonym': false,
                        'Sequence': 2444,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Landscape Gardeners'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6330,
                        'IsSynonym': false,
                        'Sequence': 2445,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Laundry Workers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6331,
                        'IsSynonym': false,
                        'Sequence': 2446,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Leather and Canvas Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6332,
                        'IsSynonym': false,
                        'Sequence': 2447,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Lift Mechanics'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6333,
                        'IsSynonym': false,
                        'Sequence': 2448,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Locomotive Drivers'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6334,
                        'IsSynonym': false,
                        'Sequence': 2449,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Mail Sorters'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6335,
                        'IsSynonym': false,
                        'Sequence': 2450,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Metal Casting Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6336,
                        'IsSynonym': false,
                        'Sequence': 2451,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Metal Finishing Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6337,
                        'IsSynonym': false,
                        'Sequence': 2452,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Metal Fitters and Machinists'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6338,
                        'IsSynonym': false,
                        'Sequence': 2453,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Metal Spinners'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6339,
                        'IsSynonym': false,
                        'Sequence': 2454,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Metalworkers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6340,
                        'IsSynonym': false,
                        'Sequence': 2455,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Motor Trade'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6341,
                        'IsSynonym': false,
                        'Sequence': 2456,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Musical Instrument Makers and Repairers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6342,
                        'IsSynonym': false,
                        'Sequence': 2457,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Nurserymen/women'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6343,
                        'IsSynonym': false,
                        'Sequence': 2458,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Office Equipment and Computer Servicers'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6344,
                        'IsSynonym': false,
                        'Sequence': 2459,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Oil and Gas Well Treatment Operators'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6345,
                        'IsSynonym': false,
                        'Sequence': 2460,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Oil, Gas and Pipe Testing Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6346,
                        'IsSynonym': false,
                        'Sequence': 2461,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Other Electrical and Electronics Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6347,
                        'IsSynonym': false,
                        'Sequence': 2462,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Other Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6348,
                        'IsSynonym': false,
                        'Sequence': 2463,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Other Stationary Plant Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6349,
                        'IsSynonym': false,
                        'Sequence': 2464,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Other Trades Assistants and Factory Hands'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6350,
                        'IsSynonym': false,
                        'Sequence': 2465,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Other Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6351,
                        'IsSynonym': false,
                        'Sequence': 2466,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Other Wood Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6352,
                        'IsSynonym': false,
                        'Sequence': 2467,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Packaging Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6353,
                        'IsSynonym': false,
                        'Sequence': 2468,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Panel Beaters'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6354,
                        'IsSynonym': false,
                        'Sequence': 2469,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Paper and Paper Products Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6355,
                        'IsSynonym': false,
                        'Sequence': 2470,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Paving and Surfacing Labourers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6356,
                        'IsSynonym': false,
                        'Sequence': 2471,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Paving and Surfacing Plant Operators'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6357,
                        'IsSynonym': false,
                        'Sequence': 2472,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Payroll Clerks'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6358,
                        'IsSynonym': false,
                        'Sequence': 2473,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Performing Arts Support Workers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6359,
                        'IsSynonym': false,
                        'Sequence': 2474,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Pharmaceuticals and Toiletries Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6360,
                        'IsSynonym': false,
                        'Sequence': 2475,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Photographic Products Machine Operators'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6361,
                        'IsSynonym': false,
                        'Sequence': 2476,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Picture Framers'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6362,
                        'IsSynonym': false,
                        'Sequence': 2477,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Plant Nursery'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6363,
                        'IsSynonym': false,
                        'Sequence': 2478,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Plasterers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6364,
                        'IsSynonym': false,
                        'Sequence': 2479,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Plastics Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6365,
                        'IsSynonym': false,
                        'Sequence': 2480,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Plumbers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6366,
                        'IsSynonym': false,
                        'Sequence': 2481,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Plywood Making Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6367,
                        'IsSynonym': false,
                        'Sequence': 2482,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Power Generation Plant Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6368,
                        'IsSynonym': false,
                        'Sequence': 2483,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Precision Metal Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6369,
                        'IsSynonym': false,
                        'Sequence': 2484,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Printing Machinists'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6370,
                        'IsSynonym': false,
                        'Sequence': 2485,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Production Recording Clerks'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6371,
                        'IsSynonym': false,
                        'Sequence': 2486,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Radio and Television Servicers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6372,
                        'IsSynonym': false,
                        'Sequence': 2487,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Railway Labourers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6373,
                        'IsSynonym': false,
                        'Sequence': 2488,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Refrigeration and Air-Conditioning Mechanics'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6374,
                        'IsSynonym': false,
                        'Sequence': 2489,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Roofer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6375,
                        'IsSynonym': false,
                        'Sequence': 2490,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Rubber Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6376,
                        'IsSynonym': false,
                        'Sequence': 2491,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Saw Operators'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6377,
                        'IsSynonym': false,
                        'Sequence': 2492,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Security'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6378,
                        'IsSynonym': false,
                        'Sequence': 2493,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Sheetmetal Tradespersons'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6379,
                        'IsSynonym': false,
                        'Sequence': 2494,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Shoemaking and Repairing Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6380,
                        'IsSynonym': false,
                        'Sequence': 2495,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Smallgoods Makers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6381,
                        'IsSynonym': false,
                        'Sequence': 2496,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Smelting Plant Operators'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6382,
                        'IsSynonym': false,
                        'Sequence': 2497,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Sound Recording and Reproduction Equipment operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6383,
                        'IsSynonym': false,
                        'Sequence': 2498,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Stock and Purchasing Clerks'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6384,
                        'IsSynonym': false,
                        'Sequence': 2499,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Storemen/women'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6385,
                        'IsSynonym': false,
                        'Sequence': 2500,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Tailors'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6386,
                        'IsSynonym': false,
                        'Sequence': 2501,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Tellers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6387,
                        'IsSynonym': false,
                        'Sequence': 2502,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Textile Sewing Machinists'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6388,
                        'IsSynonym': false,
                        'Sequence': 2503,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Textile Workers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6389,
                        'IsSynonym': false,
                        'Sequence': 2504,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Tile Manufacture'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6390,
                        'IsSynonym': false,
                        'Sequence': 2505,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Tobacco Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6391,
                        'IsSynonym': false,
                        'Sequence': 2506,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Tool Room'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6392,
                        'IsSynonym': false,
                        'Sequence': 2507,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Toolmakers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6393,
                        'IsSynonym': false,
                        'Sequence': 2508,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Upholsterers and Bedding Tradespersons'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6394,
                        'IsSynonym': false,
                        'Sequence': 2509,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Vehicle Accessories Fitters'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6395,
                        'IsSynonym': false,
                        'Sequence': 2510,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Vehicle Body Makers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6396,
                        'IsSynonym': false,
                        'Sequence': 2511,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Vehicle Mechanics'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6397,
                        'IsSynonym': false,
                        'Sequence': 2512,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Vehicle Painters'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6398,
                        'IsSynonym': false,
                        'Sequence': 2513,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Vehicle Trimmers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6399,
                        'IsSynonym': false,
                        'Sequence': 2514,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Wood Carvers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6400,
                        'IsSynonym': false,
                        'Sequence': 2515,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Wood Machinists and Turners'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6401,
                        'IsSynonym': false,
                        'Sequence': 2516,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Wood Processing Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6402,
                        'IsSynonym': false,
                        'Sequence': 2517,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisor, Yarn Production Machine Operators'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6403,
                        'IsSynonym': false,
                        'Sequence': 2518,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisors, Timber Workers'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6404,
                        'IsSynonym': false,
                        'Sequence': 2519,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisory Only, Motor Car/Motor cycle Manufacturing Industry'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6405,
                        'IsSynonym': false,
                        'Sequence': 2520,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supervisory/Tradesman/Travel'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6406,
                        'IsSynonym': false,
                        'Sequence': 2521,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supply and Distribution Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6407,
                        'IsSynonym': false,
                        'Sequence': 2522,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supply Clerk'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6408,
                        'IsSynonym': false,
                        'Sequence': 2523,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Supply Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6409,
                        'IsSynonym': false,
                        'Sequence': 2524,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surfing - Professional'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6410,
                        'IsSynonym': false,
                        'Sequence': 2525,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surgeon'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6411,
                        'IsSynonym': false,
                        'Sequence': 2526,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surgical Oncologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6412,
                        'IsSynonym': false,
                        'Sequence': 2527,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Survey Hand/Interviewer/Recorder'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6413,
                        'IsSynonym': false,
                        'Sequence': 2528,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surveying Associate'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6414,
                        'IsSynonym': false,
                        'Sequence': 2529,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surveyor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6415,
                        'IsSynonym': false,
                        'Sequence': 2530,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surveyor - Oil and Natural Gas Industry'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6416,
                        'IsSynonym': false,
                        'Sequence': 2531,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surveyor, inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6417,
                        'IsSynonym': false,
                        'Sequence': 2532,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surveyor, Railway Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6418,
                        'IsSynonym': false,
                        'Sequence': 2533,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Surveyors Assistant'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6419,
                        'IsSynonym': false,
                        'Sequence': 2534,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sweeper (No Mining or Quarrying)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6420,
                        'IsSynonym': false,
                        'Sequence': 2535,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Swimming Instructor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6421,
                        'IsSynonym': false,
                        'Sequence': 2536,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Switchboard Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6422,
                        'IsSynonym': false,
                        'Sequence': 2537,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Sylviculturist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6423,
                        'IsSynonym': false,
                        'Sequence': 2538,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'System Analyst'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6424,
                        'IsSynonym': false,
                        'Sequence': 2539,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Systems Manager (Data Processing)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6425,
                        'IsSynonym': false,
                        'Sequence': 2540,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Systems Programmer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6426,
                        'IsSynonym': false,
                        'Sequence': 2541,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tailings Dam Labourer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6427,
                        'IsSynonym': false,
                        'Sequence': 2542,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tailor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6428,
                        'IsSynonym': false,
                        'Sequence': 2543,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tank Farm Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6429,
                        'IsSynonym': false,
                        'Sequence': 2544,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tannery Hand'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6430,
                        'IsSynonym': false,
                        'Sequence': 2545,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tap Dancer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6431,
                        'IsSynonym': false,
                        'Sequence': 2546,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tavern/Shebeen Manager (Licensed)'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 6432,
                        'IsSynonym': false,
                        'Sequence': 2547,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tavern/Shebeen Manager (Unlicensed)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6433,
                        'IsSynonym': false,
                        'Sequence': 2548,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tavern/Shebeen Owner (Licensed)'
                    },
                    {
                        'Description': 'Class 6',
                        'FloatingIndex': false,
                        'Id': 6434,
                        'IsSynonym': false,
                        'Sequence': 2549,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tavern/Shebeen Owner (Unlicensed)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6435,
                        'IsSynonym': false,
                        'Sequence': 2550,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tax Agent'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6436,
                        'IsSynonym': false,
                        'Sequence': 2551,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tax Consultant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6437,
                        'IsSynonym': false,
                        'Sequence': 2552,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tax, Inspector'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6438,
                        'IsSynonym': false,
                        'Sequence': 2553,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Taxi Owner (not minibus; no driving)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6439,
                        'IsSynonym': false,
                        'Sequence': 2554,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Taxidermist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6440,
                        'IsSynonym': false,
                        'Sequence': 2555,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tea Lady/Man'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6441,
                        'IsSynonym': false,
                        'Sequence': 2556,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teacher'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6442,
                        'IsSynonym': false,
                        'Sequence': 2557,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teacher of Disabled or Gifted Students'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6443,
                        'IsSynonym': false,
                        'Sequence': 2558,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teacher of Multi-cultural Studies'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6444,
                        'IsSynonym': false,
                        'Sequence': 2559,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teacher of the Hearing Impaired'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6445,
                        'IsSynonym': false,
                        'Sequence': 2560,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teacher of the Intellectually Disabled'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6446,
                        'IsSynonym': false,
                        'Sequence': 2561,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teacher of the Visually Impaired'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6447,
                        'IsSynonym': false,
                        'Sequence': 2562,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teachers Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6448,
                        'IsSynonym': false,
                        'Sequence': 2563,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Technical Director'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6449,
                        'IsSynonym': false,
                        'Sequence': 2564,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Technical Producer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6450,
                        'IsSynonym': false,
                        'Sequence': 2565,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Technician, Cinema/Theatre/Radio'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6451,
                        'IsSynonym': false,
                        'Sequence': 2566,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tele Canvasser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6452,
                        'IsSynonym': false,
                        'Sequence': 2567,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Telecine Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6453,
                        'IsSynonym': false,
                        'Sequence': 2568,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Telecommunications Analyst'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6454,
                        'IsSynonym': false,
                        'Sequence': 2569,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Telecommunications Technician'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6455,
                        'IsSynonym': false,
                        'Sequence': 2570,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Telephonist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6456,
                        'IsSynonym': false,
                        'Sequence': 2571,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Television Equipment Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6457,
                        'IsSynonym': false,
                        'Sequence': 2572,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Television License Inspector'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6458,
                        'IsSynonym': false,
                        'Sequence': 2573,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Television Mechanic'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6459,
                        'IsSynonym': false,
                        'Sequence': 2574,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Teller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6460,
                        'IsSynonym': false,
                        'Sequence': 2575,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tennis Player - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6461,
                        'IsSynonym': false,
                        'Sequence': 2576,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Textile Designer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6462,
                        'IsSynonym': false,
                        'Sequence': 2577,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Textile Examiner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6463,
                        'IsSynonym': false,
                        'Sequence': 2578,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Textiles Technical Officer'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6464,
                        'IsSynonym': false,
                        'Sequence': 2579,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Thatcher'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6465,
                        'IsSynonym': false,
                        'Sequence': 2580,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Theatre Attendant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6466,
                        'IsSynonym': false,
                        'Sequence': 2581,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Theatre,Cinema Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6467,
                        'IsSynonym': false,
                        'Sequence': 2582,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Theologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6468,
                        'IsSynonym': false,
                        'Sequence': 2583,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ticket Collector/examiner/taker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6469,
                        'IsSynonym': false,
                        'Sequence': 2584,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ticket Seller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6470,
                        'IsSynonym': false,
                        'Sequence': 2585,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tiler'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6471,
                        'IsSynonym': false,
                        'Sequence': 2586,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Timber Merchant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6472,
                        'IsSynonym': false,
                        'Sequence': 2587,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Timber Stacker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6473,
                        'IsSynonym': false,
                        'Sequence': 2588,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Timber Stock Cutter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6474,
                        'IsSynonym': false,
                        'Sequence': 2589,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Timber Treatment Plant Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6475,
                        'IsSynonym': false,
                        'Sequence': 2590,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Title Searcher'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6476,
                        'IsSynonym': false,
                        'Sequence': 2591,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tobacco Dryer Attendant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6477,
                        'IsSynonym': false,
                        'Sequence': 2592,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tobacco Farm Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6478,
                        'IsSynonym': false,
                        'Sequence': 2593,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tobacco Machine Attendant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6479,
                        'IsSynonym': false,
                        'Sequence': 2594,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tobacco Technical Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6480,
                        'IsSynonym': false,
                        'Sequence': 2595,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tobacconist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6481,
                        'IsSynonym': false,
                        'Sequence': 2596,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Toolmaker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6482,
                        'IsSynonym': false,
                        'Sequence': 2597,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Topographic Drafting Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6483,
                        'IsSynonym': false,
                        'Sequence': 2598,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tour Guide'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6484,
                        'IsSynonym': false,
                        'Sequence': 2599,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tourist Agent/Travel Agent'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6485,
                        'IsSynonym': false,
                        'Sequence': 2600,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tower Crane Operator'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6486,
                        'IsSynonym': false,
                        'Sequence': 2601,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Town Planner'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6487,
                        'IsSynonym': false,
                        'Sequence': 2602,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Toxicologist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6488,
                        'IsSynonym': false,
                        'Sequence': 2603,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Track Worker, Railway Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6489,
                        'IsSynonym': false,
                        'Sequence': 2604,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trade Union Official'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6490,
                        'IsSynonym': false,
                        'Sequence': 2605,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trade Union Organiser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6491,
                        'IsSynonym': false,
                        'Sequence': 2606,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trade Union Secretary'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6492,
                        'IsSynonym': false,
                        'Sequence': 2607,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trading Floor Operator (Stock Exchange)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6493,
                        'IsSynonym': false,
                        'Sequence': 2608,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trading support'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6494,
                        'IsSynonym': false,
                        'Sequence': 2609,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Traditional Healer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6495,
                        'IsSynonym': false,
                        'Sequence': 2610,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Traffic Police Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6496,
                        'IsSynonym': false,
                        'Sequence': 2611,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trail Running - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6497,
                        'IsSynonym': false,
                        'Sequence': 2612,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Train Conductor'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6498,
                        'IsSynonym': false,
                        'Sequence': 2613,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Train Examiner'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6499,
                        'IsSynonym': false,
                        'Sequence': 2614,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Train Loader Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6500,
                        'IsSynonym': false,
                        'Sequence': 2615,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Train Steward'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6501,
                        'IsSynonym': false,
                        'Sequence': 2616,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Accountant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6502,
                        'IsSynonym': false,
                        'Sequence': 2617,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Air Traffic Controller'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6503,
                        'IsSynonym': false,
                        'Sequence': 2618,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Farm Manager'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6504,
                        'IsSynonym': false,
                        'Sequence': 2619,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Farmer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6505,
                        'IsSynonym': false,
                        'Sequence': 2620,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Flight Service Officer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6506,
                        'IsSynonym': false,
                        'Sequence': 2621,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Marine Engineer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6507,
                        'IsSynonym': false,
                        'Sequence': 2622,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trainee Office Equipment and Computer Servicer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6508,
                        'IsSynonym': false,
                        'Sequence': 2623,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Training Manager'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6509,
                        'IsSynonym': false,
                        'Sequence': 2624,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Training Officer - Non-Mining (< 10% travel and < 10% manual work)'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6510,
                        'IsSynonym': false,
                        'Sequence': 2625,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Training Officer - Non-Mining (> 10% travel or > 10% manual work)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6511,
                        'IsSynonym': false,
                        'Sequence': 2626,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Translator'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6512,
                        'IsSynonym': false,
                        'Sequence': 2627,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Transport Broker'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6513,
                        'IsSynonym': false,
                        'Sequence': 2628,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Transport Economist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6514,
                        'IsSynonym': false,
                        'Sequence': 2629,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trapeze Artist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6515,
                        'IsSynonym': false,
                        'Sequence': 2630,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trauma Doctor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6516,
                        'IsSynonym': false,
                        'Sequence': 2631,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Travel Agent/Consultant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6517,
                        'IsSynonym': false,
                        'Sequence': 2632,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Travel, Courier'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6518,
                        'IsSynonym': false,
                        'Sequence': 2633,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Travelling Nurse'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6519,
                        'IsSynonym': false,
                        'Sequence': 2634,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Treasurer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6520,
                        'IsSynonym': false,
                        'Sequence': 2635,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tree Doctor'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6521,
                        'IsSynonym': false,
                        'Sequence': 2636,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tree Feller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6522,
                        'IsSynonym': false,
                        'Sequence': 2637,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tree Planter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6523,
                        'IsSynonym': false,
                        'Sequence': 2638,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tree Surgeon'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6524,
                        'IsSynonym': false,
                        'Sequence': 2639,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Truck despatcher'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6525,
                        'IsSynonym': false,
                        'Sequence': 2640,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Trust Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6526,
                        'IsSynonym': false,
                        'Sequence': 2641,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tug Boat Captain/Master'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6527,
                        'IsSynonym': false,
                        'Sequence': 2642,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tunnel Worker (Non-Mining)'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6528,
                        'IsSynonym': false,
                        'Sequence': 2643,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Turnstile Attendant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6529,
                        'IsSynonym': false,
                        'Sequence': 2644,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Typist'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6530,
                        'IsSynonym': false,
                        'Sequence': 2645,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Tyre fitter'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6531,
                        'IsSynonym': false,
                        'Sequence': 2646,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ultrasound Technician'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6532,
                        'IsSynonym': false,
                        'Sequence': 2647,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Umpire'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6533,
                        'IsSynonym': false,
                        'Sequence': 2648,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Underwriter'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6534,
                        'IsSynonym': false,
                        'Sequence': 2649,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Unemployed'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6535,
                        'IsSynonym': false,
                        'Sequence': 2650,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'University Lecturer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6536,
                        'IsSynonym': false,
                        'Sequence': 2651,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Urban and Regional Planner'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6537,
                        'IsSynonym': false,
                        'Sequence': 2652,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Urologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6538,
                        'IsSynonym': false,
                        'Sequence': 2653,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vehicle Manufacturing Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6539,
                        'IsSynonym': false,
                        'Sequence': 2654,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vending Machine Attendant'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6540,
                        'IsSynonym': false,
                        'Sequence': 2655,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vending Machine Servicer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6541,
                        'IsSynonym': false,
                        'Sequence': 2656,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Ventriloquist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6542,
                        'IsSynonym': false,
                        'Sequence': 2657,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vermin and Noxious Weeds Inspector'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6543,
                        'IsSynonym': false,
                        'Sequence': 2658,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Veterinarian'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6544,
                        'IsSynonym': false,
                        'Sequence': 2659,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Veterinary Assistant/attendant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6545,
                        'IsSynonym': false,
                        'Sequence': 2660,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Veterinary Nurse'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6546,
                        'IsSynonym': false,
                        'Sequence': 2661,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Veterinary Parasitologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6547,
                        'IsSynonym': false,
                        'Sequence': 2662,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Veterinary Pathologist'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6548,
                        'IsSynonym': false,
                        'Sequence': 2663,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Veterinary Surgeon (Farm animals/Small animals)'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6549,
                        'IsSynonym': false,
                        'Sequence': 2664,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vicar'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6550,
                        'IsSynonym': false,
                        'Sequence': 2665,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Video and Sound Recorder'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6551,
                        'IsSynonym': false,
                        'Sequence': 2666,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Video Distributor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6552,
                        'IsSynonym': false,
                        'Sequence': 2667,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Video Producer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6553,
                        'IsSynonym': false,
                        'Sequence': 2668,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Video shop assistant'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6554,
                        'IsSynonym': false,
                        'Sequence': 2669,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Virologist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6555,
                        'IsSynonym': false,
                        'Sequence': 2670,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vision Mixer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6556,
                        'IsSynonym': false,
                        'Sequence': 2671,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Viticulturist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6557,
                        'IsSynonym': false,
                        'Sequence': 2672,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vocalist'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6558,
                        'IsSynonym': false,
                        'Sequence': 2673,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Vocational Counsellor'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6559,
                        'IsSynonym': false,
                        'Sequence': 2674,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Volconologist'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6560,
                        'IsSynonym': false,
                        'Sequence': 2675,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Waiter/Waitress'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6561,
                        'IsSynonym': false,
                        'Sequence': 2676,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wall and Floor Tiler'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6562,
                        'IsSynonym': false,
                        'Sequence': 2677,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wardrobe Assistant'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6563,
                        'IsSynonym': false,
                        'Sequence': 2678,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wardrobe Co-ordinator'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6564,
                        'IsSynonym': false,
                        'Sequence': 2679,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Warehouse Assistant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6565,
                        'IsSynonym': false,
                        'Sequence': 2680,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Warehouse Controller'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6566,
                        'IsSynonym': false,
                        'Sequence': 2681,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Warehouse, Porter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6567,
                        'IsSynonym': false,
                        'Sequence': 2682,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Warranty Clerk'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6568,
                        'IsSynonym': false,
                        'Sequence': 2683,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Watch and Clock Maker and Repairer'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6569,
                        'IsSynonym': false,
                        'Sequence': 2684,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Water Quality Analyst'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6570,
                        'IsSynonym': false,
                        'Sequence': 2685,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Water Skiing - Professional or Regular competition'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6571,
                        'IsSynonym': false,
                        'Sequence': 2686,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Water Supply Distribution Engineer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6572,
                        'IsSynonym': false,
                        'Sequence': 2687,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Water Treatment Engineer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6573,
                        'IsSynonym': false,
                        'Sequence': 2688,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Waterside Worker'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6574,
                        'IsSynonym': false,
                        'Sequence': 2689,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Weapons Manufacturer (excluding explosives)'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6575,
                        'IsSynonym': false,
                        'Sequence': 2690,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Weapons Manufacturer (including explosives)'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6576,
                        'IsSynonym': false,
                        'Sequence': 2691,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Welder'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6577,
                        'IsSynonym': false,
                        'Sequence': 2692,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Welfare Officer'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6578,
                        'IsSynonym': false,
                        'Sequence': 2693,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Welfare Worker'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6579,
                        'IsSynonym': false,
                        'Sequence': 2694,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Well Treatment Operator, Oil and Gas'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6580,
                        'IsSynonym': false,
                        'Sequence': 2695,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wharf Labourer'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6581,
                        'IsSynonym': false,
                        'Sequence': 2696,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wharf Tally Clerk'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6582,
                        'IsSynonym': false,
                        'Sequence': 2697,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wholesaler'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6583,
                        'IsSynonym': false,
                        'Sequence': 2698,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wild Animal Trainer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6584,
                        'IsSynonym': false,
                        'Sequence': 2699,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wildlife Keeper'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6585,
                        'IsSynonym': false,
                        'Sequence': 2700,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wildlife Veterinarian'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6586,
                        'IsSynonym': false,
                        'Sequence': 2701,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Winchman, Construction Industry'
                    },
                    {
                        'Description': 'Class 5',
                        'FloatingIndex': false,
                        'Id': 6587,
                        'IsSynonym': false,
                        'Sequence': 2702,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Window cleaner'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6588,
                        'IsSynonym': false,
                        'Sequence': 2703,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Windowdresser'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6589,
                        'IsSynonym': false,
                        'Sequence': 2704,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wine Merchant'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6590,
                        'IsSynonym': false,
                        'Sequence': 2705,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wine Steward/Stewardess'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6591,
                        'IsSynonym': false,
                        'Sequence': 2706,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wood and Wood Products Factory Hand'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6592,
                        'IsSynonym': false,
                        'Sequence': 2707,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wood carver'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6593,
                        'IsSynonym': false,
                        'Sequence': 2708,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wood Processing Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6594,
                        'IsSynonym': false,
                        'Sequence': 2709,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wooden Floor Layer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6595,
                        'IsSynonym': false,
                        'Sequence': 2710,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wool Classer/grower/presser/sorter'
                    },
                    {
                        'Description': 'Class 1',
                        'FloatingIndex': false,
                        'Id': 6596,
                        'IsSynonym': false,
                        'Sequence': 2711,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Work Study Officer'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6597,
                        'IsSynonym': false,
                        'Sequence': 2712,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wreck/salvage work'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6598,
                        'IsSynonym': false,
                        'Sequence': 2713,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Wrestler - Professional'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6599,
                        'IsSynonym': false,
                        'Sequence': 2714,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Yachtsman - Professional'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6600,
                        'IsSynonym': false,
                        'Sequence': 2715,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Yarn Production Machine Operator'
                    },
                    {
                        'Description': 'Class 3',
                        'FloatingIndex': false,
                        'Id': 6601,
                        'IsSynonym': false,
                        'Sequence': 2716,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Zoo Keeper'
                    },
                    {
                        'Description': 'Class 4',
                        'FloatingIndex': false,
                        'Id': 6602,
                        'IsSynonym': false,
                        'Sequence': 2717,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Zoo Worker'
                    },
                    {
                        'Description': 'Class 2',
                        'FloatingIndex': false,
                        'Id': 6603,
                        'IsSynonym': false,
                        'Sequence': 2718,
                        'SetupTableId': 6,
                        'Status': 1,
                        'Synonyms': [],
                        'Title': 'Zoology Technical Officer'
                    }
                ],
                'QuestionType': 4
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'SingleEntryAnswerProvider',
                        'ApplicantAttributeName': 'Gross monthly income',
                        'AutoComplete': false,
                        'DataType': 'Number',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Gross monthly income',
                        'Value': null,
                        'id': 54915
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'monthly_income',
                'Id': 14363,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 7,
                'Text': 'Gross monthly income (before tax and other deductions)',
                'Title': 'Gross monthly income',
                'QuestionType': 2
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'No matric',
                        'Value': '0',
                        'id': 54929
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Matric',
                        'Value': '1',
                        'id': 54927
                    },
                    {
                        'AnswerSequence': 3,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Diploma (less than 4 years)',
                        'Value': '2',
                        'id': 54925
                    },
                    {
                        'AnswerSequence': 4,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Diploma (4 years or more)',
                        'Value': '3',
                        'id': 54923
                    },
                    {
                        'AnswerSequence': 5,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': '3 year University / Undergraduate degree',
                        'Value': '4',
                        'id': 54921
                    },
                    {
                        'AnswerSequence': 6,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': '4 year University / Undergraduate degree',
                        'Value': '5',
                        'id': 54919
                    },
                    {
                        'AnswerSequence': 7,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Postgraduate study',
                        'Value': '6',
                        'id': 54917
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'education',
                'Id': 14365,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 8,
                'Text': 'Highest educational qualification',
                'Title': 'Education',
                'QuestionType': 1
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'No I don\'t',
                        'Value': '0',
                        'id': 54933
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Yes I do',
                        'Value': '1',
                        'id': 54930
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'Residency',
                'Id': 14373,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 9,
                'Text': 'Do you live and/or work in South Africa for more than 6 months of the year?',
                'Title': 'Residency',
                'QuestionType': 1
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Direct within allowed sum assured',
                        'Value': '0',
                        'id': 54945
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Direct exceeding allowed sum assured',
                        'Value': '1',
                        'id': 54943
                    },
                    {
                        'AnswerSequence': 3,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Intermediary',
                        'Value': '2',
                        'id': 54940
                    },
                    {
                        'AnswerSequence': 4,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [
                            {
                                'AnswerId': 54937,
                                'QuestionProfileId': 14519,
                                'SectionId': 14503,
                                'SectionVisibilityQuestionProfiles': null,
                                'Visibility': 'Show',
                                'id': 12120
                            }
                        ],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Direct improving rates',
                        'Value': '3',
                        'id': 54937
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'DND_sales_channel',
                'Id': 14376,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 10,
                'Text': 'Sales channel',
                'Title': 'DND Sales channel',
                'QuestionType': 7
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'No',
                        'Value': '0',
                        'id': 54948
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [
                            {
                                'AnswerId': 54947,
                                'QuestionProfileId': 14388,
                                'SectionId': 14387,
                                'SectionVisibilityQuestionProfiles': null,
                                'Visibility': 'Show',
                                'id': 12128
                            }
                        ],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Yes',
                        'Value': '1',
                        'id': 54947
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'DND_Show_products',
                'Id': 14381,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 11,
                'Text': 'Show product selection?',
                'Title': 'DND Show products',
                'QuestionType': 7
            },
            {
                'AllowMultiAnswers': false,
                'Answer': [
                    {
                        'AnswerSequence': 1,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'No',
                        'Value': '0',
                        'id': 54950
                    },
                    {
                        'AnswerSequence': 2,
                        'AnswerType': 'BasicAnswerProvider',
                        'ApplicantAttributeName': null,
                        'AutoComplete': false,
                        'DataType': 'String',
                        'OutComes': [],
                        'OverrideAnswer': false,
                        'Prompt': '',
                        'Text': 'Yes',
                        'Value': '1',
                        'id': 54949
                    }
                ],
                'AnswerRequired': true,
                'ExternalReferenceId': 'DND_SA_ID_number',
                'Id': 14384,
                'IsVisibleAtStart': true,
                'Prompt': '',
                'QuestionSequence': 12,
                'Text': 'Was a valid South African ID number provided?',
                'Title': 'DND South African ID number',
                'QuestionType': 7
            }
        ]
    };
};
