import { PipeTransform, Pipe } from '@angular/core';
import { IdentityAuthProviderService } from '@app/shared/common/auth/identity-auth-provider.service';

@Pipe({
    name: 'thousandSeperator',
    pure: true
})
export class ThousandSeperatorPipe implements PipeTransform {

    transform(value: any): string {
        if (!value) {
            if (value === 0 && value !== '') {
                return '0';
            } else {
                return '';
            }
        }
        let stringValue = value.toString().trim();
        while (stringValue.indexOf(' ') > 0) {
            stringValue = stringValue.replace(' ', '');
        }
        let regex = new RegExp('^[0-9]*$');
        let isDigit = regex.test(stringValue);
        if (isDigit) {
            return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }

        return stringValue;
    }
}



@Pipe({
    name: 'CurrencyZar',
    pure: true
})
export class CurrencyPipeZar implements PipeTransform {
    constructor(private authService: IdentityAuthProviderService) {
    }

    transform(
        value: any = 0,
        displayDecimals: boolean = true,
        showSymbol: boolean = true,
        checkTenant: boolean = false
    ): string | null {
        let symbol = 'R';

        if (checkTenant && this.authService.user && this.authService.user.profile && this.authService.user.profile.tenant_name === 'Australia') {
            symbol = 'A$';
        }

        if (Number.isNaN(value)) {
            if (displayDecimals) {
                return showSymbol ? `${symbol} 0.00` : '0.00';
            }

            return showSymbol ? `${symbol} 0` : '0';
        }

        value = +value;

        if (value != null) {
            const decimals = displayDecimals ? 2 : 0;

            const formattedValue = Math.abs(value).toLocaleString('en', {
                'minimumFractionDigits': decimals,
                'maximumFractionDigits': decimals
            }).replace(/,/g, ' ');

            if (value < 0) {
                return showSymbol ? `-${symbol} ${formattedValue}` : `-${formattedValue}`;
            }

            return showSymbol ? `${symbol} ${formattedValue}` : `${formattedValue}`;
        }

        if (displayDecimals) {
            return showSymbol ? `${symbol} 0.00` : '0.00';
        }

        return showSymbol ? `${symbol} 0` : '0';
    }

    eliminateZero(data: string): string {
        if (!data.startsWith('0')) {
            return data;
        } else {
            return this.eliminateZero(data.substring(1));
        }
    }

}


@Pipe({
    name: 'truncateUnderscore',
    pure: true
})
export class TruncateUnderscorePipe implements PipeTransform {
    transform(item: string): string {
        if (item && typeof (item) === 'string' && item.trim().startsWith('_')) {
            item = item.slice(1);

            return item;
        }
        if (item && typeof (item) === 'string') {
            return item.replace('_', ' ');
        }
        return item;
    }
}

@Pipe({
    name: 'truncateDecimal',
    pure: true
})
export class TruncateDecimalPipe implements PipeTransform {
    transform(item: string): string {
        let formattedString = item.split('.');
        let amountValue = formattedString[0];
        return amountValue;
    }

}


@Pipe({
    name: 'yearsPipe',
    pure: true
})
export class YearsPipe implements PipeTransform {
    transform(item: string): string {

        if (item.toLocaleLowerCase() === 'wol') {
            return 'whole of life';
        }

        if (Number.isNaN(parseInt(item))) {
            return item;
        }
        item = 'up to ' + item + ' years old';
        return item;
    }
}



@Pipe({
    name: 'groupBy',
    pure: true
})
export class GroupByPipe implements PipeTransform {
    transform(collection: any[], property: string): any[] {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }

        if (property.includes('.')) {
            let propArray = property.split('.');
            let propFirstItem = propArray[0];
            let propSecondItem = propArray[1];
            const groupedCollection = collection.reduce((previous, current) => {
                if (!previous[current[propFirstItem][propSecondItem]]) {
                    previous[current[propFirstItem][propSecondItem]] = [current];
                } else {
                    previous[current[propFirstItem][propSecondItem]].push(current);
                }

                return previous;
            }, {});
            let d = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
            return d;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }

            return previous;
        }, {});

        // this will return an array of objects, each object containing a group of objects
        let d = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
        return d;
    }
}

@Pipe({
    name: 'customsort',
    pure: true
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            const aVal = +(a[field].replace(/[^0-9\.]+/g, ''));
            const bVal = +(b[field].replace(/[^0-9\.]+/g, ''));

            if (aVal < bVal) {
                return -1;
            } else if (aVal > bVal) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}

@Pipe({
    name: 'fillterBy',
    pure: true
})
export class FilterByPipe implements PipeTransform {
    transform(collection: any[], property: string, value: any): any[] {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection || !value) {
            return null;
        }

        if (property.includes('.')) {
            let propArray = property.split('.');
            let propFirstItem = propArray[0];
            let propSecondItem = propArray[1];
            if (typeof (value) === 'string') {
                return collection.filter(x => x[propFirstItem][propSecondItem].toString() === value);
            } else if (typeof (value) === 'object') {
                return collection.filter(x => value.includes(x[propFirstItem][propSecondItem]));
            }

        }


        if (typeof (value) === 'string') {
            return collection.filter(x => x[property].toString() === value);
        } else if (typeof (value) === 'object') {
            return collection.filter(x => value.includes(x[property]));
        }
    }
}

@Pipe({
    name: 'questionableBoolean',
    pure: true
})
export class QuestionableBooleanPipe implements PipeTransform {
    transform(value: string): string {
        return value.toLowerCase() === 'true' ? 'Yes' : 'No';
    }
}

@Pipe({
    name: 'myfilterForSplit',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: string): any {

        return items.split(';');
    }
}
