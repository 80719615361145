import { animate, state, style, transition, trigger, keyframes } from '@angular/animations';

export function appModuleAnimation() {
    return slideFromBottom();
}

export function accountModuleAnimation() {
    return slideFromUp();
}

export function slideFromBottom() {
    return trigger('routerTransition', [
        state('void', style({ 'padding-top': '20px', opacity: '0' })),
        state('*', style({ 'padding-top': '0px', opacity: '1' })),
        transition(':enter', [
            animate('0.33s ease-out', style({ opacity: '1', 'padding-top': '0px' }))
        ])
    ]);
}

export function slideFromUp() {
    return trigger('routerTransition', [
        state('void', style({ 'margin-top': '-10px', opacity: '0' })),
        state('*', style({ 'margin-top': '0px', opacity: '1' })),
        transition(':enter', [
            animate('0.2s ease-out', style({ opacity: '1', 'margin-top': '0px' }))
        ])
    ]);
}


export function bounceIn() {
    return trigger('bouncein', [

        state('idle-bounceIn', style({ opacity: 1 })),
        state('idle-bounceInDown', style({ opacity: 1 })),
        state('idle-bounceInLeft', style({ opacity: 1 })),
        state('idle-bounceInUp', style({ opacity: 0 })),
        state('idle-bounceInRight', style({ opacity: 0 })),

        transition('* => idle-bounceIn',

            animate('{{timing}} {{delay}} cubic-bezier(0.215, 0.61, 0.355, 1)',

                keyframes([
                    style({ opacity: 0, transform: 'scale(0.3)', offset: 0 }),
                    style({ transform: 'scale(1.1)', offset: 0.2 }),
                    style({ transform: 'scale(0.9)', offset: 0.4 }),
                    style({ opacity: 1, transform: 'scale(1.03)', offset: 0.6 }),
                    style({ transform: 'scale(0.97)', offset: 0.8 }),
                    style({ opacity: 1, transform: 'scale(1)', offset: 1 })
                ])

            ), { params: { timing: '1750ms', delay: '' } }
        ),

        transition('* => idle-bounceInDown',

            animate('{{timing}} {{delay}} cubic-bezier(0.215, 0.61, 0.355, 1)',

                keyframes([
                    style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
                    style({ opacity: 1, transform: 'translateY(25px)', offset: 0.6 }),
                    style({ transform: 'translateY(-10px)', offset: 0.75 }),
                    style({ transform: 'translateY(5px)', offset: 0.9 }),
                    style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
                ])

            ), { params: { timing: '1s', delay: '' } }
        ),

        transition('* => idle-bounceInLeft',

            animate('{{timing}} {{delay}} cubic-bezier(0.215, 0.61, 0.355, 1)',

                keyframes([
                    style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
                    style({ opacity: 1, transform: 'translateX(25px)', offset: 0.6 }),
                    style({ transform: 'translateX(-10px)', offset: 0.75 }),
                    style({ transform: 'translateX(5px)', offset: 0.9 }),
                    style({ opacity: 1, transform: 'translateX(0)', offset: 1 })
                ])

            ), { params: { timing: '1s', delay: '' } }
        ),

        transition('* => bounceInUp',

            animate('{{timing}} {{delay}} cubic-bezier(0.215, 0.61, 0.355, 1)',

                keyframes([
                    style({ opacity: 0, transform: 'translateY(100%)', offset: 0 }),
                    style({ opacity: 1, transform: 'translateY(-25px)', offset: 0.6 }),
                    style({ transform: 'translateY(10px)', offset: 0.75 }),
                    style({ transform: 'translateY(-5px)', offset: 0.9 }),
                    style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
                ])

            ), { params: { timing: '1s', delay: '' } }
        ),

        transition('* => bounceInRight',

            animate('{{timing}} {{delay}} cubic-bezier(0.215, 0.61, 0.355, 1)',

                keyframes([
                    style({ opacity: 0, transform: 'translateX(100%)', offset: 0 }),
                    style({ opacity: 1, transform: 'translateX(-25px)', offset: 0.6 }),
                    style({ transform: 'translateX(10px)', offset: 0.75 }),
                    style({ transform: 'translateX(-5px)', offset: 0.9 }),
                    style({ opacity: 1, transform: 'translateX(0)', offset: 1 })
                ])

            ), { params: { timing: '1s', delay: '' } }
        )
    ]);
}
