



export interface IKeyedCollection<T> {
    Add(key: string, value: T);
    ContainsKey(key: string): boolean;
    Count(): number;
    Item(key: string): T;
    Keys(): string[];
    Remove(key: string): T;
    Values(): T[];
}

export class KeyedCollection<T> implements IKeyedCollection<T> {
    private items: { [index: string]: T } = {};

    private count = 0;

    public ContainsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    public Count(): number {
        return this.count;
    }

    public getList(): { extId: string, questions: T }[] {
        let keysetvalues: { extId: string, questions: T }[] = [];

        for (let prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keysetvalues.push({ extId: prop, questions: this.items[prop] });
            }
        }
        return keysetvalues;
    }

    public Add(key: string, value: T) {
        if (!this.items.hasOwnProperty(key)) {
            this.count++;
        }

        this.items[key] = value;
    }

    public AddRange(valuePair: { extId: string, value: T }[]) {
        valuePair.forEach(x => {
            this.Add(x.extId, x.value);
        });
    }

    public Remove(key: string): T {
        let val = this.items[key];
        delete this.items[key];
        this.count--;
        return val;
    }

    public Item(key: string): T {
        return this.items[key];
    }

    public Keys(): string[] {
        let keySet: string[] = [];

        for (let prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }

        return keySet;
    }

    public Values(): T[] {
        let values: T[] = [];

        for (let prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }

        return values;
    }
}
