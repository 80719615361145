export class BenefitPattern {
    UID: string;
    benefitIncreaseIndex: string;
    benefitIncrease: number | string;
    benefitEscallationTerm: string;
    InClaimbenefitIncreaseIndex: string;
    InClaimbenefitIncrease: string;
    maxPaymentAge: string;
    InClaimMaximumPaymentTerm: string;

    constructor(UID: string,
        benefitIncreaseIndex: string,
        benefitIncrease: number | string,
        benefitEscallationTerm: string,
        inClaimbenefitIncreaseIndex: string,
        inClaimbenefitIncrease: string,
        maxPaymentAge: string,
        InClaimMaximumPaymentTerm: string
    ) {
        this.UID = UID;
        this.benefitIncreaseIndex = benefitIncreaseIndex;
        this.benefitIncrease = benefitIncrease;
        this.benefitEscallationTerm = benefitEscallationTerm;
        this.InClaimbenefitIncreaseIndex = inClaimbenefitIncreaseIndex;
        this.InClaimbenefitIncrease = inClaimbenefitIncrease;
        this.maxPaymentAge = maxPaymentAge;
        this.InClaimMaximumPaymentTerm = InClaimMaximumPaymentTerm;
    }
}


