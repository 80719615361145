import { Inject, Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PolicyService } from '@app/shared/services/policy.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonModule, PoliciesModule } from '@app/shared/models/elevate-data-models';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MyDocumentsService extends AppComponentBase {
    private _policyService: PolicyService;
    public get policyService(): PolicyService {
        if (!this._policyService) {
            this._policyService = this.injector.get(PolicyService);
        }
        return this._policyService;
    }

    public vm = {
        currentPolicyId: '',
        entityId: ''
    };

    constructor(
        private injector: Injector,
        private http: HttpClient,
        @Inject(API_BASE_URL) private appServiceBaseUrl
    ) {
        super(injector);
        if (this.permission.user) {
            this.vm.entityId = this.permission.user.profile.sub;
        }
    }

    getPolicyList(policyList: PersonModule.IPolicyAPI[]): PoliciesModule.IPolicyModel[] {
        return this.policyService.getPolicySets(policyList);
    }

    getAPerson(personId: string, isCaching?: boolean): Observable<PersonModule.IPersonModel> {
        return this.policyService.getPerson(personId, isCaching)
            .pipe(map(res => {
                return res;
            }));
    }

    getPolicyById(policyId: string): Observable<PoliciesModule.IPolicyModel> {
        return this.policyService.getPolicy(policyId)
            .pipe(map(res => {
                return res;
            }));
    }

    getCoverProposals(brokerGuid: string): Observable<Array<string>> {
        let url = `${this.appServiceBaseUrl}/api/services/app/CoverProposals/GetCoverProposals?brokerGuid=${brokerGuid}`;
        return this.http.get<{ result: Array<string> }>(url, {}).pipe(map(result => result.result));
    }

    getCoverProposalUrl(fileName: string): Observable<string> {
        let url = `${this.appServiceBaseUrl}/api/services/app/CoverProposals/GetPublicUrl?fileName=${fileName}`;
        return this.http.get<{ result: string }>(url, {}).pipe(map(result => result.result));
    }

    sendCoverProposal(coverProposal: { quoteUids: Array<string>, isQuickQuote: boolean, email: string, businessAssuranceType?: string, coverReason: string }): Observable<boolean> {
        let url = `${this.appServiceBaseUrl}/api/services/app/CoverProposals/AddCoverProposal`;
        return this.http.post<{ result: boolean }>(url, coverProposal).pipe(map(result => result.result));
    }

    getFNAs(brokerGuid: string): Observable<Array<string>> {
        let url = `${this.appServiceBaseUrl}/api/services/app/fna/GetFNAsForBroker?brokerGuid=${brokerGuid}`;
        return this.http.get<{ result: Array<string> }>(url, {}).pipe(map(result => result.result));
    }

    getFnaUrl(fileName: string): Observable<string> {
        let url = `${this.appServiceBaseUrl}/api/services/app/fna/GetPublicUrl?fileName=${fileName}`;
        return this.http.get<{ result: string }>(url, {}).pipe(map(result => result.result));
    }
}
