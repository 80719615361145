import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { BenefitModule, ProductListModule, PremiumPatternModule, PersonModule, RatingFactorModule, BenficiaryModule, IIncrementalQuote, RateTableModule, ProductQuote, BenificeryModule, QuoteModule, RiderProductQuote, GetMaxSumAssuredModule, BeneficieryOutputModule, RateTableEntriesFlatModule, RatingFactorInputResponseModule, GetAllQuotes, CommissionModule, PoliciesModule, ITemplateEmailDto, ISubmittedQuoteDto } from '@app/shared/models/elevate-data-models';
import { Observable, of, forkJoin } from 'rxjs';
import { AppConsts } from '../../../shared/AppConsts';
import * as moment from 'moment';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { IAssuredProductThreshold, IAssuredProductThresholdModel, IOccupationPremium } from '../FactoryResources/ProductFactory/Product';


const APP_BASE_URL = 'https://elevate-apim.azure-api.net/';
const ACESS_TOKEN = '';
@Injectable()
export class ProductService {
    private cachedResponses = new Map();
    private productRateTableMap = new Map();
    readonly API_BASE_URL_IDP = abp.setting.values[AppConsts.KeyVaultSecrets.PortalSettingsAuthority];
    //readonly API_BASE_URL_IDP = 'http://localhost:53739';
    readonly ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];

    constructor(
        private httpMethodBaseService: HttpMethodBaseService,
        @Inject(API_BASE_URL) private appServiceBaseUrl
    ) { }

    getProductList(generation?: string): Observable<ProductListModule.IProductListModel[]> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllProduct.replace('{GENERATION_NAME}', abp.setting.values['ActiveGenerationName']);
        const conditionsFromCache = this.cachedResponses.get(_url);
        if (conditionsFromCache) { return of([...conditionsFromCache]); }
        //let headers = new HttpHeaders({
        //    'Authorization': 'Bearer ' + ACCESS_TOKEN
        //});
        return this.httpMethodBaseService.get<ProductListModule.IProductListModel[]>(_url)
            .pipe(map((res: ProductListModule.IProductListModel[]) => {
                this.cachedResponses.set(_url, res);
                return res;
            }));
    }


    getBenefitPatterSetForMultipleUid(uid: string[]): Observable<BenefitModule.IBenefitModel[]> {

        let observableArray = new Array<Observable<BenefitModule.IBenefitModel>>();

        uid.forEach(x => {
            observableArray.push(this.getBenefitPatternSet(x));
        });

        return forkJoin(observableArray).pipe(map((res: BenefitModule.IBenefitModel[]) => {
            return res;
        }));
    }


    getBenefitPatternSet(uid: string): Observable<BenefitModule.IBenefitModel> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPatternSet.replace('{GUID}', uid);

        return this.httpMethodBaseService.get<BenefitModule.IBenefitModel>(_url)
            .pipe(map((res: BenefitModule.IBenefitModel) => {
                return res;
            }));
    }


    getPremiumPatterSetForMultipleUid(uid: string[]): Observable<PremiumPatternModule.IPremiumPatternModel[]> {

        let observableArray = new Array<Observable<PremiumPatternModule.IPremiumPatternModel>>();

        uid.forEach(x => {
            observableArray.push(this.getPremiumPatternSet(x));
        });

        return forkJoin(observableArray).pipe(map((res: PremiumPatternModule.IPremiumPatternModel[]) => {
            return res;
        }));
    }

    getPremiumPatternSet(uid: string): Observable<PremiumPatternModule.IPremiumPatternModel> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPatternSet.replace('{GUID}', uid);

        const conditionsFromCache = this.cachedResponses.get(_url);
        if (conditionsFromCache) { return of(conditionsFromCache); }

        //let headers = new HttpHeaders({
        //    'Authorization': 'Bearer ' + ACCESS_TOKEN
        //})

        let response = this.httpMethodBaseService.get<PremiumPatternModule.IPremiumPatternModel>(_url)
            .pipe(map((res: PremiumPatternModule.IPremiumPatternModel) => {
                return res;
            }));

        response.subscribe(res => this.cachedResponses.set(_url, res));

        return response;

    }


    getRateTableForProduct(productId: string, ratingFactorSet: RatingFactorInputResponseModule.RatingFactor[], lowerLimit: number | null = null, upperLimit: number | null = null): Observable<RateTableEntriesFlatModule.RateTableEntries[]> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getRateTableEntryFlat;
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const body: {
            ProductGUID: string,
            RatingFactors: Array<RatingFactorInputResponseModule.RatingFactor>,
            SumAssuredUpperLimit?: number,
            SumAssuredLowerLimit?: number
        } = {
            ProductGUID: productId,
            RatingFactors: ratingFactorSet
        };

        if (upperLimit) {
            body.SumAssuredUpperLimit = upperLimit;
        }

        if (lowerLimit) {
            body.SumAssuredLowerLimit = lowerLimit;
        }

        let response = this.httpMethodBaseService.post<RateTableEntriesFlatModule.RateTableEntries[]>(_url, body, headers)
            .pipe(map((res: RateTableEntriesFlatModule.RateTableEntries[]) => {
                return res;
            }));
        return (response as any);
    }

    productQuoted(quoteId: string, data: ProductQuote, productQuoteId, requestMethod: string): Observable<any> {

        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.postProductQuoted.replace('{Quote_GUID}', quoteId);
        if (productQuoteId) {
            _url = _url + '/' + productQuoteId;
        }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.httpMethodBaseService.request<any>(requestMethod, _url, data, headers)
            .pipe(map((res: any) => {
                return res ? res : undefined;
            }));
    }


    addBeneficeryToQuote(productQuotedId: string, quoteId: string, beneficiaryForQuote: BenificeryModule.IBeneficiaryForQuote[]): Observable<boolean> {
        return this.httpMethodBaseService.put<boolean>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.createBeneficiary
            .replace('{Quote_GUID}', quoteId)
            .replace('{ProductQuoted_GUID}', productQuotedId), beneficiaryForQuote)
            .pipe(map(res => {
                return true;
            }));
    }

    getBeneficary(quoteUid: string, productUid: string): Observable<Array<{
        AllocationPercentage: number
        AssuredType: string
        Beneficiary: string
    }>> {
        return this.httpMethodBaseService.get(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getBeneficiariesForProductQuoted
            .replace('{Quote_GUID}', quoteUid)
            .replace('{ProductQuoted_GUID}', productUid));
    }

    deleteBeneficiary(beneficiaryGuid: string, policyGuid: string): Observable<{}> {
        return this.httpMethodBaseService.delete(this.API_BASE_URL_IDP + AppConsts.Endpoints.deleteBeneficiary.replace('{BENEFICIARY_GUID}', beneficiaryGuid).replace('{POLICY_GUID}', policyGuid));
    }

    getAllBeneficieriesForQuote(quoteId, productId): Observable<BeneficieryOutputModule.BeneficieryOutput[]> {
        return this.httpMethodBaseService.get<BeneficieryOutputModule.BeneficieryOutput[]>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.createBeneficiary
            .replace('{Quote_GUID}', quoteId)
            .replace('{ProductQuoted_GUID}', productId))
            .pipe(map(res => {
                return res;
            }));
    }

    addNonIndividualExclusionSet(quoteId: string, exclusionSetShortCode?: string): Observable<boolean> {
        return this.httpMethodBaseService.put<boolean>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.addNonIndividualExclusionSet
            .replace('{QuoteGUID}', quoteId)
            .replace('{ExclusionSetShortCode}', abp.setting.values[AppConsts.KeyVaultSecrets.GeneralExclusions]), '')
            .pipe(map(res => {
                return true;
            }));
    }

    acceptQuote(input: { accessToken: string, quoteId: string, includeIntermediaryDocument: boolean, senderGuid: string, ccLifeAssured: boolean, ccEmails: Array<string> }): Observable<any> {
        return this.httpMethodBaseService.post<any>(`${this.appServiceBaseUrl}/api/services/app/Quotes/AcceptQuote`, input);
    }

    nonAcceptedQuotes(input: { accessToken: string, quoteIds: Array<string>}): Observable<any> {
        return this.httpMethodBaseService.post<any>(`${this.appServiceBaseUrl}/api/services/app/Quotes/NonAcceptedQuotes`, input);
    }

    filterOutClonedQuotes(quoteUids: Array<string>): Observable<QuoteModule.IFilterOutClonedQuotes> {
        return this.httpMethodBaseService.post<{ result: QuoteModule.IFilterOutClonedQuotes }>(`${this.appServiceBaseUrl}/app/services/app/Quotes/FilterOutClonedQuotes`, { quoteUids })
            .pipe(map(res => res.result));
    }

    cloneQuote(cloneQuoteInput: QuoteModule.ICloneQuote): Observable<Array<GetAllQuotes>> {
        return this.httpMethodBaseService.post<{ result: Array<GetAllQuotes> }>(`${this.appServiceBaseUrl}/api/services/app/Quotes/CloneQuote`, cloneQuoteInput)
            .pipe(map(res => res.result));
    }

    createClonedQuoteRecord(cloneQuoteInput: QuoteModule.ICloneQuote): Observable<{}> {
        return this.httpMethodBaseService.post<{ result: any }>(`${this.appServiceBaseUrl}/api/services/app/Quotes/CreateClonedQuoteRecord`, cloneQuoteInput)
            .pipe(map(res => res.result));
    }

    getNeedsAssessmentSumAssuredLevels(): Observable<Array<QuoteModule.INeedsAssessmentSumAssuredLevel>> {
        return this.httpMethodBaseService.get<{ result: Array<QuoteModule.INeedsAssessmentSumAssuredLevel> }>(`${this.appServiceBaseUrl}/api/services/app/Quotes/GetNeedsAssessmentSumAssuredLevels`)
            .pipe(map(res => res.result));
    }

    getAQuote(quoteId: string): Observable<QuoteModule.IQuoteDetails> {
        return this.httpMethodBaseService.get<QuoteModule.IQuoteDetails>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAQuote
            .replace('{Quote_GUID}', quoteId))
            .pipe(map(res => {
                return res;
            }));
    }

    submitAdminQuote(submittedQuote: ISubmittedQuoteDto, templateEmail: ITemplateEmailDto): Observable<boolean> {
        return this.httpMethodBaseService.post<{ result: boolean }>(`${this.appServiceBaseUrl}/api/services/app/Quotes/QuoteSubmittedByBrokerAdmin`,
        { submittedQuote : submittedQuote, templateEmail: templateEmail}).pipe(map(res => res.result));
    }

    getQuoteOnAPersonBasedOnRole(personGuid: string, role: string, underwritingStatus?: number): Observable<Array<GetAllQuotes>> {
        let underwritingStatusParameter = '';
        if (underwritingStatus) {
            underwritingStatusParameter += `&underwritingStatus=${underwritingStatus}`;
        }

        return this.httpMethodBaseService.get<{ result: Array<GetAllQuotes> }>(`${this.appServiceBaseUrl}/api/services/app/Apim/GetQuotesOnPerson?personGuid=${personGuid}&role=${role}${underwritingStatusParameter}`)
            .pipe(map(res => res.result));
    }

    createOrUpdateRiderProduct(data: RiderProductQuote, quoteId, productQuoteID, riderQuotedId?): Observable<any> {
        let request = 'post';
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.createRider.replace('{Quote_GUID}', quoteId).replace('{MainProductQuoted_GUID}', productQuoteID);
        if (riderQuotedId) {
            _url = _url + '/' + riderQuotedId;
            request = 'put';
        }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.httpMethodBaseService.request<any>(request, _url, data, headers)
            .pipe(map(res => {
                return res;
            }));
    }

    getRiderProductQuoted(quoteId, productQuoteID) {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getQuotedRider.replace('{Quote_GUID}', quoteId).replace('{ProductQuoted_GUID}', productQuoteID);
        return this.httpMethodBaseService.get<any>(_url)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteRiderProduct(quoteId, productQuotedId) {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.deleteRiderProduct.replace('{Quote_GUID}', quoteId).replace('{ProductQuoted_GUID}', productQuotedId);
        return this.httpMethodBaseService.delete(_url)
            .pipe(map(res => {
                return res;
            }));
    }

    //idp

    createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails: BenficiaryModule.IBeneficiaryModel): Observable<string> {
        return this.httpMethodBaseService.post<string>(this.API_BASE_URL_IDP + AppConsts.Endpoints.addBeneficiary, beneficiaryDetails)
            .pipe(map(res => {
                return res;
            }));
    }

    getBeneficaryFromIdp(guid: string,  policyUid: string = null): Observable<BenficiaryModule.IBeneficiaryModel> {
        let queryParams = '';

        if (policyUid != null) {
            queryParams += '?policyGuid=' + policyUid;
        }


        return this.httpMethodBaseService.get<BenficiaryModule.IBeneficiaryModel>(this.API_BASE_URL_IDP + AppConsts.Endpoints.getBeneficaryFromIdp.replace('{BENEFICIARY_GUID}', guid) + queryParams);
    }

    sendUpdateBeneficiaryMail(policyUid: string): Observable<boolean> {
        return this.httpMethodBaseService.post<boolean>(`${this.appServiceBaseUrl}/api/services/app/Email/SendUpdateBeneficiaryMail?policyUid=${policyUid}`);
    }

    addIndividualExclusionSet(params: {
        ShortCode: string,
        DisplayName: string,
        CategoryCode: string,
        CategoryName: string,
        Description: string
    }, quoteId: string) {
        return this.httpMethodBaseService.put<string>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.addIndividualExclusionSet.replace('{QUOTE_ID}', quoteId), params)
            .pipe(map(res => {
                return res;
            }));
    }

    addExclusion(params: {
        ExclusionCode: string,
        DisplayName: string,
        ConditionName: string,
        Description: string
    }, quoteId: string) {
        return this.httpMethodBaseService.put<string>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.addExclusion.replace('{QUOTE_ID}', quoteId), params)
            .pipe(map(res => {
                return res;
            }));
    }

    getMendixProductAndQuircMapping(): Observable<any> {
        let url = abp.setting.values[AppConsts.KeyVaultSecrets.AppConfigAngularQuirckMendixMappingUrl];
        let cachedResponse = this.cachedResponses.get(url);
        if (cachedResponse) {
            return of(cachedResponse);
        }
        return this.httpMethodBaseService.get<any>(url)
            .pipe(map(res => {
                this.cachedResponses.set(url, res);
                return res;
            }));
    }

    getAllEventSet(): Observable<any> {

        let cachedResponse = this.cachedResponses.get(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllEventSet);
        if (cachedResponse) {
            return of(cachedResponse);
        }

        return this.httpMethodBaseService.get<any>(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllEventSet)
            .pipe(map(res => {
                this.cachedResponses.set(this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getAllEventSet, res);
                return res;
            }));
    }

    getMaxAndMinSummAssured(getMaxInput: GetMaxSumAssuredModule.GetMaxSumAssuredInput): Observable<number> {
        let url = abp.setting.values[AppConsts.KeyVaultSecrets.GetMaxSumAssuredFuncUrl];
        return this.httpMethodBaseService.post<number>(url, getMaxInput);
    }

    getBrokerCommission(brokerAdminGuid: string, quoteGuid: string): Observable<CommissionModule.BrokerCommission> {
        const url = abp.setting.values[AppConsts.KeyVaultSecrets.AppGetBrokerCommissionFuncUrl] + '?brokerAdminGuid=' + brokerAdminGuid + '&quoteGuid=' + quoteGuid;
        return this.httpMethodBaseService.get<CommissionModule.BrokerCommission>(url);
    }

    getBenefitPatterns(guids: Array<string>, accessToken: string): Observable<Array<PoliciesModule.IBenefitPattern>> {
        const url = `${this.appServiceBaseUrl}/api/services/app/Product/PostBenefitPatterns`;
        return this.httpMethodBaseService
            .post<{ result: Array<PoliciesModule.IBenefitPattern> }>(url, {
                accessToken: accessToken,
                guids: guids
            })
            .pipe(map(res => res.result));
    }

    getPremiumPatterns(guids: Array<string>, accessToken: string): Observable<Array<PoliciesModule.IPremiumPattern>> {
        const url = `${this.appServiceBaseUrl}/api/services/app/Product/PostPremiumPatterns`;
        return this.httpMethodBaseService
            .post<{ result: Array<PoliciesModule.IPremiumPattern> }>(url, {
                accessToken: accessToken,
                guids: guids
            })
            .pipe(map(res => res.result));
    }

    getProducts(guids: Array<string>, accessToken: string): Observable<Array<ProductListModule.IProductListModel>> {
        const url = `${this.appServiceBaseUrl}/api/services/app/Product/PostProducts`;
        return this.httpMethodBaseService
        .post<{ result: Array<ProductListModule.IProductListModel> }>(url, {
            accessToken: accessToken,
            guids: guids
        })
        .pipe(map(res => res.result));
    }

    getAllProductSetupsFromRates(accessToken: string): Observable<Array<PoliciesModule.IProductConfig>> {
        return this.httpMethodBaseService
            .get<{ result: Array<PoliciesModule.IProductConfig> }>(`${this.appServiceBaseUrl}/api/services/app/Product/GetAllProductSetups?accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getAssuredProductThresholds(): Observable<IAssuredProductThresholdModel> {
        return this.httpMethodBaseService
            .get<{ result: IAssuredProductThresholdModel }>(`${this.appServiceBaseUrl}/api/services/app/Product/GetAssuredProductThresholds`)
            .pipe(map(res => res.result));
    }

    getProductOccupationsList(): Observable<IOccupationPremium[]> {
        return this.httpMethodBaseService
            .get<{ result: IOccupationPremium[] }>(`${this.appServiceBaseUrl}/api/services/app/Product/GetProductOccupationsList`)
            .pipe(map(res => res.result));
    }
}
