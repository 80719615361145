import { BenefitPattern } from '../BenefitPatternFactory/BenefitPattern';
import { PremiumPattern } from '../PremiumPatternFactory/PremiumPattern';

export class RateTableEntry {
    UID: string;
    BenefitPattern: BenefitPattern;
    PremiumPattern: PremiumPattern;
    BaseRate: number;
    UnitRate: number;
    sumAssuredLimit: number;
    constructor(UID: string,
        BenefitPatternUID: BenefitPattern,
        PremiumPatternUID: PremiumPattern,
        BaseRate: number,
        UnitRate: number,
        sumAssuredLimit: number) {
        this.UID = UID;
        this.BenefitPattern = BenefitPatternUID;
        this.PremiumPattern = PremiumPatternUID;
        this.BaseRate = BaseRate;
        this.UnitRate = UnitRate;
        this.sumAssuredLimit = sumAssuredLimit;
    }
}
