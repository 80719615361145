import { IdentityAuthProviderService } from '../common/auth/identity-auth-provider.service';
import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import * as moment from 'moment';

@Injectable()
export class QuoteStatusService {

    constructor(
        private _authService: IdentityAuthProviderService
    ) {}

    pushMessages(quoteGuids: Array<string>, brokerGuid: string, status: number, isApproved: boolean = false,
        coverReason: string | null = null, businessAssuranceType: string | null = null, policyHolderIsInsuredLife: string | null = null): void {
        let customHeaders = {
            [abp.multiTenancy.tenantIdCookieName]: abp.multiTenancy.getTenantIdCookie(),
            'Authorization': 'Bearer ' + abp.auth.getToken()
        };

        let data = {
            'quoteGuids' : quoteGuids,
            'status' : status,
            'timeStamp' : moment(),
            'isApproved' : isApproved,
            'accessToken': this._authService.user.access_token,
            'brokerGuid': brokerGuid,
            'coverReason': coverReason,
            'businessAssuranceType': businessAssuranceType,
            'policyHolderIsInsuredLife': policyHolderIsInsuredLife,
            };

        XmlHttpRequestHelper.ajax(
            'POST',
            AppConsts.remoteServiceBaseUrl + '/api/services/app/QuoteStatus/PushMessages',
            customHeaders,
            JSON.stringify(data),
            result => {}
        );
    }
}
