import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, Profile } from 'oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConsts } from '../../../../shared/AppConsts';
import { XmlHttpRequestHelper } from '../../../../shared/helpers/XmlHttpRequestHelper';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IdentityAuthProviderService {

    private manager = new UserManager(getClientSettings());
    public user: User = null;
    private pathName = '/app/main/my-data/client-list';
    public urltor = '';
    userPermission: string[];
    private _brokerGuid: string;
    private _isSecuritasBroker: boolean | null = null;

    constructor(private http: HttpClient, private router: Router) {
        this.manager.events.addUserLoaded(user => {
            this.setUser(user);
        });
    }

    private setUser(user: any): void {
        this.user = user as any;

        if (!this.userPermission && this.user && !this.user.expired) {
            this.getUserRoleViaAjax(() => { });
        }
    }

    isGranted(permission): boolean {
        return this.userPermission.indexOf(permission) >= 0;
    }

    async isSecuritasBroker(): Promise<boolean> {
        if (this._isSecuritasBroker !== null) {
            return this._isSecuritasBroker;
        }

        const elevateAccEndpoint = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];

        try {
            const brokerage: { Brokers: Array<{ Person: { GUID: string, Identifier: string } }> } = await this.http.get<{ Brokers: Array<{ Person: { GUID: string, Identifier: string } }> }>(elevateAccEndpoint + 'brokersandcommission/brokerage/Securitas').toPromise();
            const brokerId = await this.getBrokerGuid();

            for (const broker of brokerage.Brokers) {
                if (broker.Person.GUID === brokerId) {
                    this._isSecuritasBroker = true;
                    return this._isSecuritasBroker;
                }
            }

            this._isSecuritasBroker = false;
            return this._isSecuritasBroker;
        } catch (e) {
            console.error(e);
            abp.message.error('Failed to get broker details', 'REQUEST FAILED');
        }
    }

    async getBrokerGuid(): Promise<string> {
        if (this._brokerGuid) {
            return this._brokerGuid;
        }

        const elevateAccEndpoint = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];

        try {
            const broker = await this.http.get<{ PersonAPI: { Identifier: string } }>(elevateAccEndpoint + 'brokersandcommission/broker/' + this.user.profile.sub).toPromise();
            this._brokerGuid = broker.PersonAPI.Identifier;
            return this._brokerGuid;
        } catch (e) {
            console.error(e);
            abp.message.error('Failed to get broker details', 'REQUEST FAILED');
        }

    }

    getUser(callback: any, forceUpdate: boolean = false): void {
        if (this.user != null && !this.user.expired && (forceUpdate || !this.user.profile)) {
            this.http.get<User>(
                abp.setting.values['PortalSettings.authority'] + `connect/userinfo`
                , {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + this.user.access_token
                    })
                }).subscribe(res => {
                    this.user.profile = res as any;
                    callback(res);
                }, (err) => {
                    abp.ui.clearBusy();
                });
        } else if (this.user && this.user.profile) {
            callback(this.user.profile);
        }
    }

    getUserInfo(callback: any, forceUpdate: boolean = false): void {
        this.getUser((res: Profile) => {
            this.user.profile = res;
            callback(res);
        }, forceUpdate);

        this.user = this.getClaims();
    }

    getUserStatus(callback) {
        this.manager.getUser().then(user => {
            this.setUser(user);

            // callback();
            if (!this.user) {
                this.startAuthentication();
            }
            if (this.user.expired) {
                this.startAuthentication();
            }
        }).catch(err => {
            const x = err;
        });
    }


    private getDevRequestHeaders() {
        return {
            'Accept': 'application/json'
        };
    }

    private getProdRequestHeaders() {
        return {
            'Authorization': 'Bearer ' + this.user.access_token,
            'Accept': 'application/json'
        };
    }

    getDevUrl() {
        let url_ = AppConsts.remoteServiceBaseUrl + '/api/services/app/ClaimModify/GetUserRoleAsync?';
        if (this.user.access_token !== undefined) {
            url_ += 'accessToken=' + encodeURIComponent('' + this.user.access_token) + '&';
        }
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getProdUrl() {
        let url_ = abp.setting.values['PortalSettings.authority'] + '/api/UserClaim/GetCurrentUserRole';
        return url_;
    }

    getUserRoleViaAjax(callback) {
        const requestHeaders = this.getProdRequestHeaders();
        const url_ = this.getProdUrl();

        XmlHttpRequestHelper.ajax(
            'GET',
            url_,
            requestHeaders,
            null,
            (response) => {
                this.userPermission = response;
                let route = localStorage.getItem('nextLink') !== '/' && localStorage.getItem('nextLink') !== '/auth-callback' && localStorage.getItem('nextLink') !== 'check-auth' ? localStorage.getItem('nextLink') : '/app/main/dashboard';
                this.router.navigate([route]);
                callback();
            }
        );
    }

    isLoggedIn(): boolean {
        return this.user != null && !this.user.expired;
    }

    destroySession() {
        this.http.get(abp.setting.values['PortalSettings.authority'] + `/connect/endsession?id_token_hint=` + this.user.id_token);
    }

    getClaims(): any {
        return this.user;
    }

    getAuthorizationHeaderValue(): string {
        return `${this.user.token_type} ${this.user.access_token}`;
    }

    startAuthentication(pathname?): Promise<void> {
        return this.manager.signinRedirect({ state: pathname });
    }

    startLogout(): void {
        this.manager.signoutRedirect();
    }

    completeAuthentication(): Promise<void> {
        return this.manager.signinRedirectCallback().then(user => {
            this.user = user;
            if (user.state && (user.state !== '/auth-callback' && user.state !== '/')) {
                this.pathName = user.state;
            }
            this.getUserRoleViaAjax(() => { });
        }).catch((err) => {
            console.log('FAILED TO COMPLETE AUTHENTICATION', err);
            let route = localStorage.getItem('nextLink') !== '/' && localStorage.getItem('nextLink') !== '/auth-callback' && localStorage.getItem('nextLink') !== 'check-auth' ? localStorage.getItem('nextLink') : '/app/main/dashboard';
            this.router.navigate(['/check-auth']);
        });
    }

    hasBrokerConsultantOrAssistantRole(): boolean {
        return this.userPermission.includes(AppConsts.Roles.BrokerConsultant) || this.userPermission.includes(AppConsts.Roles.BrokerAssistant);
    }

    hasBrokerAssistantRole(): boolean {
        return this.userPermission.includes(AppConsts.Roles.BrokerAssistant);
    }

    hasBrokerConsultantRole(): boolean {
        return this.userPermission.includes(AppConsts.Roles.BrokerConsultant);
    }

    hasBrokerAdminRole(): boolean {
        return this.userPermission.includes(AppConsts.Roles.BrokerAdmin);
    }

    hasBrokerRole(): boolean {
        return this.userPermission.includes(AppConsts.Roles.BrokerUser);
    }

    hasUIAdminRole(): boolean {
        return this.userPermission.includes(AppConsts.Roles.AdminUIAdministrator);
    }
}

export function getClientSettings(): UserManagerSettings {
    return {
        authority: abp.setting.values['PortalSettings.authority'],
        client_id: abp.setting.values['PortalSettings.ClientId'],
        redirect_uri: abp.setting.values['PortalSettings.RedirectUri'],
        post_logout_redirect_uri: abp.setting.values['PortalSettings.PostLogoutRedirectUri'],
        response_type: 'code',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true,
        silent_redirect_uri: abp.setting.values['ClientRootAddress'].lastIndexOf('/') === abp.setting.values['ClientRootAddress'].length - 1 ? abp.setting.values['ClientRootAddress'] + 'renew-callback.html' : abp.setting.values['ClientRootAddress'] + '/renew-callback.html',
        scope: abp.setting.values['PortalSettings.scope'],
        revokeAccessTokenOnSignout: true,
        monitorSession: true,
    };
}
