import {
    PermissionCheckerService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    AbpMultiTenancyService,
    NotifyService,
    SettingService
} from 'abp-ng2-module';
import { Injector, Output } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { EmailServiceProxy, UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ActionModalComponentComponent } from '../../app/shared/common/elevate-widgets/action-modal-component/action-modal-component.component';
import { IdentityAuthProviderService } from '../../app/shared/common/auth/identity-auth-provider.service';

export abstract class AppComponentBase {
    configModal: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        animated: true,
        class: 'w-350px mt-48'
    };

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: IdentityAuthProviderService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    private ngxSpinnerTextService: NgxSpinnerTextService;
    emailService: EmailServiceProxy;
    modalService: BsModalService;
    bsModalRef: any;

    constructor(injector: Injector) {
        this.modalService = injector.get(BsModalService);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(IdentityAuthProviderService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.emailService = injector.get(EmailServiceProxy);
    }

    updateClaimsIdsvr(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.permission.getUserInfo(() => {
                resolve();
            }, true);
        });
    }


    flattenDeep(array) {
        return array.reduce((acc, val) =>
            Array.isArray(val) ?
                acc.concat(this.flattenDeep(val)) :
                acc.concat(val),
            []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    openModalWithComponentGlobal(title, message, butttoname, imageNeeded?: boolean, actionButton?: string, callback?, textAlignLeft = false, messages?: string[], withToggle?: boolean) {
        const initialState = {
            list: [],
            title: title
        };
        this.bsModalRef = this.modalService.show(ActionModalComponentComponent, this.configModal);
        this.bsModalRef.content.closeBtnName = butttoname || 'Close';
        this.bsModalRef.content.title = title;
        this.bsModalRef.content.actionButtonName = actionButton;
        this.bsModalRef.content.icon = imageNeeded ? 'https://elevateme.blob.core.windows.net/publicimages/659889.svg' : null;
        this.bsModalRef.content.textAlignLeft = textAlignLeft;
        this.bsModalRef.content.message = message;
        this.bsModalRef.content.messages = messages;
        this.bsModalRef.content.withToggle = withToggle;

        this.bsModalRef.content.actionEvent.subscribe(res => {
            if (!callback) { return; }
            callback(res);
        });
    }

    isLoggedInAuth(): boolean {
        return true;
    }

    isMobileView_New() {
        if (window.innerWidth < 768) {
            return true;
        } else {
            return false;
        }
    }


    getDeepCopied(param): any {
        return JSON.parse(JSON.stringify(param));
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'kt-container kt-container--fluid';
        }

        return 'kt-container';
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.currentText = text;
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }
}
