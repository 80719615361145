<!-- BEGIN: Topbar -->
<div class="cust-header-container">
    <div class="cust-header">
        <div class="row align-items-center">
            <div class="mr-auto col-auto">
                <h3 class="cust-title">
                    <span>{{"MyBrokerPortal" | localize | uppercase}}</span>                    
                </h3>
                <span class="cust-sub">{{"HelpingYouLiveLife" | localize}}</span>
            </div>
        </div>
    </div>
</div>


<div class="kt-header__topbar">    
    <div class="kt-header__topbar-item kt-header__topbar-item--user ng-star-inserted  dropdown" dropdown
        placement="bottom-right">
        <div class="kt-header__topbar-wrapper dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
            aria-haspopup="true" dropdownToggle>            
            <div class="kt-header__topbar-user">                
                <span class="kt-header__topbar-username kt-hidden-mobile cust-username">
                    HI {{userName | uppercase}}
                </span>
                <img *ngIf="profilePicture" class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                    alt="Pic" [src]="profilePicture">
            </div>
        </div>
        <div *dropdownMenu
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            x-placement="bottom-right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1253px, 64px, 0px);">            
            <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x gradient">
                <div class="kt-user-card__avatar">
                    <img class="" alt="Pic" [src]="profilePicture">
                </div>
                <div class="kt-user-card__name">
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
                </div>                
            </div>
            <div class="kt-notification user-profile-menu">
                
                <a href="javascript:;" class="kt-notification__item" (click)="changePassword()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-more-v6 gray"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold gray">
                            <span>
                                {{"ChangePassword" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="changeProfilePicture()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-profile-1 gray"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold gray">
                            <span>
                                {{"ChangeProfilePicture" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="changeMySettings()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-cogwheel gray"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold gray">
                            <span>
                                {{"MyProfile" | localize}}
                            </span>
                        </div>
                    </div>
                </a>               
                <div class="kt-notification__custom">
                    <button (click)="logout()" class="btn btn-primary kt-btn--pill btn-pill btn-gradient-clr"><small class="font-weight-bold">{{"Logout" | localize}}</small></button>
                </div>
            </div>
        </div>
    </div>    
</div>
<!-- END: Topbar -->