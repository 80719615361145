import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityAuthProviderService } from '../../app/shared/common/auth/identity-auth-provider.service';

@Component({
  selector: 'app-check-auth',
  templateUrl: './check-auth.component.html',
  styleUrls: ['./check-auth.component.css']
})
export class CheckAuthComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute, private authService: IdentityAuthProviderService) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.authService.getUserStatus(() => {
                this.router.navigate([localStorage.getItem('nextLink') ? localStorage.getItem('nextLink') : '/app/main/dashboard']);
            });
        }, 150);
  }

}
