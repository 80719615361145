import { Inject, Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { HttpMethodBaseService } from './HttpMethodsBase/httpmethods-base';
import { HttpHeaders } from '@angular/common/http';
import { PersonModule, PoliciesModule, RatingFactorModule } from '../models/elevate-data-models';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

@Injectable({ providedIn: 'root' })
export class PolicyService {
    private policyService = new Map();
    readonly ELEVATE_ACCP_ENDPOINT = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];
    readonly API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
    beneficiaryList = [];

    constructor(
        private httpMethodBaseService: HttpMethodBaseService,
        @Inject(API_BASE_URL) private readonly API_BASE_URL
    ) { }

    personRatingFactor = new RatingFactorModule.PersonRatingFactor(undefined, null, null, null, null);
    getPolicySets(policyList: PersonModule.IPolicyAPI[]): PoliciesModule.IPolicyModel[] {
        let responseData: PoliciesModule.IPolicyModel[] = [];


        policyList.forEach(x => {
            this.getPolicy(x.Identifier).subscribe((result: PoliciesModule.IPolicyModel) => {
                if (result) {
                    responseData.push(result);
                }
            });
        });


        return responseData;
    }

    async updatePersonAsync(entityId) {
        let res = await this.getPerson(entityId, false).toPromise();
        this.personRatingFactor = new RatingFactorModule.PersonRatingFactorFactory()
            .withPersonMetaData(res)
            .getAge()
            .getSmoker()
            .getOccupational()
            .getGender()
            .getSocials()
            .build();
    }

    getPolicy(policyUid: string, cachedResult = true): Observable<PoliciesModule.IPolicyModel> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPolicy.replace('{PolicyGUID}', policyUid);
        const cachedPolicyData = this.policyService.get(_url);
        if (cachedPolicyData && cachedResult) { return of(cachedPolicyData); }
        let response = this.httpMethodBaseService.get<PoliciesModule.IPolicyModel>(_url)
            .pipe(map((res: PoliciesModule.IPolicyModel) => {
                this.policyService.set(_url, res);
                return res;
            }));
        return response;
    }

    isSubmitted(policyStatusName: string): boolean {
        const submittedStatuses = [
            'Declined',
            'Approved',
            'Referred',
            'ApprovedWithExclusion',
            'ApprovedWithPostponement',
            'ApprovedWithPostponementAndExclusion',
            'ApprovedWithLoading',
            'ApprovedWithLoadingAndExclusion',
            'ApprovedWithLoadingAndExclusionAndPostponement',
            'ApprovedWithLoadingAndPostponement'
        ];

        return submittedStatuses.includes(policyStatusName);
    }

    getBroker(idpGuid: string): Observable<PersonModule.IBrokerModel> {
        const url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getBroker.replace('{Broker_IDP_GUID}', idpGuid);
        return this.httpMethodBaseService.get<PersonModule.IBrokerModel>(url);
    }

    editBroker(broker: PersonModule.IBrokerModel, adminGuid: string): Observable<{}> {
        const url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.putBroker.replace('{PersonGUID}', adminGuid);
        return this.httpMethodBaseService.put(url, broker);
    }

    getPerson(personId: string, isCaching = true): Observable<PersonModule.IPersonModel> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPerson.replace('{GUID}', personId);
        const conditionsFromCache = this.policyService.get(_url);
        if (conditionsFromCache && isCaching) { return of(conditionsFromCache); }
        let response = this.httpMethodBaseService.get<PersonModule.IPersonModel>(_url)
            .pipe(map((res: PersonModule.IPersonModel) => {
                this.policyService.set(_url, res);
                return res;
            }));
        return response;
    }

    createOrEditPerson(person: PersonModule.IPersonInput, personId): Observable<any> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPerson.replace('{GUID}', personId);
        return this.httpMethodBaseService.put(_url, person);
    }


    queuePolicyToCrm(policyId) {
        let _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicy + policyId;
        return this.httpMethodBaseService.post(_url);
    }

    queuePolicyReferencesEmail(policyGuid, policyReferences, policyStatusName, lifeAssuredGuid, premiumPayerGuid, brokerGuid): Observable<boolean> {
        let headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });

        let body = {
            policyGuid,
            policyReferences: JSON.stringify(policyReferences, null, 1),
            policyStatusName,
            lifeAssuredGuid,
            premiumPayerGuid,
            brokerGuid
        };

        const url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicyReferencesMail;
        return this.httpMethodBaseService.post(url, body, headers);
    }

    queueMedicalReferralEmail(userId, brokerId): Observable<boolean> {
        let body = {
            userId,
            brokerId,
            template: 'MedicalReferralTemplate'
        };

        return this.httpMethodBaseService
        .post<{ result: boolean }>(`${this.API_BASE_URL}/api/services/app/Referral/QueueMedicalReferralEmail`, body)
        .pipe(map(res => res.result));
    }

    queuePolicyForEmailNotification(emailid, policyId, contract, policyNumber, userName, accessToken, senderGuid: string) {
        let headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        });

        let body = {
            email: emailid,
            policyId: policyId,
            contract: contract,
            policyNumber: policyNumber,
            userFirstName: userName,
            includeIntermediaryDocument: false,
            senderGuid
        };

        let _url = this.API_BASE_URL_IDP + AppConsts.Endpoints.queuePolicyForEmailNotification.replace('{EMAIL}', emailid).replace('{POLICY_ID}', policyId);
        return this.httpMethodBaseService.post(_url, body, headers);
    }

    getPolicyContract(policyId) {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getContract.replace('{policyId}', policyId);
        return this.httpMethodBaseService.get(_url).pipe(map((res: any) => {
            return res.Result;
        }));
    }


    getAPerson(personId: string, isCaching?: boolean): Observable<PersonModule.IPersonModel> {
        return this.getPerson(personId, isCaching)
            .pipe(map(res => {
                return res;
            }));
    }

    setBeneficiaryLisT(data: any) {
        this.beneficiaryList = data;
    }

    getPoliciesForLifeAssured(lifeAssuredGuid: string, accessToken: string): Observable<Array<PoliciesModule.IPolicy>> {
        return this.httpMethodBaseService
            .get<{ result: Array<PoliciesModule.IPolicy> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPoliciesForLifeAssured?lifeAssuredGuid=${lifeAssuredGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getPoliciesForPremiumPayer(premiumPayerGuid: string, accessToken: string): Observable<Array<PoliciesModule.IPolicy>> {
        return this.httpMethodBaseService
            .get<{ result: Array<PoliciesModule.IPolicy> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPoliciesForPremiumPayer?premiumPayerGuid=${premiumPayerGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getPoliciesForBroker(brokerGuid: string, accessToken: string): Observable<Array<PoliciesModule.IPolicy>> {
        return this.httpMethodBaseService
            .get<{ result: Array<PoliciesModule.IPolicy> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPoliciesForBroker?brokerGuid=${brokerGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getPolicyFromRates(policyOrQuoteGuid: string, accessToken: string): Observable<PoliciesModule.IPolicy> {
        return this.httpMethodBaseService
            .get<{ result: PoliciesModule.IPolicy }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPolicy?policyOrQuoteGuid=${policyOrQuoteGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getLifeAssuredGuidsForBroker(brokerGuid: string, accessToken: string): Observable<Array<string>> {
        return this.httpMethodBaseService
            .get<{ result: Array<string> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetLifeAssuredGuidsForBroker?brokerGuid=${brokerGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    getPremiumPayerGuidsForBroker(brokerGuid: string, accessToken: string): Observable<Array<string>> {
        return this.httpMethodBaseService
            .get<{ result: Array<string> }>(`${this.API_BASE_URL}/api/services/app/Policy/GetPremiumPayerGuidsForBroker?brokerGuid=${brokerGuid}&accessToken=${accessToken}`)
            .pipe(map(res => res.result));
    }

    savePolicyCession(policyCession: PoliciesModule.IPolicyCession): Observable<PoliciesModule.IPolicyCession> {
        return this.httpMethodBaseService
            .post<{ result: PoliciesModule.IPolicyCession }>(`${this.API_BASE_URL}/api/services/app/Policy/SavePolicyCession`, policyCession)
            .pipe(map(res => res.result));
    }

    savePolicyRAR(policyCession: PoliciesModule.IPolicyRAR): Observable<PoliciesModule.IPolicyRAR> {
        return this.httpMethodBaseService
            .post<{ result: PoliciesModule.IPolicyRAR }>(`${this.API_BASE_URL}/api/services/app/Policy/SavePolicyRAR`, policyCession)
            .pipe(map(res => res.result));
    }

    updateBeneficiaries(policyGuid: string, productGuid: string, effectiveDate: Date, beneficiaries: Array<{
        AllocationPercentage: number,
        BeneficiaryAssuredType: string,
        Person: {
            Identifier: string
        }
    }>): Observable<any> {
        let _url = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.updateBeneficiaries.replace('{PolicyGUID}', policyGuid).replace('{ProductGUID}', productGuid);

        const body = {
            EffectiveDate: effectiveDate,
            Beneficiarys: beneficiaries
        };

        let _cachedUrl = this.ELEVATE_ACCP_ENDPOINT + AppConsts.ElevateMendixEndpoints.getPolicy.replace('{PolicyGUID}', policyGuid);
        this.policyService.delete(_cachedUrl);

        return this.httpMethodBaseService.put(_url, body).pipe(map((res: any) => {
            return res.Result;
        }));
    }
}
