import { RateTableEntry } from './RateTableEntry';
import { BenefitPattern } from '../BenefitPatternFactory/BenefitPattern';
import { Injector } from '@angular/core';
import { PremiumPattern } from '../PremiumPatternFactory/PremiumPattern';
import { ProductService } from '@app/shared/services/product.service';
import { RateTableEntriesFlatModule } from '../../models/elevate-data-models';
import { RatingFactorInputResponseModule } from '../../models/elevate-data-models';
import { PolicyService } from '../../services/policy.service';
import { MyCommandCenterService } from '@app/main/command-center/my-command-center.service';

export interface IIncompleteRateTableEntry {
    withMetaData(productId: string, lowerLimit: number, upperLimit: number, age: string): Promise<IIncompleteRateTableEntry>;
    addRateEntry(productId: string, sumAssureLimit: number, age: string): Promise<IIncompleteRateTableEntry>;
    extractRateTableEntryUIDList(): string[];
    forRateTableEntry(UID: string): IIncompleteRateTableEntry;
    extractUID(): IIncompleteRateTableEntry;
    extractBenefitPattern(benefitPatternSet: BenefitPattern[]): IIncompleteRateTableEntry;
    extractPremiumPattern(premiumPatternSet: PremiumPattern[]): IIncompleteRateTableEntry;
    extractBaseRate(): IIncompleteRateTableEntry;
    extractUnitRate(): IIncompleteRateTableEntry;
    extractSumAssuredLimit(): IIncompleteRateTableEntry;
    build(): RateTableEntry;
}

export class RateTableEntryFactory implements IIncompleteRateTableEntry {
    metaData: RateTableEntriesFlatModule.RateTableEntries[];

    RateEntry: RateTableEntriesFlatModule.RateTableEntries;
    UID: string;
    BenfitPattern: BenefitPattern;
    PremiumPattern: PremiumPattern;
    BaseRate: number;
    UnitRate: number;


    private _productService: ProductService;
    sumAssuredLimit: number;

    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    private _policyService: PolicyService;

    public get policyService(): PolicyService {
        if (!this._policyService) {
            this._policyService = this.injector.get(PolicyService);
        }
        return this._policyService;
    }

    constructor(private injector: Injector) {

    }

    private getRatingFactors(sumAssuredLimit: number | null = null, age: string): RatingFactorInputResponseModule.RatingFactor[] {
        let userProfile = this.policyService.personRatingFactor;

        let ratingFactors = new Array<RatingFactorInputResponseModule.RatingFactor>();

        ratingFactors.push({
            Description: age,
            RatingFactorType: 'Age'
        });
        ratingFactors.push({
            Description: userProfile.smoker === 'Smoker' ? 'Smoker' : 'Non_Smoker',
            RatingFactorType: 'SmokingStatus'
        });
        ratingFactors.push({
            Description: userProfile.occupational || 'Class_1',
            RatingFactorType: 'OccupationCategory'
        });
        ratingFactors.push({
            Description: userProfile.socials || 'Class_1',
            RatingFactorType: 'SocioEconomicClass'
        });
        ratingFactors.push({
            Description: userProfile.gender,
            RatingFactorType: 'Gender'
        });

        if (sumAssuredLimit !== null) {
            ratingFactors.push({
                Description: sumAssuredLimit.toString(),
                RatingFactorType: 'SumAssuredLimit'
            });
        }

        return ratingFactors;
    }

    async addRateEntry(productId: string, sumAssuredLimit: number, age: string): Promise<IIncompleteRateTableEntry> {
        try {
            if (!this.metaData) {
                throw new Error('No rate table instance');
            }

            const ratesToAdd = await this.productService.getRateTableForProduct(productId, this.getRatingFactors(sumAssuredLimit, age)).toPromise();
            this.metaData.push(...ratesToAdd);
            return this;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    async withMetaData(productId: string, lowerLimit: number, upperLimit: number, age: string): Promise<IIncompleteRateTableEntry> {
        try {
            this.metaData = await this.productService.getRateTableForProduct(productId, this.getRatingFactors(null, age), lowerLimit, upperLimit).toPromise();
            return this;
        } catch (error) {
            abp.message.error('Please Contact Support, as we could not assist at present.');
            abp.ui.clearBusy();
            throw new Error(error);
        }
    }

    extractRateTableEntryUIDList(): string[] {
        try {
            return this.metaData.map(({ UID }) => ({ UID }.UID));
        } catch (error) {
            alert(JSON.stringify(error));
            throw new Error(error);
        }
    }

    forRateTableEntry(UID: string): IIncompleteRateTableEntry {
        this.RateEntry = this.metaData.find(x => x.UID === UID);
        return this;
    }

    extractUID(): IIncompleteRateTableEntry {
        this.UID = this.RateEntry.UID;
        return this;
    }

    extractSumAssuredLimit(): IIncompleteRateTableEntry {
        this.sumAssuredLimit = Number.parseInt(this.RateEntry.RatingFactor_SumAssuredLimit_Description);
        return this;
    }


    extractBenefitPattern(benefitPatternSet: BenefitPattern[]): IIncompleteRateTableEntry {
        if (!this.RateEntry.BenefitPatternGUID) {
            abp.message.error('Please Contact Support, as we could not assist at present. Error Details extractName');
            abp.ui.clearBusy();
        }
        this.BenfitPattern = benefitPatternSet.find(item => item.UID === this.RateEntry.BenefitPatternGUID);
        return this;
    }
    extractPremiumPattern(premiumPatternSet: PremiumPattern[]): IIncompleteRateTableEntry {
        if (!this.RateEntry.PremiumPatternGUID) {
            throw new Error('Method not implemented.');
        }
        this.PremiumPattern = premiumPatternSet.find(item => item.UID === this.RateEntry.PremiumPatternGUID);
        return this;
    }
    extractBaseRate(): IIncompleteRateTableEntry {
        if (!this.RateEntry.BaseRate) {
            throw new Error('Method not implemented.');
        }
        this.BaseRate = this.RateEntry.BaseRate;
        return this;
    }
    extractUnitRate(): IIncompleteRateTableEntry {
        if (!this.RateEntry.ProductOfFactors) {
            throw new Error('Method not implemented.');
        }
        this.UnitRate = this.RateEntry.ProductOfFactors;
        return this;
    }
    build(): RateTableEntry {
        return new RateTableEntry(
            this.UID,
            this.BenfitPattern,
            this.PremiumPattern,
            this.BaseRate,
            this.UnitRate,
            this.sumAssuredLimit);
    }

}
