import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { UserDelegationsModalComponent } from '@app/shared/layout/user-delegations-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { QuirkService } from './shared/services/quirk.service';
import { MyCommandCenterService } from './main/command-center/my-command-center.service';
import './shared/extensions/common-extensions';
import './shared/extensions/my-policies-extensions';
export const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

export function changeTemplatePopupGlobal(item: string, item1: string, forceRed: boolean = false) {
    let iconDiv = document.getElementsByClassName('swal2-icon-show');
        if (iconDiv && iconDiv[0].classList.contains('swal2-icon-show')) {
            iconDiv[0].innerHTML = '';
            (iconDiv[0] as any).style = 'display: none;';
            iconDiv[0].classList.remove('swal2-icon-show');

            let imageDiv = document.getElementsByClassName('swal2-image');

            if (imageDiv) {
                (imageDiv[0] as any).style = '';
                imageDiv[0].classList.add('swal2-icon');
                (imageDiv[0] as any).src  = `src="../../../../assets/common/images/location_exclamation${forceRed ? '_red' : ''}.svg`;
                imageDiv[0].classList.remove('swal2-image');
            }
        }

    if (item !== undefined) {
        (document.getElementsByClassName('swal2-confirm')[0] as any).innerText = item;
        document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('btn-primary');

        if (forceRed) {
            document.getElementsByClassName('swal2-confirm')[0].classList.add('bg-cust-portal-red');
        }
    } else {
        document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
    }

    if (item1 !== undefined) {
        (document.getElementsByClassName('swal2-cancel')[0] as any).innerText = item1;
        document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
    } else {
        document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
    }

}

export function isMobileView_NewGlobal() {
    if (window.innerWidth < 768) {
        return true;
    } else {
        return false;
    }
}


@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit {

    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;

    @ViewChild('loginAttemptsModal', { static: true }) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('userDelegationsModal', { static: true }) userDelegationsModal: UserDelegationsModalComponent;
    @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', { static: true }) mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal', { static: true }) notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('chatBarComponent') chatBarComponent;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && this.appSession.userId != null;

    public constructor(
        injector: Injector,
        private _chatSignalrService: ChatSignalrService,
        private _userNotificationHelper: UserNotificationHelper,
        private quirkService: QuirkService,
        private commandService: MyCommandCenterService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        //this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.quirkService.init();
        this.registerModalOpenEvents();
        //if (this.appSession.application) {
        //    SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        //}
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.linkedAccountsModal', () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on('app.show.userDelegationsModal', () => {
            this.userDelegationsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }
}
