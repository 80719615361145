import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable } from '@node_modules/rxjs/internal/Observable';
import { of, Subject } from 'rxjs';
import { IdentityAuthProviderService } from './identity-auth-provider.service';
import { User } from 'oidc-client';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {

    public user: User;


    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _refreshTokenService: RefreshTokenService,
        private _identityAuthService: IdentityAuthProviderService
    ) { }


    checkIfRouteActive(route): boolean {
        if (this._identityAuthService.userPermission) {
            return this._identityAuthService.userPermission.indexOf((route as any).permission) >= 0;
        } else {
            return false;
        }
    }


    canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        if (state.url.indexOf('auth-callback') >= 0 && state.url.indexOf('check-auth') >= 0) {

        } else {
            localStorage.setItem('nextLink', state.url);
        }
        if (this._identityAuthService.isLoggedIn()) {
            this.user = this._identityAuthService.getClaims();
            return of(true) && of(this.checkIfRouteActive(data));
        } else {
            localStorage.setItem('nextLink', state.url);
            this._router.navigate(['/check-auth']);
        }
        return of(false);
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
            return '/app/admin/hostDashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
            return '/app/main/dashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }

        return '/app/notifications';
    }
}
