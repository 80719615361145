<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__body  kt-portlet__body--fit" [perfectScrollbar]>
        <div class="row row-no-padding row-col-separator-xl">
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24 new-subscription-statistics">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"NewSubscriptionAmount" | localize}}
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"Subscriptions" | localize}}
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt-font-brand">
                            <span *ngIf="!topStatsData">...</span>
                            <span *ngIf="topStatsData" counto [step]="30"
                                [countTo]="topStatsData.newSubscriptionAmount" [countFrom]="0"
                                [duration]="5"
                                (countoChange)="countoNewSubscriptionAmount = $event">{{countoNewSubscriptionAmount | currency:'USD':'symbol':'1.0-0'}}</span>
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-brand" role="progressbar" style="width: 100%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            <div class="status-title">
                                {{selectedDateRange[0] | momentFormat: 'L'}} -
                                {{selectedDateRange[1] | momentFormat: 'L'}}
                            </div>
                        </span>
                        <span class="kt-widget24__number">
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24 new-tenants-statistics">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"NewTenants" | localize}}
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"Tenants" | localize}}
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt-font-info new-tenants-count">
                            <span *ngIf="!topStatsData">...</span>
                            <span *ngIf="topStatsData" counto [step]="10"
                                [countTo]="topStatsData.newTenantsCount" [countFrom]="0"
                                [duration]="5"
                                (countoChange)="countoNewTenantsCount = $event">{{countoNewTenantsCount | number:'1.0-0' }}</span>
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-info" role="progressbar" style="width: 100%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            <div class="status-title">
                                {{selectedDateRange[0] | momentFormat: 'L'}} -
                                {{selectedDateRange[1] | momentFormat: 'L'}}
                            </div>
                        </span>
                        <span class="kt-widget24__number">
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24 dashboard-statistics1">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"DashboardSampleStatistics" | localize}} 1
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"DashboardSampleStatistics" | localize}}
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt-font-danger dashboard-placeholder1">
                            <span *ngIf="!topStatsData">...</span>
                            <span *ngIf="topStatsData" counto [step]="100"
                                [countTo]="topStatsData.dashboardPlaceholder1" [countFrom]="0"
                                [duration]="5"
                                (countoChange)="countoDashboardPlaceholder1 = $event">{{countoDashboardPlaceholder1 | number:'1.0-0' }}</span>
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-danger" role="progressbar" style="width: 45%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            {{"DashboardSampleStatisticsHelpText" | localize}}
                        </span>
                        <span class="kt-widget24__number">
                            <span class="counterup">45</span>%
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24 dashboard-statistics2">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"DashboardSampleStatistics" | localize}} 2
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"DashboardSampleStatistics" | localize}}
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt-font-success dashboard-placeholder2">
                            <span *ngIf="!topStatsData">...</span>
                            <span *ngIf="topStatsData" counto [step]="100"
                                [countTo]="topStatsData.dashboardPlaceholder2" [countFrom]="0"
                                [duration]="5"
                                (countoChange)="countoDashboardPlaceholder2 = $event">{{countoDashboardPlaceholder2 | number:'1.0-0' }}</span>
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-success" role="progressbar" style="width: 65%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            {{"DashboardSampleStatisticsHelpText" | localize}}
                        </span>
                        <span class="kt-widget24__number">
                            <span class="counterup">65</span>%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>