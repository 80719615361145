<footer class="kt-grid__item kt-footer h60">

    <div class="m-container--fluid" style="margin-top: 0 !important;">

        <div class="row">

            <div class="col-md-6">

            </div>

            <div class="col-md-6 d-flex flex-row justify-content-end align-items-center top-3">

                <h6 class="small font-weight-bold mb-0">
                    All Elevate Life policies are underwritten by Centriq Life Insurance
                    Company Ltd. ("Centriq"), an authorised financial services provider (FSP 7370) and licensed
                    long-term insurer
                </h6>

                <img src="../../../assets/common/images/Life Logo Colour on White.gif" />
            </div>
        </div>
    </div>
</footer>
