import { IBeneficiaryList, IProductInformationList, PersonModule, PoliciesModule, ProductListModule } from '../models/elevate-data-models';

declare global {
    export interface Array<T> {
        fetchAllPolicies(): Array<PersonModule.IPolicyAPI>;
        deleteDuplicatePolicyIds(): Array<PersonModule.IPolicyAPI>;
        getProductsArray(productsList: Array<ProductListModule.IProductListModel>): Array<PoliciesModule.IUniqueProducts>;
        deleteDuplicateProducts(): Array<PoliciesModule.IUniqueProducts>;
        fetchProductInformation(productsList: Array<ProductListModule.IProductListModel>): Array<IProductInformationList>;
        deleteDuplicateProductInformations(): Array<IProductInformationList>;
        fetchBeneficiariesList(productsList: Array<ProductListModule.IProductListModel>): Array<IBeneficiaryList>;
        fetchAllIdentifiers(): Array<string>;
        deleteDuplicateProductData(): Array<IBeneficiaryList>;
    }

    export interface String {
        getFormatedProductName(): string;
        getIndexAsPerOccurence(substring, occurrence): number;
    }

}

String.prototype.getFormatedProductName = function () {
    let _self = this as String;
    let firstIndex = _self.getIndexAsPerOccurence('_', 2);
    let secondIndex = _self.getIndexAsPerOccurence('_', 3);
    return _self.substr(firstIndex + 1, secondIndex - (firstIndex + 1)).replace(/([a-z])([A-Z])/g, '$1 $2');
};

String.prototype.getIndexAsPerOccurence = function (subString, index) {
    return this.split(subString, index).join(subString).length;
};


Array.prototype.fetchAllPolicies = function () {
    let _self = this as any as Array<PersonModule.ICoverInstanceAPI>;
    let PolicyData: PersonModule.IPolicyAPI[] = [];
    _self.forEach(policyData => {
        if (policyData.ProductSelectedAPI.ProductPackageAPI.PolicyAPIs[0] && policyData.Premium > 0) {
            PolicyData.push(policyData.ProductSelectedAPI.ProductPackageAPI.PolicyAPIs[0]);
        }
    });
    return PolicyData;
};

Array.prototype.deleteDuplicatePolicyIds = function () {
    let _self = this as any as Array<PersonModule.IPolicyAPI>;
    let filteredPolicyData = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.Identifier === element.Identifier)
    );
    return filteredPolicyData;
};

Array.prototype.getProductsArray = function (productsList) {
    let _self = this as any as Array<PoliciesModule.IProductPackageAPI>;
    let productSelectedArray: PoliciesModule.IProductSelectedAPI2[] = [];
    let productArray: PoliciesModule.IUniqueProducts[] = [];
    _self.forEach(productPackage => {
        if (!(productPackage.ProductPackageStatus.toLowerCase().includes('closed'))) {
            let premiumPayerRole = productPackage.RoleAPIs.find(x => x.RoleType.toLowerCase().includes('premium_payer') && x.RoleStatus.toLowerCase().includes('active'));
            let lifeAssuredRole = productPackage.RoleAPIs.find(x => x.RoleType.toLowerCase().includes('life_assured') && x.RoleStatus.toLowerCase().includes('active'));
            let premium = 0;
            let coverAmount = 0;
            let productName = '';
            let productId = '';
            productPackage.ProductSelectedAPIs.forEach(productDetails => {
                let UIDList = productDetails.CoverInstanceAPIs.fetchAllIdentifiers();

                coverAmount = lifeAssuredRole.CoverInstanceAPIs.find(object => UIDList.includes(object.Identifier)).Value;
                premium = premiumPayerRole.CoverInstanceAPIs.find(object => UIDList.includes(object.Identifier)).Premium;

                if (productDetails.Product.ProductConfig) {
                    productId = productDetails.Product.ProductConfig.UID;

                    let product = productsList.find(x => x.UID === productId);
                    if (product) {
                        productName = product.DisplayName;
                    }
                }
                productArray.push({ productId: productId, productName: productName, PremiumAmount: premium, CoverAmount: coverAmount });
            });
        }
    });
    return productArray;
};


Array.prototype.deleteDuplicateProducts = function () {
    let _self = this as any as Array<PoliciesModule.IUniqueProducts>;
    let filteredProductsData = _self.filter((element, index, array) =>
        index === array.findIndex((data) =>
            data.productId === element.productId)
    );
    return filteredProductsData;
};

Array.prototype.fetchProductInformation = function (productsList) {
    let _self = this as any as Array<PoliciesModule.IProductPackageAPI>;
    let productInformationList: IProductInformationList[] = [];
    let coverAmount = 0;
    let premium = 0;
    _self.forEach(productPackage => {
        if (!(productPackage.ProductPackageStatus.toLowerCase().includes('closed'))) {
            productPackage.ProductSelectedAPIs.forEach(selectedProduct => {
                let premiumPayerRole = productPackage.RoleAPIs.find(x => x.RoleType.toLowerCase().includes('premium_payer') && x.RoleStatus.toLowerCase().includes('active'));
                let lifeAssuredRole = productPackage.RoleAPIs.find(x => x.RoleType.toLowerCase().includes('life_assured') && x.RoleStatus.toLowerCase().includes('active'));
                let UIDList = selectedProduct.CoverInstanceAPIs.fetchAllIdentifiers();

                coverAmount = lifeAssuredRole.CoverInstanceAPIs.find(object => UIDList.includes(object.Identifier)).Value;
                premium = premiumPayerRole.CoverInstanceAPIs.find(object => UIDList.includes(object.Identifier)).Premium;

                let benefitIncreaseIndex = selectedProduct.BespokeProductAPIs[0].BenefitPattern.BeforeClaimEscalation;
                let benefitTerm = selectedProduct.BespokeProductAPIs[0].BenefitPattern.CoverInForceCeaseAge;
                let benefitIncrease = selectedProduct.BespokeProductAPIs[0].BenefitPattern.BeforeClaimEscalation_2.BCE_Fixed ? selectedProduct.BespokeProductAPIs[0].BenefitPattern.BeforeClaimEscalation_2.BCE_Fixed.BCOverallEscalation.toString() : '0';
                let premiumIncreaseIndex = selectedProduct.BespokeProductAPIs[0].PremiumPattern.FixedIncreasePremium ? selectedProduct.BespokeProductAPIs[0].PremiumPattern.PremiumEscalationType :
                    selectedProduct.BespokeProductAPIs[0].PremiumPattern.IndexLinkedPremium ? selectedProduct.BespokeProductAPIs[0].PremiumPattern.IndexLinkedPremium.Index.Name : selectedProduct.BespokeProductAPIs[0].PremiumPattern.PremiumEscalationType.toString();
                let premiumIncreases = selectedProduct.BespokeProductAPIs[0].PremiumPattern.FixedIncreasePremium ? selectedProduct.BespokeProductAPIs[0].PremiumPattern.FixedIncreasePremium.OverallEscalation.toString() :
                    selectedProduct.BespokeProductAPIs[0].PremiumPattern.IndexLinkedPremium ? selectedProduct.BespokeProductAPIs[0].PremiumPattern.IndexLinkedPremium.AdditionalEscalation.toString() : '0';
                let premiumTerm = selectedProduct.BespokeProductAPIs[0].PremiumPattern.CeaseAge;

                let productName = '';
                let productId = '';
                if (selectedProduct.Product.ProductConfig) {
                    productId = selectedProduct.Product.ProductConfig.UID;

                    let product = productsList.find(x => x.UID === productId);
                    if (product) {
                        productName = product.DisplayName;
                    } else {
                        productName = selectedProduct.Product.ProductConfig.DisplayName;
                    }
                }

                productInformationList.push({
                    productId: productId,
                    productName: productName,
                    coverAmount: coverAmount,
                    benefitIncreaseIndex: benefitIncreaseIndex,
                    benefitIncreases: benefitIncrease,
                    benefitTerm: benefitTerm,
                    premiumIncreaseIndex: premiumIncreaseIndex,
                    premiumInstallment: premium,
                    premiumIncreases: premiumIncreases,
                    premiumTerm: premiumTerm
                });
            });
        }
    });

    return productInformationList;
};

Array.prototype.fetchBeneficiariesList = function (productsList) {
    let _self = this as any as Array<PoliciesModule.IProductPackageAPI>;
    let beneficiariesList: IBeneficiaryList[] = [];
    let coverAmount = 0;
    let beneficiaryPercentage = 0;
    let beneficiaryId = '';
    let beneficiaryName = '';
    let coveredAmount = 0;
    let productName = '';
    let productId = '';

    _self.forEach(productPackage => {
        if (!(productPackage.ProductPackageStatus.toLowerCase().includes('closed'))) {
            productPackage.ProductSelectedAPIs.forEach(selectedProduct => {
                let lifeAssuredRole = productPackage.RoleAPIs.find(x => x.RoleType.toLowerCase().includes('life_assured') && x.RoleStatus.toLowerCase().includes('active'));
                let beneficiaryRole = productPackage.RoleAPIs.filter(x => x.RoleType.toLowerCase().includes('beneficiary') && x.RoleStatus.toLowerCase().includes('active'));
                let UIDList = selectedProduct.CoverInstanceAPIs.fetchAllIdentifiers();

                coverAmount = lifeAssuredRole.CoverInstanceAPIs.find(object => UIDList.includes(object.Identifier)).Value;

                UIDList.forEach(UID => {
                    let tempCoverAmount = lifeAssuredRole.CoverInstanceAPIs.find(x => x.Identifier === UID) ? lifeAssuredRole.CoverInstanceAPIs.find(x => x.Identifier === UID).Value : 0;
                    if (tempCoverAmount > 0) {
                        coverAmount = tempCoverAmount;
                    }

                    if (selectedProduct.Product.ProductConfig) {
                        productId = selectedProduct.Product.ProductConfig.UID;
                        let product = productsList.find(x => x.UID === productId);
                        if (product) {
                            productName = product.DisplayName;
                        }
                    }

                    beneficiaryRole.forEach(beneficiary => {
                        let benefit = beneficiary.CoverInstanceAPIs.find(x => x.Identifier === UID) ? beneficiary.CoverInstanceAPIs.find(x => x.Identifier === UID).Value : 0;
                        if (benefit !== 0) {
                            beneficiaryPercentage = benefit;
                            beneficiaryId = beneficiary.Person.Identifier;
                            //beneficiaryName = beneficiary.Person.FriendlyIdentifier;
                            coveredAmount = coverAmount * (beneficiaryPercentage / 100);

                            beneficiariesList.push({
                                beneficiaryId: beneficiaryId,
                                productId: productId,
                                productName: productName,
                                beneficiaryName: beneficiaryName,
                                coveredAmount: coveredAmount,
                                beneficiaryPercentage: beneficiaryPercentage
                            });
                        }
                    });
                });
            });
        }
    });
    return beneficiariesList;
};

Array.prototype.fetchAllIdentifiers = function () {
    let _self = this as any as Array<PoliciesModule.ICoverInstanceAPI2>;
    let uidList: string[] = [];
    _self.forEach(x => {
        uidList.push(x.Identifier);
    });
    return uidList;
};

export { };
