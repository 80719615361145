import { IIncompleteProduct, ProductFactory } from '../ProductFactory/IIncompleteProduct';
import { AssociatedProduct } from './associated-product';
import { Injector } from '@angular/core';
import { Product } from '../ProductFactory/Product';
import { AppConsts } from '../../../../shared/AppConsts';
import { IIncompleteProductBase, ProductBaseFactory } from '../ProductFactory/IIncompleteProductBase';
import { ProductListModule } from '../../models/elevate-data-models';

export interface IIncompleteAssociatProduct extends IIncompleteProductBase {
    withMetadataAsync(generationName: string): Promise<IIncompleteAssociatProduct>;
    forRiderProduct(MetaDataProduct: ProductListModule.IProductListModel): IIncompleteAssociatProduct;
    setCurrentMainProducUID(MainProductUID): IIncompleteAssociatProduct;
    extractRequirementType(): IIncompleteAssociatProduct;
    extractRiderPolicy(): IIncompleteAssociatProduct;
    extractAccelerator(): IIncompleteAssociatProduct;
    build(): AssociatedProduct;
}

export class AssociatedProductFactory extends ProductBaseFactory implements IIncompleteAssociatProduct {
    metaDataProduct: ProductListModule.IProductListModel;
    MainProductUID: string;
    requirementType: string;
    riderPolicy: boolean;
    Accelerator: boolean;
    Product: Product;

    constructor(protected injector: Injector) {
        super(injector);
    }


    async withMetadataAsync(generationName: string): Promise<IIncompleteAssociatProduct> {
        try {
            this.metaData = await this.productService.getProductList(generationName).toPromise();
            return this;
        } catch (err) {

        }
    }
    forRiderProduct(MetaDataProduct: ProductListModule.IProductListModel): IIncompleteAssociatProduct {
        this.metaDataProduct = MetaDataProduct;
        return this;
    }

    setCurrentMainProducUID(MainProductUID): IIncompleteAssociatProduct {
        this.MainProductUID = MainProductUID;
        return this;
    }

    extractRequirementType(): IIncompleteAssociatProduct {
        this.requirementType = this.metaDataProduct.ProductRequirements_2.find(x => x.ProductConfig.UID === this.MainProductUID).RequirementType;
        return this;
    }
    extractRiderPolicy(): IIncompleteAssociatProduct {
        this.riderPolicy = this.metaDataProduct.ProductRequirements_2.find(x => x.ProductConfig.UID === this.MainProductUID).RiderPolicy;
        return this;
    }
    extractAccelerator(): IIncompleteAssociatProduct {
        this.Accelerator = this.metaDataProduct.ProductRequirements_2.find(x => x.ProductConfig.UID === this.MainProductUID).Accelerator;
        return this;
    }


    build(): AssociatedProduct {

        return new AssociatedProduct(
            this.uid,
            this.name,
            this.minBenefitAmount,
            this.maxBenefitAmount,
            this.benefitPatternSet,
            this.premiumPatternSet,
            this.eventSet,
            this.category,
            this.displayName,
            [],
            this.defaultBenefitPattern,
            this.defaultPremiumPattern,
            this.rateTable,
            this.requirementType,
            this.riderPolicy,
            this.Accelerator,
            this.isDisability,
            this.isParentDependent,
            this.dependentParentGuid
        );
    }


}
