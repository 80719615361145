import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdentityAuthProviderService } from '@app/shared/common/auth/identity-auth-provider.service';
import { Observable } from 'rxjs';

@Injectable()
export class VerifyAuthTokenInterceptor implements HttpInterceptor {

  constructor(private authService: IdentityAuthProviderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = '';
    let tokenFound = false;
    token = new URLSearchParams(new URL(req.url).search).get('accessToken');
    if (token) {
        tokenFound = true;
    } else if (req.headers.has('Authorization')) {
        token = req.headers.get('Authorization')?.replace('Bearer ', '');
        if (!token) {
        } else {
            tokenFound = true;
        }
    } else if (this.getTokenFromBody(req.body)) {
        token = this.getTokenFromBody(req.body);
        tokenFound = true;
    }

    if (tokenFound) {
        if (this.tokenExpired(token)) {
            this.authService.getUserStatus(() => { });
        }
    }
    return next.handle(req);
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  private getTokenFromBody(body: any) {
    if (this.isJSON(body)) {
      return JSON.parse(body).accessToken;
    } else if (this.isObject(body)) {
      return body.accessToken;
    }
  }

  private isJSON(str: any) {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
  }

  private isObject(val: any) {
      return val instanceof Array || val instanceof Object;
  }
}
