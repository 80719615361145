import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';
import { IdentityServerService } from '../../app/shared/services/identity-server.service';
import { MyCommandCenterService } from '../../app/main/command-center/my-command-center.service';
import { ProductService } from '../../app/shared/services/product.service';
import { MyDocumentsService } from '@app/main/my-resource-centre/my-documents/my-documents.service';
import { QuoteStatusService } from '@app/shared/services/quote-status.service';
import { VerifyAuthTokenInterceptor } from '@shared/interceptors/verify-auth-token-interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.IdpCLaimsServiceProxy,
        ApiServiceProxies.TokenIdentiyProviderServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.EmailServiceProxy,
        ApiServiceProxies.ComissionServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicParameterServiceProxy,
        ApiServiceProxies.DynamicEntityParameterDefinitionServiceProxy,
        ApiServiceProxies.EntityDynamicParameterServiceProxy,
        ApiServiceProxies.DynamicParameterValueServiceProxy,
        ApiServiceProxies.EntityDynamicParameterValueServiceProxy,
        ApiServiceProxies.NeedsCalculatorServiceProxy,
        MyCommandCenterService,
        IdentityServerService,
        MyDocumentsService,
        ProductService,
        QuoteStatusService,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: VerifyAuthTokenInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
