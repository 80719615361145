import * as _ from 'lodash';
import { GetQuestionariesModule, ProductListModule, BenefitModule, PremiumPatternModule, RateTableModule, AnonymousUserDetail, ClaimsModule } from '../models/elevate-data-models';
import { Product } from '../FactoryResources/ProductFactory/Product';
import { PersonEducation, QuestionType } from '../../../shared/AppEnums';
import { AppConsts } from '../../../shared/AppConsts';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import * as moment from 'moment';
import { NeedsCalculatorx } from '@shared/service-proxies/service-proxies';

declare global {
    export interface Array<T> {
        getQuestionsToBeShown(questions: any): Array<T>;
        getFilterQuestions(questions: any): Array<T>;
        deleteUnWantedQuestions(questions: any): Array<T>;
        getAlreadyPresentedQuestions(): Array<any>;
        checkForAnsweringDND(userDetails: AnonymousUserDetail): GetQuestionariesModule.IQuestion;
        setQuestionType(): Array<GetQuestionariesModule.IQuestion>;
        IsNullOrEmpty(): boolean;
        getParsedMainAndRiderProduct(): ProductListModule.IProductListModel[];
        getPageViewForStepper(): Array<{ productSectionName: string, products: Array<ProductListModule.IProductListModel> }>;
        getPageViewForStepper_new(): Array<{ productSectionName: string, products: Array<Product> }>;
        getLowestBenefitpatternUID(benefitPatternsWithRate): RateTableModule.IRateTableModel;
        getBenefitWithRate(rateTableEntryResponse): BenefitModule.IBenefitPattern[];
        getPremiumWithRate(rateTableEntryResponse): PremiumPatternModule.PremiumPattern[];
        ToJsonParse(): any;
        toBenefitUID(): string[];
        customFlat(): any;
        fetchProductList(): Array<{ productSectionName: string, products: Array<ProductListModule.ISelectedProductDetails> }>;
        getTotalPremiumAmount(): number;
        findReplaceAndPush(element: ClaimsModule.IQuestionAnsType): any[];
        swapItems(a: number, b: number): Array<any>;
    }

    export interface String {
        getGenderFromIdNumber(): string;
        getDateOfBirthByIdNumber(): string;
        getAgeByIdNumber(): number;
        getCurrencyFormatWithourDecimalPlaces(): string;
        getSociaEconomicClass(grosssIncome: number): string;
        replaceSpaceWithUnderscore(): string;
    }
}

String.prototype.getCurrencyFormatWithourDecimalPlaces = function () {
    let item = Number.parseFloat(this);

    let amountCurrency = getAmount(item);
    return TruncateDecimal(amountCurrency);


    function getAmount(
        value: number = 0,
        currencyCode: string = 'ZAR',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '3.2-2',
        locale: string = 'en',

    ) {
        if (value != null) {
            if (value.toString() === '0') {
                return '0.00';
            }

            if (value.toString().indexOf(',') > 0) {
                let zaramount = value.toString();
                while (zaramount.indexOf(',') > 0) {
                    zaramount = zaramount.replace(',', ' ');
                }
                return zaramount;
            }

            if (value < 0) {
                return value.toString();
            }
        }



        let amount = formatCurrency(
            value,
            locale,
            getCurrencySymbol(currencyCode, 'wide'),
            currencyCode,
            digitsInfo,
        );
        let amountWithoutZAR = amount.substr(3);

        while (amountWithoutZAR.indexOf(',') > 0) {
            amountWithoutZAR = amountWithoutZAR.replace(',', ' ');
        }

        if (amountWithoutZAR.startsWith('0')) {
            amountWithoutZAR = eliminateZero(amountWithoutZAR);
        }

        return amountWithoutZAR;
    }


    function eliminateZero(data) {
        if (!data.startsWith('0')) {
            return data;
        } else {
            return eliminateZero(data.substring(1));
        }
    }


    function TruncateDecimal(item) {
        let formattedString = item.split('.');
        return formattedString[0];
    }

    return '';
};

String.prototype.getSociaEconomicClass = function (grosssIncome: number) {
    let self = Number.parseInt(this);
    if (self === undefined && self === null) { return 'Class_1'; }

    if (self === PersonEducation.NoMatric) {
        if (grosssIncome <= 15000) {
            return 'Class_4';
        }

        if (grosssIncome <= 21000) {
            return 'Class_3';
        }

        if (grosssIncome <= 1000000) {
            return 'Class_2';
        }

        return 'Class_1';
    }

    if (PersonEducation.Matric === self) {
        if (grosssIncome <= 6000) {
            return 'Class_4';
        }

        if (grosssIncome <= 15000) {
            return 'Class_3';
        }

        if (grosssIncome <= 45000) {
            return 'Class_2';
        }

        return 'Class_1';
    }

    if (PersonEducation.DiplomaLessThanFourYears === self) {
        if (grosssIncome <= 15000) {
            return 'Class_3';
        }

        if (grosssIncome <= 45000) {
            return 'Class_2';
        }

        return 'Class_1';
    }

    if (PersonEducation.DiplomaFourYearsOrMoreThan === self || PersonEducation.ThreeYearsUniversityUnderGraduateDegree === self) {
        if (grosssIncome <= 6000) {
            return 'Class_3';
        }

        if (grosssIncome <= 21000) {
            return 'Class_2';
        }

        return 'Class_1';
    }

    if (PersonEducation.FourYearsUniversityUnderGraduateDegree === self || PersonEducation.PostGraduateStudy === self) {
        if (grosssIncome <= 15000) {
            return 'Class_2';
        }

        return 'Class_1';
    }
};

String.prototype.replaceSpaceWithUnderscore = function (): string {
    return this.split(' ').join('_');
};

String.prototype.getDateOfBirthByIdNumber = function () {
    const idNumber = this as string;
    const idYear = idNumber.substr(0, 2);
    const idMonth = idNumber.substr(2, 2);
    const idDay = idNumber.substr(4, 2);
    const nowYear = moment().format('YY');
    let yearPrefix = +idYear <= +nowYear ? '20' : '19';
    return `${yearPrefix}${idYear}-${idMonth}-${idDay}`;
};

String.prototype.getAgeByIdNumber = function (): number {
    let idNumber = this as string;
    const dob = idNumber.getDateOfBirthByIdNumber();
    const age = moment().diff(dob, 'years', false);
    return age;
};

Array.prototype.swapItems = function (a: number, b: number): Array<any> {
    if (a === null || typeof a === 'undefined' || b === null || typeof b === 'undefined') {
        return this;
    }

    this[a] = this.splice(b, 1, this[a])[0];
    return this;
};

Array.prototype.findReplaceAndPush = function (element: ClaimsModule.IQuestionAnsType) {
    let _self = this as Array<ClaimsModule.IQuestionAnsType>;

    let res = _self.find(x => x.item === element.item);

    if (!res && element.item) {
        _self.push(element);
        return _self;
    }
    return this;
};

Array.prototype.getTotalPremiumAmount = function () {
    let _self = this as any as Array<ProductListModule.ISelectedProductDetails>;
    let totalPremiumAmount = 0;
    if (_self.length > 0) {
        _self.forEach(x => {
            if (x.premiumAmount) {
                totalPremiumAmount = totalPremiumAmount + x.premiumAmount;
            }
        });
    }
    return totalPremiumAmount;
};


Array.prototype.fetchProductList = function () {
    let _self = this as any as Array<ProductListModule.ISelectedProductDetails>;
    let productList = new Array<{ productSectionName: string, products: Array<ProductListModule.ISelectedProductDetails> }>();
    let groupedResult = _.groupBy(_self.reverse(), 'quoteId');
    // tslint:disable-next-line: forin
    for (let key in groupedResult) {
        let filteredProductList = _.groupBy(groupedResult[key], 'productCategory');
        let productCategory = groupedResult[key].find(x => x.isMainProduct === true).productCategory;
        productList.push({ productSectionName: productCategory, products: groupedResult[key] });
    }
    return productList;
};

Array.prototype.customFlat = function () {
    let _self = this as Array<any>;
    return _self.reduce((a, b) => a.concat(b), []);
};

Array.prototype.toBenefitUID = function () {
    let _benfitModel = this as BenefitModule.IBenefitPattern[];
    return _benfitModel.map(({ UID }) => ({ UID }.UID));
};


String.prototype.getGenderFromIdNumber = function () {
    let _self = this as String;
    let genderDecoded = parseInt(_self.substr(6, 4));
    return (genderDecoded >= 5000 && genderDecoded <= 9999) ? 'Male' : (genderDecoded >= 0 && genderDecoded <= 4999) ? 'Female' : '';
};

Array.prototype.ToJsonParse = function () {
    let _self = this;
    return JSON.parse(JSON.stringify(_self));
};

Array.prototype.getBenefitWithRate = function (rateTableEntryResponse) {
    let _self = this as BenefitModule.IBenefitModel[];
    return (_self.filter(x => rateTableEntryResponse.some(s => s.BenefitPatterns.some(d => x.BenefitPatterns.some(f => f.UID === d.UID)))).map(({ BenefitPatterns }) => ({ BenefitPatterns }.BenefitPatterns)) as any).customFlat();
};
Array.prototype.getPremiumWithRate = function (rateTableEntryResponse) {
    let _self = this as PremiumPatternModule.IPremiumPatternModel[];
    return (_self.filter(x => rateTableEntryResponse.some(s => s.PremiumPatterns.some(d => x.PremiumPatterns.some(f => f.UID === d.UID)))).map(({ PremiumPatterns }) => ({ PremiumPatterns }.PremiumPatterns)) as any).customFlat();
};

Array.prototype.getLowestBenefitpatternUID = function (benefitPatternsWithRate) {
    let _self = this as RateTableModule.IRateTableModel[];
    return _self.filter(x => x.BenefitPatterns.some(s => benefitPatternsWithRate.some(f => f.UID === s.UID))).sort((a, b) => a.BaseRate - b.BaseRate)[0];
};

Array.prototype.getPageViewForStepper = function () {
    let _self = this as Array<ProductListModule.IProductListModel>;
    let stepperView = new Array<{ productSectionName: string, products: Array<ProductListModule.IProductListModel> }>();

    let groupedResult = _.groupBy(_self.filter(x => x.Category !== 'Complementary Benefits').reverse(), 'Category');
    // tslint:disable-next-line: forin
    for (let key in groupedResult) {
        stepperView.push({ productSectionName: key, products: groupedResult[key] });
    }

    return stepperView;
};
Array.prototype.getPageViewForStepper_new = function () {
    let _self = this as Array<Product>;
    let stepperView = new Array<{ productSectionName: string, products: Array<Product> }>();

    let groupedResult = _.groupBy(_self.filter(x => x.Category !== 'Complementary Benefits').reverse(), 'Category');
    // tslint:disable-next-line: forin
    for (let key in groupedResult) {
        stepperView.push({ productSectionName: key, products: groupedResult[key] });
    }

    return stepperView;
};




Array.prototype.getParsedMainAndRiderProduct = function () {
    let _self = this as Array<ProductListModule.IProductListModel>;
    _self.filter(x => x.ProductRequirements !== undefined && x.ProductRequirements.length > 0).forEach(x => {
        x.isMainProduct = true;
        x.riderProducts = _self.filter(r => x.ProductRequirements.some(t => t.ProductConfig_2.UID === r.UID));
    });
    return _self;
};

Array.prototype.IsNullOrEmpty = function () {
    let _self = this as Array<any>;
    return _self === undefined || _self === null || _self.length === 0 || _self.length < 0;
};

Array.prototype.setQuestionType = function () {
    let questionForCurrentIteration = this as Array<GetQuestionariesModule.IQuestion>;

    for (let i = 0; i < questionForCurrentIteration.length; i++) {
        if (questionForCurrentIteration[i] &&
            questionForCurrentIteration[i].Answer.length > 0 && questionForCurrentIteration[i].AllowMultiAnswers &&
            questionForCurrentIteration[i].Answer[0].AnswerType === AppConsts.AnswerType.BasicAnswerProvider) {

            questionForCurrentIteration[i].QuestionType = QuestionType.MultiAnswer;

        } else if (questionForCurrentIteration[i] &&
            questionForCurrentIteration[i].Answer.length > 0 && !questionForCurrentIteration[i].AllowMultiAnswers &&
            (questionForCurrentIteration[i].Answer[0].AnswerType === AppConsts.AnswerType.BasicAnswerProvider)) {
            questionForCurrentIteration[i].QuestionType = QuestionType.SingleSelect;

        } else if (questionForCurrentIteration[i] &&
            questionForCurrentIteration[i].Answer.length > 0 && !questionForCurrentIteration[i].AllowMultiAnswers &&
            (questionForCurrentIteration[i].Answer[0].AnswerType === AppConsts.AnswerType.SingleEntryAnswerProvider)) {
            questionForCurrentIteration[i].QuestionType = QuestionType.FreeText;

        } else if (questionForCurrentIteration[i] &&
            questionForCurrentIteration[i].Answer.length > 0 && questionForCurrentIteration[i].AllowMultiAnswers &&
            questionForCurrentIteration[i].Answer[0].AnswerType === AppConsts.AnswerType.MultipleSelectionAnswerProvider) {
            questionForCurrentIteration[i].QuestionType = QuestionType.ConditionsMultiSelect;
        } else if (questionForCurrentIteration[i] &&
            questionForCurrentIteration[i].Answer.length > 0 && !questionForCurrentIteration[i].AllowMultiAnswers &&
            questionForCurrentIteration[i].Answer[0].AnswerType === AppConsts.AnswerType.SelectionAnswerProvider) {
            questionForCurrentIteration[i].QuestionType = QuestionType.ConditionsSingleSelect;
        }
        if (questionForCurrentIteration[i] && (
            questionForCurrentIteration[i].ExternalReferenceId.indexOf('consent') > -1
            || questionForCurrentIteration[i].ExternalReferenceId.indexOf('disclaimer') > -1)) {
            questionForCurrentIteration[i].QuestionType = QuestionType.DataConsent;
        }
        if (questionForCurrentIteration[i] && (
            questionForCurrentIteration[i].ExternalReferenceId.indexOf('DND') > -1)) {
            questionForCurrentIteration[i].QuestionType = QuestionType.DNDQuesions;
        }

        if (questionForCurrentIteration[i].ExternalReferenceId === 'Avocations_disclosure') {
            questionForCurrentIteration[i].QuestionType = QuestionType.DataConsent;
        }

        if (questionForCurrentIteration[i].ExternalReferenceId === 'Access_consent_HIV') {
            questionForCurrentIteration[i].QuestionType = QuestionType.SingleSelect;
        }
    }
    return questionForCurrentIteration;
};

Array.prototype.checkForAnsweringDND = function (userDetails: AnonymousUserDetail, needsAssessments: Array<NeedsCalculatorx> = []) {
    let _self = this[0] as any as GetQuestionariesModule.IQuestion;

    switch (_self.ExternalReferenceId) {
        case AppConsts.DND_Questions.DND_Sex:
            _self.ValueGiven = userDetails.identityNumber.getGenderFromIdNumber();
            break;
        case AppConsts.DND_Questions.DND_SA_ID_number:
            _self.ValueGiven = _self.Answer.find(x => x.Text === 'Yes').Value;
            break;
        case AppConsts.DND_Questions.DND_Show_products:
            _self.ValueGiven = _self.Answer.find(x => x.Text === 'No').Value;
            break;
        case AppConsts.DND_Questions.DND_sales_channel:
            if (userDetails.summAssured) {
                if (userDetails.isQuoteImprovedForUser) {
                    _self.ValueGiven = _self.Answer.find(x => x.Text === 'Direct improving rates').Value;
                } else if (userDetails.summAssured <= 1500000) {
                    _self.ValueGiven = _self.Answer.find(x => x.Text === 'Direct within allowed sum assured').Value;
                } else {
                    _self.ValueGiven = _self.Answer.find(x => x.Text === 'Direct exceeding allowed sum assured').Value;
                }
            } else {
                _self.ValueGiven = _self.Answer.find(x => x.Text === 'Intermediary').Value;
            }

            break;
        case AppConsts.DND_Questions.DND_na_indicator:
            const textToFindNaIndicator = userDetails.needsAssessment ? 'Yes' : 'No';
            _self.ValueGiven = _self.Answer.find(x => x.Text === textToFindNaIndicator).Value;
            break;
        case AppConsts.DND_Questions.DND_Credit_check:
            const textToFindCreditCheck = userDetails.creditCheck ? 'Yes' : 'No';
            _self.ValueGiven = _self.Answer.find(x => x.Text === textToFindCreditCheck).Value;
            break;
        case AppConsts.DND_Questions.DND_Income_confirmation:
            const textToFindIncomeConfirmation = userDetails.proofOfIncome ? 'Yes' : 'No';
            _self.ValueGiven = _self.Answer.find(x => x.Text === textToFindIncomeConfirmation).Value;
            break;
        case AppConsts.DND_Questions.DND_existing_life_cover_na:
            _self.ValueGiven = userDetails.needsAssessmentLifeCover || 0;
            break;
        case AppConsts.DND_Questions.DND_existing_income_protection_na:
            _self.ValueGiven = userDetails.needsAssessmentIncomeProtection || 0;
            break;
        case AppConsts.DND_Questions.DND_existing_disability_impairment_cover_na:
            _self.ValueGiven = userDetails.needsAssessmentDisabilityImpairmentCover || 0;
            break;
        case AppConsts.DND_Questions.DND_existing_severe_illness_cover_na:
            _self.ValueGiven = userDetails.needsAssessmentSevereIllnessCover || 0;
            break;
        case AppConsts.DND_Questions.DND_duration_on_scheme:
            _self.ValueGiven = '0';
            break;
        case AppConsts.DND_Questions.DND_Scheme_Data:
            _self.ValueGiven = [6866];
            break;
        case AppConsts.DND_Questions.DND_Access_to_scheme_data:
            _self.ValueGiven = '0';
            break;
        default:
            _self.ValueGiven = _self.AllowMultiAnswers ? [_self.Answer[0].Value] : _self.Answer[0].Value;
            break;
    }

    return _self;
};

Array.prototype.getQuestionsToBeShown = function (questions) {
    let _self = this as any as Array<{ extId: string, questions: any[] }>;
    return _self.concat(questions.getFilterQuestions(this.SectionQuestionAnswer))
        .getFilterQuestions(this.SectionQuestionAnswer)
        .deleteUnWantedQuestions(this.SectionQuestionAnswer);
};

Array.prototype.getFilterQuestions = function (questions) {
    let _self = this as any as Array<{ extId: string, questions: any[] }>;
    return _self.filter(x => !questions.some(f => f.extId === x.extId));
};

Array.prototype.deleteUnWantedQuestions = function (questions) {
    let _self = this as any as Array<{ extId: string, questions: any[] }>;
    if (_self && _self.length > 0) {
        _self.forEach(i => {
            questions
                .splice(questions
                    .findIndex(h => h.extId === i.extId), 1);
        });
    }
    return questions;
};

Array.prototype.getAlreadyPresentedQuestions = function () {
    let _self = this as any as Array<{ extId: string, questions: any[] }>;
    if (_self && _self.length > 0) {
        return (_self
            .map(({ questions }) => ({ questions }
                .questions.map(({ ExternalReferenceId }) =>
                    ({ ExternalReferenceId }.ExternalReferenceId)))) as any)
            .customFlat();
    }
    return _self;
};

export { };
