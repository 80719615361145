import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as http from 'http';
import {Header} from '@node_modules/primeng';


@Injectable({
    providedIn: 'root'
})
export class HttpMethodBaseService {
    constructor(private http: HttpClient) {
    }

    get<T>(url: string, headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(url, { headers: headers });
    }

    /**
     * Gets content based on the provided url.
     * @param url The location to be called
     * @param headers Optional. Additional header information to be included in the call
     */
    getAsync<T>(url: string, headers?: HttpHeaders): Promise<T> {
        return this.wrapInPromise(this.get<T>(url, headers));
    }

    post<T>(url, body?, headers?: HttpHeaders): Observable<T> {
        return this.http.post<T>(url, body, { headers: headers });
    }

    put<T>(url, body?, headers?): Observable<T> {
        return this.http.put<T>(url, body, { headers: headers });

    }

    delete<T>(url, headers?: HttpHeaders): Observable<T> {
        return this.http.delete<T>(url, { headers: headers });
    }

    request<T>(request, url, body?, headers?: HttpHeaders) {
        return this.http.request<T>(request, url, { body: body, headers: headers });
    }

    /**
     * Provides an asynchronous alternative to observables. Recommended for HTTP calls only
     * @param observable The observable to be wrapped for async usage
     * @private
     */
    private wrapInPromise<T>(observable: Observable<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            observable.subscribe({
                next: (result) => resolve(result),
                error: (error) => reject(error)
            });
        });
    }
}
