import { PremiumPattern } from './PremiumPattern';
import { Injector } from '@angular/core';
import { ProductService } from '@app/shared/services/product.service';
import { PremiumPatternModule } from '../../models/elevate-data-models';

export interface IIncompletePremiumPattern {
    withMetadata(beneditPatternId): Promise<IIncompletePremiumPattern>;
    forPremiumPattern(benefitPatternUID: string): IIncompletePremiumPattern;
    extractUID(): IIncompletePremiumPattern;
    extractPremiumIncreaseIndex(): IIncompletePremiumPattern;
    extractPremiumIncrease(): IIncompletePremiumPattern;
    extractPremiumTerm(): IIncompletePremiumPattern;
    extractPremiumPatternUIDList(): string[];
    build(): PremiumPattern;
}

export class PremiumPatternFactory implements IIncompletePremiumPattern {
    private _productService: ProductService;
    metaData$: PremiumPatternModule.IPremiumPatternModel;
    PremiumPattern: PremiumPatternModule.PremiumPattern;
    UID: string;
    PremiumIncreaseIndex: string;
    PremiumIncrease: any;
    CeaseAge: string;
    PremiumTerm: string;
    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    constructor(private injector: Injector) {

    }

    async withMetadata(premiumPatternId: any): Promise<IIncompletePremiumPattern> {
        this.metaData$ = await this.productService.getPremiumPatternSet(premiumPatternId).toPromise();
        return this;
    }
    extractPremiumPatternUIDList(): string[] {
        return this.metaData$.PremiumPatterns.map(({ UID }) => ({ UID }.UID));
    }
    forPremiumPattern(premiumPatternUID: string): IIncompletePremiumPattern {
        this.PremiumPattern = this.metaData$.PremiumPatterns.find(x => x.UID === premiumPatternUID);
        return this;
    }
    extractUID(): IIncompletePremiumPattern {
        this.UID = this.PremiumPattern.UID;
        return this;
    }
    extractPremiumIncreaseIndex(): IIncompletePremiumPattern {
        if (this.PremiumPattern.PremiumEscalationType) {
            if (this.PremiumPattern.IndexLinkedPremium && this.PremiumPattern.IndexLinkedPremium.Index) {
                this.PremiumIncreaseIndex = this.PremiumPattern.IndexLinkedPremium.Index.Name;
                return this;
            }
            this.PremiumIncreaseIndex = this.PremiumPattern.PremiumEscalationType;
            return this;
        }
    }
    extractPremiumIncrease(): IIncompletePremiumPattern {
        this.PremiumIncrease = 0;

        if (this.PremiumPattern.FixedIncreasePremium && this.PremiumPattern.FixedIncreasePremium.OverallEscalation) {
            this.PremiumIncrease = this.PremiumPattern.FixedIncreasePremium.OverallEscalation;
            if (this.PremiumIncreaseIndex.toLocaleLowerCase() === 'cpi') {
                this.PremiumIncrease = 'CPI + ' + this.PremiumIncrease;
            }
            return this;
        }
        if (this.PremiumPattern.IndexLinkedPremium && this.PremiumPattern.IndexLinkedPremium.AdditionalEscalation !== undefined && this.PremiumPattern.IndexLinkedPremium.AdditionalEscalation !== null) {
            this.PremiumIncrease = this.PremiumPattern.IndexLinkedPremium.AdditionalEscalation;
            if (this.PremiumIncreaseIndex.toLocaleLowerCase() === 'cpi') {
                this.PremiumIncrease = 'CPI + ' + this.PremiumIncrease;
            }
            return this;
        }

        return this;
    }
    extractPremiumTerm(): IIncompletePremiumPattern {
        if (this.PremiumPattern.CeaseAge) {
            this.PremiumTerm = this.PremiumPattern.CeaseAge;
            if (this.PremiumTerm.toString().toLocaleLowerCase().indexOf('cover') >= 0) {
                this.PremiumTerm = 'same as benefit term';
            }
            return this;
        }
    }

    build(): PremiumPattern {
        return new PremiumPattern(this.PremiumPattern.UID, this.PremiumIncreaseIndex, this.PremiumIncrease, this.PremiumTerm);
    }

}
