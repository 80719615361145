import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConsts } from '../../../shared/AppConsts';
import { IDirectSalesUserDto, IHttpResponseDetails, BenficiaryModule } from '../models/elevate-data-models';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { TokenIdentiyProviderServiceProxy } from '../../../shared/service-proxies/service-proxies';


@Injectable()
export class IdentityServerService {
    API_BASE_URL_IDP = abp.setting.values['PortalSettings.authority'];
    constructor(private _http: HttpClient, private tokenIdentityServiceProxy: TokenIdentiyProviderServiceProxy) { }

    checkLeadAndRegister(directService: IDirectSalesUserDto, acessToken: string): Observable<IHttpResponseDetails> {

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + acessToken
        });

        return this._http.post<IHttpResponseDetails>(this.API_BASE_URL_IDP + AppConsts.Endpoints.autoRegister, directService, { headers: headers })
            .pipe(map((response: IHttpResponseDetails) => {
                return response;
            }));
    }

    brokerQuoteNotification(customerCellNumber: string, customerFirstName: string, brokerFirstName: string, accessToken: string, senderGuid: string): Observable<{}> {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });

        const obj = {
            customerCellNumber,
            customerFirstName,
            brokerFirstName,
            senderGuid
        };

        return this._http.post(this.API_BASE_URL_IDP + AppConsts.Endpoints.brokerQuoteNotification, obj, { headers: headers });
    }

    getRegisterAccessToken(): Observable<string> {
        return this.tokenIdentityServiceProxy.getTokenProvider();
    }

    sendNotificationEmailAndSms(cellNumber: string, email: string) {

        let params = new HttpParams().append('email', email).append('cellNumber', cellNumber);

        return this._http.post<string>(this.API_BASE_URL_IDP + AppConsts.Endpoints.sendNotifications, null, { params: params })
            .pipe(map((response: any) => {
                return response.result;
            }));
    }

    createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails: BenficiaryModule.IBeneficiaryModel): Observable<string> {

        return this._http.post<string>(this.API_BASE_URL_IDP + AppConsts.Endpoints.addBeneficiary, beneficiaryDetails)
            .pipe(map(res => {
                return res;
            }));
    }

    getBeneficiariesForPolicy(policyGuid: string): Observable<Array<BenficiaryModule.IBeneficiaryModel>> {
        return this._http.get<Array<BenficiaryModule.IBeneficiaryModel>>(this.API_BASE_URL_IDP + AppConsts.Endpoints.getBeneficiariesForPolicy.replace('{POLICY_GUID}', policyGuid));
    }
}
