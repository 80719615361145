import { Component, OnInit } from '@angular/core';
import { IdentityAuthProviderService } from '../app/shared/common/auth/identity-auth-provider.service';

@Component({
    selector: 'app-idp-auth-callback',
    templateUrl: './idp-auth-callback.component.html',
    styleUrls: ['./idp-auth-callback.component.css']
})
export class IdpAuthCallbackComponent implements OnInit {

    constructor(private authService: IdentityAuthProviderService) { }

    ngOnInit(): void {
        this.completeAuthentication();
    }

    completeAuthentication() {
        this.authService.completeAuthentication();
    }

}
