<div class="kt-portlet kt-portlet--height-fluid ">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Daily Sales
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body" [perfectScrollbar]>
        <div class="row">
            <div class="col-md-12" style="max-height: 200px">
                <ngx-charts-bar-vertical [results]="dailySalesLineChart.chartData"
                    [scheme]="dailySalesLineChart.scheme">
                    <ng-template #tooltipTemplate let-model="model">
                        <span>Day {{model.name}}</span>
                        <br />
                        <span>{{model.value}}</span>
                    </ng-template>
                </ngx-charts-bar-vertical>
            </div>
        </div>
    </div>
</div>