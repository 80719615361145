<div bsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save('formSave')">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{"MyProfile" | localize}}
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="currentUserInfo">
                    <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" style="cursor:pointer;" (click)="profileTab()" [class.active]="tabIndex==1" data-toggle="tab" role="tab" aria-selected="true">
                                {{'Profile' | localize}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" style="cursor:pointer;" (click)="contactTab()" [class.active]="tabIndex==2" data-toggle="tab" role="tab" aria-selected="false">
                                {{'Contact' | localize}}
                            </a>
                        </li>                        
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane  side-border" id="kt_widget2_tab1_content" [class.active]="tabIndex==1">
                            <div class="form-group">
                                <label for="Name">{{"Name" | localize}} *</label>
                                <div class="input-group">
                                    <input id="Name" [attr.disabled]="nameDisabled ? '' : null" #nameInput="ngModel" class="form-control" type="text" name="Name" [(ngModel)]="currentUserInfo.given_name" required maxlength="32">
                                    <div class="input-group-prepend curs" (click)="edit('name')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="Surname">{{"Surname" | localize}} *</label>
                                <div class="input-group">
                                    <input id="Surname" [attr.disabled]="surnameDisabled ? '' : null" #surnameInput="ngModel" type="text" name="Surname" class="form-control" [(ngModel)]="currentUserInfo.family_name" required>
                                    <div class="input-group-prepend curs" (click)="edit('surname')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="birthday">{{"DateOfBirth" | localize}} *</label>
                                <div class="input-group">
                                    <input type="text"
                                           id="birthday"
                                           name="birthday"
                                           [attr.disabled]="birthdayDisabled ? '' : null"
                                           placeholder="Select Date"
                                           class="form-control"
                                           #birthdayInput="ngModel"
                                           bsDatepicker
                                           required
                                           (keyup)="onChange($event)"
                                           [(ngModel)]="restrictionDateValue"
                                           (bsValueChange)="onValueChange($event)"
                                           [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',  isAnimated: true,showWeekNumbers:false }">
                                    <div class="input-group-prepend curs" (click)="edit('birthday')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <span *ngIf="restrictionMessage" class="m--font-danger font-size-12">{{"AgeRestriction" | localize}}</span>
                                <validation-messages [formCtrl]="birthdayInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="gender">{{"Gender" | localize}} *</label>

                                <div class="input-group">
                                    <select id="gender" #genderInput="ngModel" [attr.disabled]="genderDisabled ? '' : null" class="form-control h-auto" required name="gender" [(ngModel)]="selectedGender">
                                        <option value="" disabled>{{"SelectGender" | localize}}</option>
                                        <option *ngFor="let gender of GenderList" [ngValue]="gender">{{gender}}</option>
                                    </select>
                                    <div class="input-group-prepend curs" (click)="edit('gender')"><span class="input-group-text"><i class="flaticon-edit"></i></span></div>
                                </div>
                                <validation-messages [formCtrl]="genderInput"></validation-messages>
                            </div>
                        </div>

                        <div class="tab-pane  side-border" id="kt_widget2_tab1_content" [class.active]="tabIndex==2">
                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input disabled id="EmailAddress" #emailAddressInput="ngModel" type="email" name="EmailAddress" class="form-control" [(ngModel)]="currentUserInfo.email" required email maxlength="256">
                            </div>
                            <div class="form-group">
                                <label for="Phonenumber">{{"MobileNumber" | localize}} *</label>
                                <input disabled id="Phonenumber" #PhoneNumberInput="ngModel" type="text" name="PhoneNumber" class="form-control" [(ngModel)]="currentUserInfo.phone_number" required>                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving">{{"Close" | localize}}</button>
                    <button type="submit" class="btn btn-danger" [disabled]="!mySettingsModalForm.form.valid || saving">
                        <span *ngIf="!lastTabActive">{{"SaveContinue" | localize}}</span>
                        <span *ngIf="lastTabActive">{{"Submit" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal #smsVerificationModal (modalSave)="changePhoneNumberToVerified()"></smsVerificationModal>

