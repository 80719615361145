import { DefaultTimezoneScope } from './service-proxies/service-proxies';

export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}


export enum QuestionType {
    MultiAnswer,
    SingleSelect,
    FreeText,
    DataConsent,
    ConditionsSingleSelect,
    ConditionsMultiSelect,
    ConditionPopup,
    DNDQuesions,
    UnKown,
    Dob
}

export class AppTimezoneScope {
    static Application: number = DefaultTimezoneScope._1;
    static Tenant: number = DefaultTimezoneScope._2;
    static User: number = DefaultTimezoneScope._4;
}

export enum AnswerType {
    InputDate,
    SingleSelect,
    InputText,
    AutoCompleteText
}

export enum SmokerStatus {
    NonSmoker,
    Smoker
}

export enum Education {
    Matric,
    NoMatric,
    DiplomaLessThanThreeYear,
    DiplomaEqualOrMoreThanThreeYear,
    UnderGraduate,
    University4Years,
    PostGraduate
}

export enum PersonEducation {
    NoMatric,
    Matric,
    DiplomaLessThanFourYears,
    DiplomaFourYearsOrMoreThan,
    ThreeYearsUniversityUnderGraduateDegree,
    FourYearsUniversityUnderGraduateDegree,
    PostGraduateStudy
}
