<div class="modal-header d-flex flex-row justify-content-between align-items-center">
  <h5 class="modal-title">
      <span>{{"Support" | localize}}</span>
  </h5>
  <button type="button" class="close p-0 m-0" (click)="close()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mp-0 mt-10">  
  <iframe #iframe class="no-border" height="652px" width="100%" src="https://connect.solgari.com/connect/default.aspx?token=K8Rjg4"
    title="Support"></iframe>
</div>